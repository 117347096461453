import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: MsgType = {
  open: false,
  message: '',
};

export const Messageboxslice = createSlice({
  name: 'messagebox',
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.message = action.payload;
    },
    closeMessage: state => {
      state.open = false;
      // state.message = '';
    },
    initMessage: state => {
      state.message = '';
    },
  },
});

export const { showMessage, closeMessage, initMessage } =
  Messageboxslice.actions;
export default Messageboxslice.reducer;
