const LogoSmall = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.3125 0H4.6875C2.09867 0 0 2.09867 0 4.6875V59.3125C0 61.9013 2.09867 64 4.6875 64H59.3125C61.9013 64 64 61.9013 64 59.3125V4.6875C64 2.09867 61.9013 0 59.3125 0Z"
        fill="url(#paint0_linear_2611_6404)"
      />
      <g filter="url(#filter0_d_2611_6404)">
        <path
          d="M18.4211 11.875H8.125V18.7862H8.13054V44.9638H8.125V51.875H18.4725C24.1767 51.875 28.8145 47.2289 28.8145 41.5146V22.2997C28.8145 16.5468 24.151 11.875 18.4211 11.875ZM21.9284 41.5018C21.9284 43.4065 20.3867 44.9509 18.4854 44.9509H15.0423V18.7862H18.434C20.3611 18.7862 21.9412 20.3563 21.9412 22.2997V41.5018H21.9284Z"
          fill="white"
        />
        <path
          d="M48.0695 20.5H32.5V51H37.7286V25.7715H41.5821V51H46.8107V25.7715H48.05C49.4937 25.7715 50.6643 26.9495 50.6643 28.4121V51H55.8929V28.4121C55.8929 24.0536 52.3714 20.5 48.0402 20.5H48.0695Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2611_6404"
          x="7.96656"
          y="11.7166"
          width="48.1916"
          height="40.4237"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0534375" dy="0.0534375" />
          <feGaussianBlur stdDeviation="0.105937" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0156863 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2611_6404"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2611_6404"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2611_6404"
          x1="-3.13926"
          y1="66.9027"
          x2="70.4505"
          y2="-6.20326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D83B3C" />
          <stop offset="1" stopColor="#CB151D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoSmall;
