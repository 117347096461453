/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Logo from '../../assets/images/app-logo.svg';
import { customAxios } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setCurrentMenu,
  setParentMenu,
  initCurrentMenu,
} from '../../features/mymenu/MymenuSlice';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { logout, userinfoInit } from '../../features/userinfo/UserinfoSlice';

const drawerWidth = 256;

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id, username } = useAppSelector(state => state.userinfo);
  const { menu, currentMenu } = useAppSelector(state => state.mymenu);
  const { loading, showLoading } = useAppSelector(state => state.loading);
  // const { loading, showLoading } = { loading: true, showLoading: false };
  const [myMenu, setMyMenu] = useState<MenuInterface[]>([]);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleClick = () => {
    dispatch(initCurrentMenu());
    navigate('/main/mypage');
  };

  useEffect(() => {
    if (menu.length > 0 && menu.length !== myMenu.length) {
      setMyMenu(
        menu.map(item => {
          return { ...item, open: true };
        }),
      );
    }
    dispatch(initCurrentMenu());
    let path = location.pathname;
    if (location.pathname.includes('/main/extapp')) {
      path = '/main/extapp';
    } else if (location.pathname.includes('/main/intapp')) {
      path = '/main/intapp';
    } else if (location.pathname.includes('/main/notice')) {
      path = '/main/notice';
    }
    if (path === '/main/updatemypage') {
      setSelectedMenu({
        navigateUrl: '/main/updatemypage',
        id: 9999,
        name: 'Edit My Information',
        parentId: -1,
        permission: 15,
        menuIconSvg: null,
        selectedMenuIconSvg: null,
      });
    } else if (path !== '/main') {
      menu.forEach(item => {
        if (item.navigateUrl.includes(path)) {
          setSelectedMenu(item);
        }
      });
    }
  }, [menu, location.pathname]);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const setSelectedMenu = (menuItem: MenuInterface) => {
    sessionStorage.removeItem('page');
    let permissionString = menuItem.permission.toString(2);
    permissionString =
      permissionString.length >= 4
        ? permissionString
        : new Array(4 - permissionString.length + 1).join('0') +
          permissionString;
    if (menuItem.parentId === 1) {
      dispatch(setParentMenu(menuItem));
    } else {
      const parentItem = menu.find(item => item.id === menuItem.parentId);
      if (parentItem) dispatch(setParentMenu(parentItem));
      else dispatch(setParentMenu(undefined));
    }
    dispatch(
      setCurrentMenu({
        ...menuItem,
        c: permissionString.split('')[0] === '1',
        r: permissionString.split('')[1] === '1',
        u: permissionString.split('')[2] === '1',
        d: permissionString.split('')[3] === '1',
      }),
    );
  };

  const onMenuClick = (nodeId: number) => {
    const menu = myMenu.find(item => item.id === nodeId);
    if (menu) {
      setSelectedMenu(menu);
    }
  };

  const isMenuOpen = (id: number) => {
    const targetMenu = myMenu.find(item => item.id === id);
    if (targetMenu && targetMenu.open !== undefined) {
      return targetMenu.open;
    }
    return false;
  };

  const handleMenuClick = (id: number) => {
    setMyMenu(
      myMenu.map(item => {
        if (item.id === id) {
          return { ...item, open: !item.open };
        }
        return { ...item };
      }),
    );
  };

  const getMenuImage = (icon: string | null) => {
    if (icon) {
      return (
        <ListItemIcon>
          <img src={`data:image/svg+xml;base64,${btoa(icon)}`} />
        </ListItemIcon>
      );
    }
    return (
      <ListItemIcon>
        <div style={{ width: '24px', height: '24px' }} />
      </ListItemIcon>
    );
  };

  const createListItem = (targetMenu: MenuInterface) => {
    const subMenuArr = myMenu.filter(
      subMenu => subMenu.parentId === targetMenu.id,
    );

    if (subMenuArr.length > 0) {
      return (
        <Wrap key={`${targetMenu.id}-wrap`}>
          <ListItemButton
            selected={targetMenu.name === currentMenu.name}
            key={`${targetMenu.id}-ListItemButton`}
            onClick={() => {
              handleMenuClick(targetMenu.id);
            }}
          >
            {getMenuImage(targetMenu.menuIconSvg)}
            <ListItemText
              primary={targetMenu.name}
              key={`${targetMenu.id}-ListItemText`}
            />
            {isMenuOpen(targetMenu.id) ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={isMenuOpen(targetMenu.id)}
            className="menuCollapse"
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding key={`${targetMenu.id}-List`}>
              {subMenuArr.map(menuItem => createListItem(menuItem))}
            </List>
          </Collapse>
        </Wrap>
      );
    }
    return (
      <Wrap key={`${targetMenu.id}-wrap`}>
        <ListItemButton
          selected={targetMenu.name === currentMenu.name}
          onClick={() => {
            onMenuClick(targetMenu.id);
          }}
          component={NavLink}
          to={targetMenu.navigateUrl}
          key={`${targetMenu.id}-ListItemButton`}
        >
          {targetMenu.name === currentMenu.name
            ? getMenuImage(targetMenu.selectedMenuIconSvg)
            : getMenuImage(targetMenu.menuIconSvg)}
          <ListItemText
            primary={targetMenu.name}
            key={`${targetMenu.id}-ListItemText`}
          />
        </ListItemButton>
      </Wrap>
    );
  };

  const drawer =
    loading && !showLoading ? (
      <div style={{ background: '#fff', height: '100%', width: '100%' }}>
        <Skeleton variant="rectangular" height="100%" width="100%" />
      </div>
    ) : (
      <div style={{ background: '#000', color: '#fff' }}>
        <NavLink to="/main/masterkey">
          <img src={Logo} style={{ margin: '32px' }} alt="" />
        </NavLink>
        <List component="nav">
          {myMenu
            .filter(item => item.parentId === 1)
            .map(item => createListItem(item))}
        </List>
      </div>
    );

  const container = document.body;

  const onLogout = () => {
    customAxios
      .post(`/mdx/V1.0/setting/user/${id}/logout`)
      .then(() => {
        if (username.indexOf('@seegene.com') < 0) {
          dispatch(logout());
        }
      }) // 사용자 정보 초기화
      .catch(() => {
        if (username.indexOf('@seegene.com') < 0) {
          dispatch(userinfoInit());
        }
      })
      .finally(() => {
        if (username.indexOf('@seegene.com') > 0) {
          window.location.replace(
            `https://login.windows.net/301bf889-c43d-435f-be4a-d53ec728b61b/oauth2/logout?post_logout_redirect_uri=${window.location.origin}`,
          );
        }
      });
  };
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          mb: '64px',
          backgroundColor: '#f3f3f3',
          boxShadow: 'unset',
          padding: '42px 48px 22px 48px',
          margin: '0px',
        }}
      >
        {loading && !showLoading ? (
          <Skeleton variant="rectangular" width={169} height={39} />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '32px',
              alignItems: 'center',
            }}
          >
            <div>
              {currentMenu && (
                <Typography style={{ color: '#4D4C4C', fontSize: '28px' }}>
                  {currentMenu.name}
                </Typography>
              )}
            </div>
            <div>
              <IconButton onClick={handleClick}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"
                    fill="#878585"
                  />
                </svg>
              </IconButton>
              <IconButton onClick={onLogout}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.09 15.59L11.5 17L16.5 12L11.5 7L10.09 8.41L12.67 11H3V13H12.67L10.09 15.59ZM19 3H5C3.89 3 3 3.9 3 5V9H5V5H19V19H5V15H3V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                    fill="#878585"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
        )}
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        style={{ background: '#F6F6F6' }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
        <VersionDiv>
          <p>Version 1.00.000</p>
        </VersionDiv>
      </Box>
    </>
  );
};

export default Sidebar;

const Wrap = styled.div`
  .MuiListItemButton-root {
    padding: 16px 20px;
    &:hover {
      background-color: #c8c8c8;
    }
  }
  .MuiListItemButton-root.Mui-selected {
    background-color: #fff;
    color: #0076ce;
    font-weight: bold;
    &:hover {
      background-color: #c8c8c8;
    }
  }
  .MuiListItemIcon {
    min-width: revert;
  }
  .MuiListItemIcon-root {
    margin-right: 8px;
    min-width: revert;
  }
`;

const VersionDiv = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1200;
  color: #fff;
`;
