import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const EmptyLayout = () => {
  return (
    <Wrap>
      <Outlet />
    </Wrap>
  );
};

export default EmptyLayout;

const Wrap = styled.div`
  height: 100vh;
  /* padding: 56px 0px; */
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;
