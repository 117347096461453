/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, useEffect, useState, MouseEvent } from 'react';
import { t } from '@lingui/macro';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatVersion, getDate } from '../../lib/common';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import SearchBox from '../common/SearchBox';
import ModalPopup from '../common/ModalPopup';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import { axios, customAxios } from '../../lib/customAxios';
import SwAppAddModify from './SwAppAddModify';
import DownloadProgress from '../common/DownloadProgress';
import IconAdd from '../../assets/images/icon_add.svg';
import styled from 'styled-components';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import '../../styles/app/app.scss';
import MoreVert from '../../assets/icon/MoreVert';
import FileDownload from '../../assets/icon/FileDownload';

interface Props {
  disturbDsn: string;
  swproductlist: SwProductInterface[];
  getSwProductList: (search?: string) => void;
  totalPage?: number;
  page: number;
  setPage: (page: number) => void;
}

const SwAppList = ({
  disturbDsn,
  swproductlist,
  getSwProductList,
  totalPage,
  page,
  setPage,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { c, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const { loading, showLoading } = useAppSelector(state => state.loading);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [moreMenu, setMoreMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [popupTitle, setPopupTitle] = useState<string>('');
  const [swAppPopup, setSwAppPopup] = useState(false);
  const [openProgress, setOpenProgress] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [selectedData, setSelectedData] = useState<
    SwProductInterface | undefined
  >(undefined);

  useEffect(() => {
    if (progress === 100) {
      setOpenProgress(0);
      dispatch(
        openAlert({
          message: t`Downloaded`,
          type: 'success',
          title: 'Confirm',
        }),
      );
    }
  }, [progress]);

  const handleMoreInfo = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    setSelectedRowId(Number(id));
    setMoreMenu(
      moreMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleMoreMenuClose = () => {
    setMoreMenu(null);
  };

  const downloadLatestSw = async (data: SwProductInterface) => {
    const result = await dispatch(
      openConfirm({
        message: t`Do you want to download?`,
        title: t`Download`,
        okString: t`Download`,
      }),
    );
    if (result.payload) {
      customAxios
        .get<SwVersionInterface>(
          `/mdx/V1.0/swm/sw/${data.id}/version/${data.verId}`,
        )
        .then(response => {
          const tempCheckFileData = response.data.swVersionFileList;
          if (tempCheckFileData && tempCheckFileData?.length > 0) {
            const filePerArr: number[] = Array(tempCheckFileData.length);

            tempCheckFileData.forEach((item, itemIndex) => {
              setOpenProgress(openProgress + 1);
              axios
                .get<Blob>(item.filePath, {
                  responseType: 'blob',
                  onDownloadProgress: progressEvent => {
                    filePerArr[itemIndex] = Math.round(
                      ((100 / tempCheckFileData.length) *
                        progressEvent.loaded) /
                        progressEvent.total,
                    );
                    let total = 0;
                    filePerArr.forEach(size => {
                      total += size;
                    });
                    setProgress(total);
                  },
                })
                .then(response => {
                  const fileTemp = tempCheckFileData.find(
                    item => item.filePath === response.config.url,
                  );
                  const filename = fileTemp ? fileTemp.fileName : 'test';
                  const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  setOpenProgress(openProgress - 1);
                })
                .catch(() => {
                  setOpenProgress(openProgress - 1);
                  dispatch(
                    openConfirm({
                      message: t`An error occurred while downloading. Would you like to try again?`,
                      okString: t`Retry`,
                    }),
                  );
                });
            });
          }
        });
    }
  };

  const goToLtestVersion = (data: SwProductInterface) => {
    navigate(
      `/main/${pathname.split('/')[2]}/versionInfo/${data.id}/${data.verId}`,
    );
  };

  const onItemClick = () => {
    if (u) {
      const item = swproductlist.find(item => item.id === selectedRowId);
      if (item) {
        handleMoreMenuClose();
        setPopupTitle(t`Modify App`);
        setSelectedData(item);
        setSwAppPopup(true);
      }
    }
  };

  const onAddSwApp = () => {
    handleMoreMenuClose();
    setPopupTitle(t`Add App`);
    setSelectedData(undefined);
    setSwAppPopup(true);
  };

  const onSearchClick = (search: string) => {
    getSwProductList(search);
  };

  const onDelete = async () => {
    if (d) {
      const result = await dispatch(
        openConfirm({
          type: 'error',
          message: t`Are you sure to delete the app?`,
          title: t`Delete`,
          okString: t`Delete`,
        }),
      );
      if (result.payload) {
        customAxios.delete(`/mdx/V1.0/swm/sw/${selectedRowId}`).then(() => {
          handleMoreMenuClose();
          setSwAppPopup(false);
          getSwProductList();
          dispatch(
            openAlert({
              message: t`Deleted app.`,
              title: t`OK`,
              type: 'success',
            }),
          );
        });
      }
    }
  };

  const goToVersionList = () => {
    navigate(`/main/${pathname.split('/')[2]}/versionList/${selectedRowId}`);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: t`App Name`,
      headerClassName: 'grid-title-app-name',
      minWidth: 220,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <div style={{ padding: '16px 35px' }}>
            {params.row.imagePath && (
              <img src={params.row.imagePath} alt={params.value} />
            )}
            <p>{params.value}</p>
          </div>
        );
      },
    },
    {
      field: 'marketStatus',
      headerName: t`IVD/RUO`,
      minWidth: 110,
      sortable: false,
    },
    {
      field: 'descr',
      headerName: t`Summary`,
      cellClassName: 'appDescr',
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'releaseDt',
      headerName: t`Last Updated Date`,
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.row.verId ? getDate(params.value || '') : <div />;
      },
    },
    {
      field: 'version',
      headerName: t`Version`,
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.row.verId ? (
          formatVersion(`${params.row.version}`)
        ) : (
          <div />
        );
      },
    },
    {
      field: 'download',
      headerName: t`Download`,
      minWidth: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.row.verId ? (
          <Button
            className="text-btn"
            variant="text"
            onClick={() => {
              downloadLatestSw(params.row);
            }}
            startIcon={<FileDownload />}
          >
            {t`Download`}
          </Button>
        ) : (
          <div />
        );
      },
    },
    {
      field: 'verId',
      headerName: ' ',
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        return params.value ? (
          <Button
            variant="text"
            className="text-btn"
            onClick={() => {
              goToLtestVersion(params.row);
            }}
          >
            {t`Latest Version`}
          </Button>
        ) : (
          <div />
        );
      },
    },
    {
      field: 'modify',
      headerName: ' ',
      width: 60,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconButton-${params.row.id}`}
              onClick={event => {
                handleMoreInfo(event, `${params.row.id}`);
              }}
              id={`app-modify-${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  const handelCellClick = (params: GridCellParams) => {
    if (
      ['name', 'marketStatus', 'descr', 'releaseDt', 'version'].includes(
        params.field,
      )
    ) {
      if (params.row.verId) {
        navigate(
          `/main/${pathname.split('/')[2]}/versionInfo/${params.row.id}/${
            params.row.verId
          }`,
        );
      }
    }
  };

  return (
    <>
      <div>
        <div style={{ height: '38px' }} />
        {loading && !showLoading ? (
          <Skeleton width={431} height={56} variant="rectangular" />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SearchBox label={t`App Name`} searchClick={onSearchClick} />
            {c && (
              <Buttons
                variant="contained"
                size="small"
                onClick={onAddSwApp}
                startIcon={<img src={IconAdd} alt="" />}
              >
                {t`Add App`}
              </Buttons>
            )}
          </div>
        )}
        {loading && !showLoading ? (
          <div>
            <div
              style={{
                width: '1568px',
                height: '700px',
                backgroundColor: '#FFF',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '45px',
                  backgroundColor: '#f3f3f3',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginLeft: '36px', width: '150px' }}>
                  <Skeleton width={39} height={19} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '10px', width: '100px' }}>
                  <Skeleton width={39} height={19} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '10px', width: '450px' }}>
                  <Skeleton width={39} height={19} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '10px', width: '130px' }}>
                  <Skeleton width={39} height={19} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '100px', width: '130px' }}>
                  <Skeleton width={39} height={19} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '90px', width: '130px' }}>
                  <Skeleton width={39} height={19} variant="rectangular" />
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '16px' }}>
                <div style={{ marginLeft: '36px', width: '150px' }}>
                  <Skeleton width={64} height={64} variant="rectangular" />
                  <Skeleton
                    width={116}
                    height={24}
                    variant="rectangular"
                    style={{ marginTop: '8px' }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    width: '100px',
                  }}
                >
                  <Skeleton width={44} height={29} variant="rectangular" />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    width: '450px',
                  }}
                >
                  <Skeleton width={413} height={187} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '10px', width: '130px' }}>
                  <Skeleton width={108} height={29} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '100px', width: '130px' }}>
                  <Skeleton width={68} height={29} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '90px', width: '130px' }}>
                  <Skeleton width={68} height={29} variant="rectangular" />
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '30px' }}>
                <div style={{ marginLeft: '36px', width: '150px' }}>
                  <Skeleton width={64} height={64} variant="rectangular" />
                  <Skeleton
                    width={116}
                    height={24}
                    variant="rectangular"
                    style={{ marginTop: '8px' }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    width: '100px',
                  }}
                >
                  <Skeleton width={44} height={29} variant="rectangular" />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    width: '450px',
                  }}
                >
                  <Skeleton width={413} height={187} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '10px', width: '130px' }}>
                  <Skeleton width={108} height={29} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '100px', width: '130px' }}>
                  <Skeleton width={68} height={29} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '90px', width: '130px' }}>
                  <Skeleton width={68} height={29} variant="rectangular" />
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '30px' }}>
                <div style={{ marginLeft: '36px', width: '150px' }}>
                  <Skeleton width={64} height={64} variant="rectangular" />
                  <Skeleton
                    width={116}
                    height={24}
                    variant="rectangular"
                    style={{ marginTop: '8px' }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    width: '100px',
                  }}
                >
                  <Skeleton width={44} height={29} variant="rectangular" />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    width: '450px',
                  }}
                >
                  <Skeleton width={413} height={187} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '10px', width: '130px' }}>
                  <Skeleton width={108} height={29} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '100px', width: '130px' }}>
                  <Skeleton width={68} height={29} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '90px', width: '130px' }}>
                  <Skeleton width={68} height={29} variant="rectangular" />
                </div>
              </div>
            </div>
            <Skeleton width={1568} height={34} variant="rectangular" />
          </div>
        ) : (
          <>
            <GridWrap
              style={{
                height: 'calc(100vh - 250px)',
                minHeight: '300px',
                width: '100%',
                marginTop: '24px',
              }}
            >
              <DataGrid
                disableColumnMenu
                disableColumnFilter
                rows={swproductlist}
                columns={columns}
                rowHeight={228}
                onCellClick={handelCellClick}
                hideFooterPagination
                hideFooter
              />
            </GridWrap>
            {totalPage && totalPage > 1 && (
              <Pagination
                count={totalPage}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              />
            )}
            <Menu
              open={moreMenu !== null}
              className="more-menu"
              onClose={handleMoreMenuClose}
              anchorReference="anchorPosition"
              anchorPosition={
                moreMenu !== null
                  ? { top: moreMenu.mouseY, left: moreMenu.mouseX }
                  : undefined
              }
              PaperProps={{
                style: { width: '154px' },
              }}
            >
              <MenuItem
                divider
                onClick={goToVersionList}
              >{t`View all version`}</MenuItem>
              {u && (
                <MenuItem divider onClick={onItemClick}>{t`Modify`}</MenuItem>
              )}
              {d && <MenuItem onClick={onDelete}>{t`Delete`}</MenuItem>}
            </Menu>
          </>
        )}
      </div>
      <ModalPopup open={swAppPopup} title={popupTitle} popupWidth={544}>
        <SwAppAddModify
          disturbDsn={disturbDsn}
          setProductPopup={setSwAppPopup}
          data={selectedData}
          getSwProduct={getSwProductList}
        />
      </ModalPopup>
      <ModalPopup open={openProgress > 0} title="" popupWidth={408}>
        <DownloadProgress progress={progress} />
      </ModalPopup>
    </>
  );
};

export default SwAppList;

const Buttons = styled(Button)``;

const GridWrap = styled.div`
  .MuiDataGrid-cell {
    align-items: flex-start !important;
    padding-top: 16px !important;
  }
  .text-btn {
    font-weight: 700 !important;
    color: #0076ce;
    font-size: 16px;
    line-height: 24px;
  }
  .text-btn:hover {
    color: #2290e2;
  }
  .text-btn svg {
    fill: #0076ce;
  }
  .text-btn:hover svg {
    fill: #2290e2;
  }
`;
