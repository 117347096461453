/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useCallback, useEffect, ChangeEvent } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import 'react-quill/dist/quill.snow.css';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import { useAppDispatch } from '../../app/hooks';

interface Props {
  fileName: FileInterface[];
  files: File[];
  setFiles: (files: File[]) => void;
  deleteFileIds?: number[];
  setDeleteFileIds?: (files: number[]) => void;
  externalFiles?: string[];
  setExternalFiles?: (filename: string[]) => void;
  showFileList?: boolean;
  loadFileSize?: number;
}

const FileUpload = ({
  fileName,
  setFiles,
  files,
  deleteFileIds,
  setDeleteFileIds,
  externalFiles,
  setExternalFiles,
  showFileList,
  loadFileSize,
}: Props) => {
  const dispatch = useAppDispatch();
  const [savedFiles, setSavedFiles] = useState<FileInterface[]>(fileName);
  const [progress, setProgress] = useState<number[]>([]);
  const [externalCheck, setExternalCheck] = useState<string[]>([]);
  const [savedCheck, setSavedCheck] = useState<number[]>([]);
  const [rejectedFileRejection, setRejectedFileRejection] = useState<
    FileRejection[]
  >([]);

  const FILE_MAX_SIZE: number = parseInt(
    `${process.env.REACT_APP_MAX_FILE_SIZE}`,
    10,
  );

  useEffect(() => {
    setSavedFiles(fileName);
  }, [fileName]);

  useEffect(() => {
    if (loadFileSize && loadFileSize >= 0) {
      setProgress(
        files.map((_file, _index) => {
          let totalSize = 0;
          files.forEach((_fileSize, _indexF) => {
            if (_index < _indexF) {
              totalSize += _fileSize.size;
            }
          });
          if (
            totalSize < loadFileSize &&
            totalSize + _file.size > loadFileSize
          ) {
            return Math.round((100 * loadFileSize) / (totalSize + _file.size));
          }
          if (totalSize + _file.size <= loadFileSize) {
            return 100;
          }
          return 0;
        }),
      );
    }
  }, [loadFileSize]);

  // File upload onChange 이벤트
  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      setFiles(files.concat(acceptedFiles));
      setProgress(progress.concat(acceptedFiles.map(file => 0)));
    },
    [files, setFiles],
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      let msg = '';

      fileRejections.forEach(rejection => {
        msg += `'${rejection.file.name}'\r\n`;
        rejection.errors.forEach(fileError => {
          msg += `${fileError.message}\r\n`;
        });
      });

      dispatch(openAlert({ message: msg, type: 'error' }));
      setRejectedFileRejection(fileRejections);
    },
    [setRejectedFileRejection],
  );

  const fileValidator = (file: File) => {
    console.log(`MAX SIZE - ${FILE_MAX_SIZE}`);
    if (file.size >= FILE_MAX_SIZE) {
      // dispatch(openAlert({ message: t`Modified group.`, type: 'error' }));
      console.log(`${file.name} -  Size : ${file.size}`);
      return {
        code: 'MAXSIZE',
        message: `파일 크기가 허용 제한을 초과했습니다.`,
      };
    }
    console.log(`${file.name} -  Size : ${file.size} - OK!!`);
    return null;
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    validator: fileValidator,
    onDropAccepted,
    onDropRejected,
    maxFiles: 5,
  });

  const onFileClick = (id: number) => {
    setFiles(files.filter((file, index) => index !== id));
  };

  const onDeleteFile = (id: number) => {
    if (deleteFileIds && setDeleteFileIds) {
      setDeleteFileIds([...deleteFileIds, id]);
      setSavedFiles(savedFiles.filter(file => file.id !== id));
      setSavedCheck(savedCheck.filter(saved => saved !== id));
    }
  };

  const onDeleteExternalFiles = (name: string) => {
    if (setExternalFiles && externalFiles) {
      setExternalFiles(externalFiles.filter(item => item !== name));
      setExternalCheck(externalCheck.filter(ext => ext !== name));
    }
  };

  return (
    <Wrap>
      <Box className="btnGroup">
        <Button
          className="btn-black"
          variant="contained"
          size="small"
          onClick={open}
          // style={{ background: '#000', marginBottom: '16px' }}
        >
          {t`This PC`}
        </Button>
      </Box>
      <div className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="dropzone_bg" />
          <Typography className="dropzone-text">{t`Register by dragging the file here.`}</Typography>
        </div>
        {showFileList && files.length > 0 && (
          <div className="drivefile-wrap">
            {files.map((file, index) => (
              <div key={index} className="drivefile-row">
                <div className="drivefile-icon">
                  <InsertDriveFileIcon sx={{ width: '46px', height: '46px' }} />
                </div>
                <div className="drivefile-name">
                  <Typography title={file.name}>{file.name}</Typography>
                  <LinearProgress
                    variant="determinate"
                    value={progress[index] || 0}
                    sx={{
                      borderRadius: 5,
                      height: 22,
                      border: '3px solid #E6E6E6',
                      outline: '1px solid #C8C8C8',
                      background: 'transparent',
                    }}
                  />
                </div>
                <div className="drivefile-delete-icon">
                  <IconButton
                    aria-label="delete"
                    onClick={() => onFileClick(index)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="10"
                        cy="10"
                        r="9.5"
                        fill="white"
                        stroke="#616161"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.2105 6.7736C12.915 6.47749 12.4718 6.47749 12.1763 6.7736L10.0095 8.9451L7.84268 6.7736C7.54721 6.47749 7.104 6.47749 6.80852 6.7736C6.51305 7.06971 6.51305 7.51388 6.80852 7.81L8.97534 9.9815L6.80852 12.153C6.51305 12.4491 6.51305 12.8933 6.80852 13.1894C6.95626 13.3374 7.15324 13.3868 7.35022 13.3868C7.54721 13.3868 7.74419 13.3374 7.89193 13.1894L10.0587 11.0179L12.2256 13.1894C12.3733 13.3374 12.5703 13.3868 12.7673 13.3868C12.9643 13.3868 13.1612 13.3374 13.309 13.1894C13.6044 12.8933 13.6044 12.4491 13.309 12.153L11.1422 9.9815L13.309 7.81C13.506 7.51388 13.506 7.06971 13.2105 6.7736Z"
                        fill="#616161"
                      />
                    </svg>
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
        {showFileList &&
          (savedFiles.length > 0 ||
            (externalFiles && externalFiles?.length > 0)) && (
            <div className="divTable">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead" />
                  <Typography
                    component="div"
                    className="divTableHead"
                  >{t`File name`}</Typography>
                  <div className="divTableHead" />
                </div>
              </div>
              <div className="divTableBody">
                {savedFiles.map((file, index) => (
                  <div className="divTableRow" key={index}>
                    <div className="divTableCell">
                      <Checkbox
                        aria-label={file.fileName}
                        key={file.id.toString()}
                        value={savedCheck.includes(file.id)}
                        defaultChecked={false}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (
                            event.target.checked &&
                            !savedCheck.includes(file.id)
                          ) {
                            setSavedCheck([...savedCheck, file.id]);
                          } else if (
                            !event.target.checked &&
                            savedCheck.includes(file.id)
                          ) {
                            setSavedCheck(
                              savedCheck.filter(saved => saved !== file.id),
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="divTableCell">
                      <Typography className="filename-title text-overflow">
                        {file.fileName}
                        <div className="tool-tip">{file.fileName}</div>
                      </Typography>
                    </div>
                    <div
                      className="divTableCell"
                      style={{ textAlign: 'right', paddingRight: '4px' }}
                    >
                      {savedCheck.includes(file.id) && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => onDeleteFile(file.id)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="10"
                              cy="10"
                              r="9.5"
                              fill="white"
                              stroke="#616161"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.2105 6.7736C12.915 6.47749 12.4718 6.47749 12.1763 6.7736L10.0095 8.9451L7.84268 6.7736C7.54721 6.47749 7.104 6.47749 6.80852 6.7736C6.51305 7.06971 6.51305 7.51388 6.80852 7.81L8.97534 9.9815L6.80852 12.153C6.51305 12.4491 6.51305 12.8933 6.80852 13.1894C6.95626 13.3374 7.15324 13.3868 7.35022 13.3868C7.54721 13.3868 7.74419 13.3374 7.89193 13.1894L10.0587 11.0179L12.2256 13.1894C12.3733 13.3374 12.5703 13.3868 12.7673 13.3868C12.9643 13.3868 13.1612 13.3374 13.309 13.1894C13.6044 12.8933 13.6044 12.4491 13.309 12.153L11.1422 9.9815L13.309 7.81C13.506 7.51388 13.506 7.06971 13.2105 6.7736Z"
                              fill="#616161"
                            />
                          </svg>
                        </IconButton>
                      )}
                    </div>
                  </div>
                ))}
                {externalFiles?.map((name, index) => (
                  <div className="divTableRow" key={index + savedFiles.length}>
                    <div className="divTableCell">
                      <Checkbox
                        aria-label={name}
                        key={name}
                        value={externalCheck.includes(name)}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (
                            event.target.checked &&
                            !externalCheck.includes(name)
                          ) {
                            setExternalCheck([...externalCheck, name]);
                          } else if (
                            !event.target.checked &&
                            externalCheck.includes(name)
                          ) {
                            setExternalCheck(
                              externalCheck.filter(ext => ext !== name),
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="divTableCell text-overflow">
                      <Typography title={name}>
                        {name} <div className="tool-tip">{name}</div>
                      </Typography>
                    </div>
                    <div className="divTableCell">
                      {externalCheck.includes(name) && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => onDeleteExternalFiles(name)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="10"
                              cy="10"
                              r="9.5"
                              fill="white"
                              stroke="#616161"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.2105 6.7736C12.915 6.47749 12.4718 6.47749 12.1763 6.7736L10.0095 8.9451L7.84268 6.7736C7.54721 6.47749 7.104 6.47749 6.80852 6.7736C6.51305 7.06971 6.51305 7.51388 6.80852 7.81L8.97534 9.9815L6.80852 12.153C6.51305 12.4491 6.51305 12.8933 6.80852 13.1894C6.95626 13.3374 7.15324 13.3868 7.35022 13.3868C7.54721 13.3868 7.74419 13.3374 7.89193 13.1894L10.0587 11.0179L12.2256 13.1894C12.3733 13.3374 12.5703 13.3868 12.7673 13.3868C12.9643 13.3868 13.1612 13.3374 13.309 13.1894C13.6044 12.8933 13.6044 12.4491 13.309 12.153L11.1422 9.9815L13.309 7.81C13.506 7.51388 13.506 7.06971 13.2105 6.7736Z"
                              fill="#616161"
                            />
                          </svg>
                        </IconButton>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
    </Wrap>
  );
};

export default FileUpload;

const Wrap = styled.div`
  .btn-black {
    background: rgb(0, 0, 0) !important;
    margin-bottom: 16px;
    padding: 8px 10px;
    box-sizing: border-box;
    letter-spacing: 0.01em;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .dropzone {
    text-align: center;
  }
  .dropzone_bg {
    box-sizing: border-box;
    background-image: url('/images/dropzone_bg.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
  }
  .dropzone-text {
    font-size: 14px;
  }
  .divTable {
    width: 100%;
    border: 1px solid #c8c8c8;
    /* border-radius: 4px 4px 0px 0px; */
    margin-top: 32px;
    overflow: hidden;
  }
  .divTableHeading {
    background: #000;
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    color: #fff;
    height: 45px;
    line-height: 45px;
    display: table-caption;
    .divTableHead {
      padding-left: 10px;
      font-size: 16px;
      line-height: unset;
    }
  }
  .divTableCell {
    border-bottom: 1px solid #c8c8c8 !important;
    /* padding: 0 4px; */
  }

  .divTableCell .MuiCheckbox-root {
    padding: 7px 4px;
  }

  .file-preview-box {
    border: 1px solid #9f9f9f;
    border-radius: 4px;
    margin-top: 32px;
    .title {
      height: 45px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      background: #000000;
      color: #fff;
    }
    .list-wrap {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .list {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      align-items: center;
    }
  }
  .drivefile-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    width: 100%;
    .drivefile-row {
      min-height: 69px;
      max-height: 90px;
      display: flex;
      align-items: center;
      background: #e6e6e6;
      border-radius: 4px;
      margin: 4px 0px;
      padding: 11.5px 13px;
      .drivefile-icon {
        margin-right: 30px;
        fill-opacity: 0.7;
      }
      .drivefile-name {
        width: 100%;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          /* white-space: nowrap; */
          margin-bottom: 4px;
          max-width: 312px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.3em;
          max-height: 3.6em;
          word-break: break-all;
        }
      }
      .drivefile-delete-icon {
        align-items: flex-start;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        button {
          padding: 0px;
        }
      }
    }
  }
  .MuiLinearProgress-bar {
    transform: translateX(-101%);
    border-radius: 25px !important;
  }
  .text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 400px;
    max-width: 400px;
    font-size: 16px;
    line-height: 24px;
  }

  .tool-tip {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 16px 16px;
    position: absolute;
    max-width: 420px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    box-sizing: border-box;
    word-break: break-all;
    white-space: pre-line;
    line-height: 24px;
  }
  .filename-title:hover {
    .tool-tip {
      visibility: visible;
      z-index: 999999999;
    }
  }
`;
