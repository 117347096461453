import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Input from '../common/Input';
import { t } from '@lingui/macro';
import { customAxios } from '../../lib/customAxios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { openAlert } from '../../features/alertModal/AlertModalSlice';

interface Props {
  setOpen: (isOpen: boolean) => void;
  getTermsList: () => void;
  data?: TermsUseInterface;
}
const TermsRegisterForm = ({ setOpen, getTermsList, data }: Props) => {
  const dispatch = useAppDispatch();
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const [termsItemEn, setTermsItemEn] = useState<string>('');
  const [termsItemEnError, setTermsItemEnError] = useState<boolean>(false);
  const [termsItemEnHelptext, setTermsItemEnHelptext] = useState<string>('');
  const [termsItemKo, setTermsItemKo] = useState<string>('');
  const [termsItemKoError, setTermsItemKoError] = useState<boolean>(false);
  const [termsItemKoHelptext, setTermsItemKoHelptext] = useState<string>('');
  const [reqYn, setReqYn] = useState<boolean>(false);
  const [useYn, setUseYn] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setTermsItemEn(data.termsItem.en);
      setTermsItemKo(data.termsItem.ko);
      setReqYn(data.reqYn === 'Y');
      setUseYn(data.useYn === 'Y');
    }
  }, [data]);

  const handleTermsItemEnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTermsItemEn(e.target.value);
  };

  const validationTermsItemEn = () => {
    return true;
  };

  const handleTermsItemKoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTermsItemKo(e.target.value);
  };

  const validationTermsItemKo = () => {
    return true;
  };

  const onSave = () => {
    if (validationTermsItemEn() && validationTermsItemKo()) {
      if (!data && c) {
        customAxios
          .post('/mdx/V1.0/setting/agrTermsUse', {
            termsItem: { ko: termsItemKo, en: termsItemEn },
            reqYn: reqYn ? 'Y' : 'N',
            useYn: useYn ? 'Y' : 'N',
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: '추가 되었습니다.' }),
            );
            if (result.payload) {
              setOpen(false);
              getTermsList();
            }
          });
      } else if (data && u) {
        customAxios
          .put(`/mdx/V1.0/setting/agrTermsUse/${data.id}`, {
            termsItem: { ko: termsItemKo, en: termsItemEn },
            reqYn: reqYn ? 'Y' : 'N',
            useYn: useYn ? 'Y' : 'N',
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: '수정 되었습니다.' }),
            );
            if (result.payload) {
              setOpen(false);
              getTermsList();
            }
          });
      }
    }
  };

  const handleReqYnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReqYn(event.target.checked);
  };

  const handleUseYnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUseYn(event.target.checked);
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Input
        id="version"
        label="약관 EN"
        variant="outlined"
        value={termsItemEn}
        onChange={handleTermsItemEnChange}
        error={termsItemEnError}
        helperText={termsItemEnHelptext}
        fullWidth
        sx={{
          marginBottom: '24px',
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: '#A8A7A7',
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: '#0076CE',
            },
          },
        }}
      />
      <Input
        id="version"
        label="약관 KO"
        variant="outlined"
        value={termsItemKo}
        onChange={handleTermsItemKoChange}
        error={termsItemKoError}
        helperText={termsItemKoHelptext}
        fullWidth
        sx={{
          marginBottom: '24px',
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: '#A8A7A7',
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: '#0076CE',
            },
          },
        }}
      />
      <FormControlLabel
        sx={{ marginTop: '15px' }}
        control={
          <Checkbox checked={reqYn} onChange={handleReqYnChange} name="Req" />
        }
        label="Req"
      />
      <FormControlLabel
        sx={{ marginTop: '15px' }}
        control={
          <Checkbox checked={useYn} onChange={handleUseYnChange} name="Use" />
        }
        label="Use"
      />
      <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
        {(c || u) && (
          <Button variant="contained" size="large" onClick={onSave}>
            {data ? t`Modify` : t`Add`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={onCancel}>
          {t`Cancel`}
        </Button>
      </Box>
    </div>
  );
};

export default TermsRegisterForm;
