import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';
import { t } from '@lingui/macro';

interface Props {
  progress: number;
}

const DownloadProgress = ({ progress }: Props) => {
  return (
    <Wrap>
      <LinearProgress
        variant="determinate"
        value={progress | 0}
        sx={{
          borderRadius: 5,
          height: 22,
          border: '3px solid #fff',
          outline: '1px solid #C8C8C8',
        }}
      />
      <p className="status-msg">{t`Downloading`}</p>
    </Wrap>
  );
};

export default DownloadProgress;

const Wrap = styled.div`
  height: 95px;
  .MuiLinearProgress-root {
    background: #fff;
  }
  .MuiLinearProgress-bar {
    border-radius: 25px;
    transform: translateX(0%);
    background: #0076ce;
  }
  .status-msg {
    width: 100%;
    text-align: center;
    margin: 16px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }
`;
