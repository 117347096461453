import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { customAxios } from '../../lib/customAxios';
import Box from '@mui/material/Box';
import { t } from '@lingui/macro';

interface Props {
  setProductPopup: (isOpen: boolean) => void;
  swObjectKeys: string[];
  setSwObjectKeys: (arr: string[]) => void;
}

const SwAppVersionInstallFile = ({
  setProductPopup,
  swObjectKeys,
  setSwObjectKeys,
}: Props) => {
  const [sTempFileList, setSTempFileList] = useState<TempFileList[]>([]);
  const [checked, setChecked] = useState<number[]>([]);

  useEffect(() => {
    getTempFileList();
  }, []);

  const getTempFileList = () => {
    const url = '/mdx/V1.0/swm/sw/temp/version/file';
    customAxios.get<TempFileList[]>(url).then(response => {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].id = i;
      }
      setSTempFileList(response.data);
      setChecked(
        swObjectKeys.map(
          item => response.data.find(file => file.objectKey === item)?.id,
        ) as number[],
      );
    });
  };

  const handleToggle = (value: number, objectKey: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
      setSwObjectKeys(swObjectKeys.concat([objectKey]));
      // swObjectKeys.add(objectKey);
    } else {
      newChecked.splice(currentIndex, 1);
      setSwObjectKeys(swObjectKeys.filter(item => item !== objectKey));
      // swObjectKeys.delete(objectKey);
      // swObjectKeys.splice(objectKey);
    }
    setChecked(newChecked);
  };

  const handleCancel = () => {
    setProductPopup(false);
  };

  const handleNext = () => {
    setProductPopup(false);
  };

  return (
    <div>
      <div>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            maxHeight: 500,
            overflow: 'auto',
            marginTop: '30px',
            border: '0.5px solid #C8C8C8',
          }}
        >
          {sTempFileList.map(value => {
            const labelId = `checkbox-list-label-${value.objectKey}`;
            return (
              <ListItem key={value.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value.id, value.objectKey)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={value.objectKey.substring(
                      value.objectKey.lastIndexOf('/') + 1,
                      value.objectKey.length,
                    )}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
      <Box className="btnGroup" sx={{ '& button': { m: 1, mt: 4 } }}>
        <Button variant="contained" size="large" onClick={handleCancel}>
          {t`Cancel`}
        </Button>
        <Button variant="outlined" size="large" onClick={handleNext}>
          {t`Next`}
        </Button>
      </Box>
    </div>
  );
};

export default SwAppVersionInstallFile;
