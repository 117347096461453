import { useEffect, useState, MouseEvent } from 'react';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import CategoryRegisterForm from '../../components/admin/CategoryRegisterForm';
import ItemRegisterForm from '../../components/admin/ItemRegisterForm';
import ModalPopup from '../../components/common/ModalPopup';
import { customAxios } from '../../lib/customAxios';
import IconAdd from '../../assets/images/icon_add.svg';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import { t } from '@lingui/macro';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import MoreVert from '../../assets/icon/MoreVert';
import ItemUpload from '../../assets/icon/ItemUpload';
import ItemDownload from '../../assets/icon/ItemDownload';
import ArrowDown from '../../assets/icon/ArrowDown';

const CategoryScreen = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { c, r, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const [popupTitle, setPopupTitle] = useState<string>('');
  const [category, setCategory] = useState<CategoryInterface[]>([]);
  const [categoryPopup, setCategoryPopup] = useState<boolean>(false);
  const [seletedCategory, setSeletedCategory] = useState<
    CategoryInterface | undefined
  >(undefined);
  const [item, setItem] = useState<ItemInterface[]>([]);
  const [itemPopup, setItemPopup] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ItemInterface | undefined>(
    undefined,
  );
  const [categorySelectionModel, setCategorySelectionModel] =
    useState<GridSelectionModel>([]);
  const [selectedCategoryRowId, setSelectedCategoryRowId] = useState<number>();
  const [moreMenuCategory, setMoreMenuCategory] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [selectedItemRowId, setSelectedItemRowId] = useState<number>();
  const [moreMenuItem, setMoreMenuItem] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [itemSelectionModel, setItemSelectionModel] =
    useState<GridSelectionModel>([]);

  useEffect(() => {
    getCategory();
  }, [r, location.key]);

  const getCategory = () => {
    if (r) {
      customAxios
        .get<ResultType<CategoryInterface>>(`/mdx/V1.0/setting/category`)
        .then(response => setCategory(response.data.content));
    }
  };

  const getItem = (caId: number) => {
    customAxios
      .get<ItemInterface[]>(`/mdx/V1.0/setting/category/${caId}/item`)
      .then(response => setItem(response.data));
  };

  const handleCategoryAdd = () => {
    setSeletedCategory(undefined);
    setPopupTitle(t`Add Category`);
    setCategoryPopup(true);
  };

  const handleItemAdd = () => {
    setSelectedItem(undefined);
    setPopupTitle(t`Add Item`);
    setItemPopup(true);
  };

  const handleItemsDelete = () => {
    if (itemSelectionModel.length > 0 && seletedCategory) {
      customAxios
        .put(`/mdx/V1.0/setting/category/${seletedCategory.id}`, {
          ...seletedCategory,
          categoryItems: item
            .filter(data => data.id && itemSelectionModel.includes(data.id))
            .map(data => {
              return { ...data, mode: 'DELETE' };
            }),
        })
        .then(() => getItem(seletedCategory.id));
    }
  };

  const handleCategoryClose = () => {
    setMoreMenuCategory(null);
  };

  const handleCategoryModify = () => {
    if (u) {
      const item = category.find(item => item.id === selectedCategoryRowId);
      if (item) {
        handleCategoryClose();
        setPopupTitle(t`Modify Category`);
        setSeletedCategory(item);
        setCategoryPopup(true);
      }
    }
  };

  const handleCategoryDelete = async () => {
    if (d) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the category?` }),
      );
      if (result.payload) {
        customAxios
          .delete(`/mdx/V1.0/setting/category/${selectedCategoryRowId}`)
          .then(() => {
            handleCategoryClose();
            getCategory();
          });
      }
    }
  };

  const handleCategorysDelte = async () => {
    if (d) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the category?` }),
      );
      // if (result.payload) {
      //   customAxios.delete(`/mdx/V1.0/setting/category/${id}`).then(() => {
      //     handleCategoryClose();
      //     getCategory();
      //   });
      // }
    }
  };

  const handleCategoryRowClick = (params: GridRowParams) => {
    if (params.row) {
      setSeletedCategory(params.row as CategoryInterface);
      getItem(params.row.id);
    }
  };

  const handleCategoryMoreInfo = (
    event: MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setSelectedCategoryRowId(Number(id));
    setMoreMenuCategory(
      moreMenuCategory === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleItemMoreInfo = (
    event: MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setSelectedItemRowId(Number(id));
    setMoreMenuItem(
      moreMenuItem === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };
  const categoryCol: GridColumns = [
    {
      field: 'cgCd',
      headerName: t`Code`,
      minWidth: 150,
    },
    {
      field: 'cgName',
      headerName: t`Category Name`,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'modify',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconButton-${params.row.id}`}
              onClick={event => {
                handleCategoryMoreInfo(event, `${params.row.id}`);
              }}
              id={`app-modify-${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  const handleItemClose = () => {
    setMoreMenuItem(null);
  };

  const handleItemModify = () => {
    if (u) {
      const selected = item.find(target => target.id === selectedItemRowId);
      if (item) {
        handleItemClose();
        setPopupTitle(`Modify Item`);
        setSelectedItem(selected);
        setItemPopup(true);
      }
    }
  };

  const handleItemDelete = async () => {
    if (d && seletedCategory) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the item?` }),
      );
      if (result.payload) {
        customAxios
          .put(`/mdx/V1.0/setting/category/${seletedCategory.id}`, {
            ...seletedCategory,
            categoryItems: item
              .filter(data => data.id && selectedItemRowId === data.id)
              .map(data => {
                return { ...data, mode: 'DELETE' };
              }),
          })
          .then(() => {
            getItem(seletedCategory.id);
            dispatch(openAlert({ message: t`Deleted item.` }));
          });
      }
    }
  };

  const itemCol: GridColumns = [
    {
      field: 'code',
      headerName: t`Code`,
      minWidth: 150,
    },
    {
      field: 'name',
      headerName: t`Item Name`,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'modify',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconItemButton-${params.row.id}`}
              onClick={event => {
                handleItemMoreInfo(event, `${params.row.id}`);
              }}
              id={`Item-modify-${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  return (
    <Wrap>
      <div className="content">
        <div className="admin">
          <div className="categoryGroup">
            <div>
              <div className="itemTitle">
                <Typography>{t`Category Code Management`}</Typography>
                <Box className="btnGroup" sx={{ '& button': { ml: 1, mb: 3 } }}>
                  {c && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleCategoryAdd}
                      startIcon={<img src={IconAdd} alt="" />}
                    >
                      {t`Add Category`}
                    </Button>
                  )}
                  {d && categorySelectionModel.length > 0 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleCategorysDelte}
                    >
                      {t`Delet Category`}
                    </Button>
                  )}
                </Box>
              </div>
              <div style={{ height: 265, width: '100%' }}>
                <DataGrid
                  disableColumnMenu
                  disableColumnFilter
                  rows={category}
                  columns={categoryCol}
                  rowHeight={44}
                  disableSelectionOnClick
                  onRowClick={handleCategoryRowClick}
                  hideFooterPagination
                  hideFooter
                  checkboxSelection
                  onSelectionModelChange={newSelectionModel => {
                    setCategorySelectionModel(newSelectionModel);
                  }}
                  selectionModel={categorySelectionModel}
                />
              </div>
              <Menu
                open={moreMenuCategory !== null}
                className="more-menu"
                onClose={handleCategoryMoreInfo}
                anchorReference="anchorPosition"
                anchorPosition={
                  moreMenuCategory !== null
                    ? {
                        top: moreMenuCategory.mouseY,
                        left: moreMenuCategory.mouseX,
                      }
                    : undefined
                }
                PaperProps={{
                  style: { width: '154px' },
                }}
              >
                <MenuItem divider onClick={handleCategoryModify}>
                  {t`Modify`}
                </MenuItem>
                {d && (
                  <MenuItem onClick={handleCategoryDelete}>
                    {t`Delete`}
                  </MenuItem>
                )}
              </Menu>
            </div>
            <div className="item-select-msg-wrap">
              <div className="icon">
                <ArrowDown />
              </div>
              {seletedCategory === undefined && (
                <Typography className="item-select-msg">
                  {t`Select Category Code Management, and then select Item Code Management.`}
                </Typography>
              )}
            </div>
            {seletedCategory && (
              <div>
                <div className="itemTitle">
                  <Typography>{t`Item Code Management`}</Typography>
                  <Box
                    className="btnGroup"
                    sx={{ '& button': { ml: 1, mb: 3 } }}
                  >
                    <Button
                      variant="text"
                      size="small"
                      className="text-btn"
                      sx={{ mr: 1 }}
                      startIcon={<ItemUpload />}
                    >
                      {t`Item List Upload (Excel file)`}
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      className="text-btn"
                      startIcon={<ItemDownload />}
                    >
                      {t`Item Form Download (Excel file)`}
                    </Button>
                    {c && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleItemAdd}
                        startIcon={<img src={IconAdd} alt="" />}
                      >
                        {t`Add Item`}
                      </Button>
                    )}
                    {d && itemSelectionModel.length > 0 && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleItemsDelete}
                      >
                        {t`Delete Item`}
                      </Button>
                    )}
                  </Box>
                </div>
                <div style={{ height: 265, width: '100%' }}>
                  <DataGrid
                    disableColumnMenu
                    disableColumnFilter
                    rows={item}
                    columns={itemCol}
                    rowHeight={44}
                    selectionModel={itemSelectionModel}
                    disableSelectionOnClick
                    onSelectionModelChange={newSelectionModel => {
                      setItemSelectionModel(newSelectionModel);
                    }}
                    hideFooterPagination
                    hideFooter
                    checkboxSelection
                  />
                </div>
                <Menu
                  open={moreMenuItem !== null}
                  className="more-menu"
                  onClose={handleItemMoreInfo}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    moreMenuItem !== null
                      ? {
                          top: moreMenuItem.mouseY,
                          left: moreMenuItem.mouseX,
                        }
                      : undefined
                  }
                  PaperProps={{
                    style: { width: '154px' },
                  }}
                >
                  <MenuItem divider onClick={handleItemModify}>
                    {t`Modify`}
                  </MenuItem>
                  {d && (
                    <MenuItem onClick={handleItemDelete}>{t`Delete`}</MenuItem>
                  )}
                </Menu>
              </div>
            )}
          </div>
        </div>
        <ModalPopup open={categoryPopup} title={popupTitle} popupWidth={544}>
          <CategoryRegisterForm
            setOpen={setCategoryPopup}
            getCategory={getCategory}
            data={seletedCategory}
          />
        </ModalPopup>
        <ModalPopup open={itemPopup} title={popupTitle} popupWidth={544}>
          <ItemRegisterForm
            category={seletedCategory}
            setOpen={setItemPopup}
            selectedData={selectedItem}
            getItems={getItem}
          />
        </ModalPopup>
      </div>
    </Wrap>
  );
};

export default CategoryScreen;

const Wrap = styled.div`
  table {
    background: #fff;
    th {
      height: 45px;
      color: #2b2b2b;
      font-weight: bold;
      background: #f3f3f3;
      font-size: 16px;
    }
    td {
      height: 44px;
      font-size: 16px;
      border-bottom: 0px;
    }
  }
  .itemTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: keep-all;
    white-space: nowrap;
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #2b2b2b;
    }
  }
  .item-select-msg-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    .icon {
      margin-bottom: 19px;
      height: 24px;
    }
    .item-select-msg {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #878585;
    }
  }
  .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root {
    display: none;
  }
  .MuiDataGrid-cell {
    border-bottom: none !important;
  }
  .text-btn {
    font-weight: 700 !important;
    color: #0076ce;
    font-size: 16px;
    line-height: 24px;
  }
  .text-btn:hover {
    color: #2290e2;
  }
  .text-btn svg {
    fill: #0076ce;
  }
  .text-btn:hover svg {
    fill: #2290e2;
  }
`;
