import React from 'react';
import ReactDOM from 'react-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import store from './app/store';
import App from './App';
import { dynamicActivate } from './i18n';
import { injectStore } from './lib/customAxios';
import '@fontsource/noto-sans-kr';

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

injectStore(store);

// dynamicActivate(
//   localStorage.getItem('lang') || window.navigator.language.split('-')[0],
// );
dynamicActivate('en');

ReactDOM.render(
  <I18nProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </I18nProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
