import Typography from '@mui/material/Typography';
import IconInfo from '../../assets/images/icon_info.svg';
import { t } from '@lingui/macro';
import styled from 'styled-components';

const PasswordTooltip = ({ label = 'Password' }: { label?: string }) => {
  return (
    <HtmlTooltip>
      <div className="tooltip-wrap">
        <div className="title-password">
          <Typography component="span">{label}</Typography>
          <img src={IconInfo} alt="info" />
        </div>
        <div className="tooltip-box">
          <Typography className="title">Password Rules</Typography>
          <ul>
            <Typography component="li">
              Between 8 to 20 characters inclusive
            </Typography>
            <Typography component="li">
              Combination of alphabetical letters(A-Z, a-z), numbers, or special
              characters (-_()[]{}~!@#$%^&*()_+).
            </Typography>
            <Typography component="li">
              Exclude more than 3 consecutively repeated numbers(Eg. 1111, 2222,
              3333, 4444)
            </Typography>
            <Typography component="li">
              Exclude more than 3 consecutive numbers(Eg. 1234, 5678)
            </Typography>
            <Typography component="li">
              Password should not be identical to ID
            </Typography>
          </ul>
        </div>
      </div>
    </HtmlTooltip>
  );
};

const HtmlTooltip = styled.div`
  .tooltip-box {
    display: none;
    padding: 16px;
    position: absolute;
    width: 450px;
    background: #ffffff;
    /* border: 1px solid #d4d8e1; */
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    left: 73px;
    top: 22px;
    z-index: 999;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 8px;
    }
    ul {
      margin: 0px;
      li {
        list-style: disc;
        margin-left: 16px;
        letter-spacing: -0.5px;
        color: #6a7086;
      }
    }
  }
  .tooltip-wrap {
    position: relative;
    height: 15px;
    cursor: pointer;
    &:hover {
      .tooltip-box {
        display: block;
      }
    }
  }
  .title-password {
    position: absolute;
    transform: translate(11px, 2px);
    background: #fff;
    z-index: 100;
    padding: 0 3px;
    span {
      padding-right: 2px;
      color: #4d4c4c;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }

  .icon-info {
    position: absolute;
    position: absolute;
    left: 74px;
    top: 7px;
    padding: 0px 6px;
    background: #fff;
    z-index: 999;
  }
`;

export default PasswordTooltip;
