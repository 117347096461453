import { configureStore, Store } from '@reduxjs/toolkit';
import LoadingReducer from '../features/loading/LoadingSlice';
import MessageboxReducer from '../features/messagebox/MessageboxSlice';
import UserinfoReducer from '../features/userinfo/UserinfoSlice';
import MyMenuReducer from '../features/mymenu/MymenuSlice';
import ConfirmModalReducer from '../features/confirmModal/ConfirmModalSlice';
import AlertModalReducer from '../features/alertModal/AlertModalSlice';

const store = configureStore({
  reducer: {
    loading: LoadingReducer,
    messagebox: MessageboxReducer,
    userinfo: UserinfoReducer,
    mymenu: MyMenuReducer,
    confirmModal: ConfirmModalReducer,
    alertModal: AlertModalReducer,
  },
});

export interface ThunkExtraArguments {
  store: Store;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
