import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import Logo from '../assets/images/app-logo.svg';
import { t } from '@lingui/macro';
import CopyRight from '../components/common/CopyRight';
import Typography from '@mui/material/Typography';

const NotFound = () => {
  return (
    <Wrap>
      <div className="error-page-header">
        <img src={Logo} alt="logo" />
      </div>
      <div className="content-wrap">
        <Typography className="error-msg">404</Typography>
        <Typography className="error-msg3">
          The page you were looking for does not exist or is no longer
          available.
          <br />
          Please recheck the URL you have entered.
        </Typography>
        <NavLink to="/">
          <Button variant="contained" size="large">
            {t`Go Home`}
          </Button>
        </NavLink>
      </div>
      <CopyRight />
    </Wrap>
  );
};

export default NotFound;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: #ffffff;
  .error-page-header {
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center; */
    padding: 24px;
    position: absolute;
    height: 113px;
    width: 100%;
    left: 0px;
    top: 0px;
    background: #f2f5f8;
    box-sizing: border-box;
  }
  .error-msg {
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 56px;
    text-transform: capitalize;
    color: #878585;
  }
  .error-msg2 {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    margin-top: 65px;
  }
  .error-msg3 {
    width: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    word-break: keep-all;
    margin: 105px 0px;
  }
  a {
    text-decoration: none;
    button {
      text-decoration: none;
      font-style: normal;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      font-size: 16px !important;
      width: 370px;
      height: 46px;
    }
  }
  .content-wrap {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 7%;
    padding-bottom: 8%;
  }
`;
