import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  title: string;
  popupWidth: number;
  children: JSX.Element;
}

const ModalPopup = ({ open, title, popupWidth, children }: Props) => {
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: popupWidth,
            bgcolor: 'background.paper',
            border: '0px',
            p: 4,
            overflowY: 'auto',
            maxHeight: '100%',
            borderRadius: '16px',
            boxShadow:
              '0px 18px 24px 12px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
          }}
        >
          <Box id="modal-modal-title" style={{ marginBottom: '25px' }}>
            <Typography className="modalTitle">{title}</Typography>
          </Box>
          <Box id="modal-modal-description">{children}</Box>
        </Box>
      </Modal>
    </div>
  );
};
export default ModalPopup;
