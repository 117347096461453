import { useState } from 'react';
import styled from 'styled-components';
import RegisterCompletion from '../../components/auth/RegisterCompletion';
import RegisterForm from '../../components/auth/RegisterForm';
import CopyRight from '../../components/common/CopyRight';

const RegisterScreen = () => {
  const [isRegisterComplete, setRegisterComplete] = useState<boolean>(false);
  return (
    <>
      {!isRegisterComplete && (
        <Wrap>
          <RegisterForm setComplete={setRegisterComplete} />
          <CopyRight />
        </Wrap>
      )}
      {isRegisterComplete && (
        <Wrap2>
          <RegisterCompletion />
          <CopyRight />
        </Wrap2>
      )}
    </>
  );
};

export default RegisterScreen;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  flex-direction: column;
`;
const Wrap2 = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
