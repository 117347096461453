import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initManu: MenuInterface = {
  id: -1,
  name: '',
  navigateUrl: '',
  parentId: -1,
  permission: 0,
  menuIconSvg: null,
  selectedMenuIconSvg: null,
  c: false,
  r: false,
  u: false,
  d: false,
};

const initialState: MyMenuList = {
  menu: [],
  parentMenu: undefined,
  currentMenu: initManu,
};

const MymenuSlice = createSlice({
  name: 'mymenu',
  initialState,
  reducers: {
    setMymenu: (state, action: PayloadAction<MenuInterface[]>) => {
      state.menu = action.payload;
    },
    initMymenu: state => {
      state.menu = [];
    },
    setCurrentMenu: (state, action: PayloadAction<MenuInterface>) => {
      state.currentMenu = { ...action.payload };
    },
    initCurrentMenu: state => {
      state.currentMenu = initManu;
    },
    setParentMenu: (
      state,
      action: PayloadAction<MenuInterface | undefined>,
    ) => {
      state.parentMenu = action.payload;
    },
  },
});

export const {
  setMymenu,
  initMymenu,
  setCurrentMenu,
  initCurrentMenu,
  setParentMenu,
} = MymenuSlice.actions;
export default MymenuSlice.reducer;
