const ItemDownload = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.5 9.5H15.5V3.5H9.5V9.5H5.5L12.5 16.5L19.5 9.5ZM5.5 18.5V20.5H19.5V18.5H5.5Z" />
    </svg>
  );
};

export default ItemDownload;
