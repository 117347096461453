import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Input from '../common/Input';
import { t } from '@lingui/macro';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { customAxios } from '../../lib/customAxios';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Editor from '../common/Editor';

interface Props {
  setOpen: (isOpen: boolean) => void;
  getTermsVersionList: (id: number) => void;
  selectedTerms?: TermsUseInterface;
  data?: TermsVersionInterface;
}
const TermsVersionRegisterForm = ({
  setOpen,
  getTermsVersionList,
  selectedTerms,
  data,
}: Props) => {
  const dispatch = useAppDispatch();
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const [version, setVersion] = useState<string>('');
  const [versionError, setVersionError] = useState<boolean>(false);
  const [versionHelptext, setVersionHelptext] = useState<string>('');
  const [contentEn, setContentEn] = useState<string>('');
  const [contentEnError, setContentEnError] = useState<boolean>(false);
  const [contentEnHelptext, setContentEnHelptext] = useState<string>('');
  const [contentKo, setContentKo] = useState<string>('');
  const [contentKoError, setContentKoError] = useState<boolean>(false);
  const [contentKoHelptext, setContentKoHelptext] = useState<string>('');
  const [useYn, setUseYn] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setVersion(`${data.version}`);
      setContentEn(data.content.en);
      setContentKo(data.content.ko);
      setUseYn(data.useYn === 'Y');
    }
  }, [data]);

  const handleVersionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVersion(e.target.value);
  };

  const validationVersion = () => {
    return true;
  };

  const handleContentKoChange = (contents: string) => {
    setContentKo(contents);
  };

  const validationContentKo = () => {
    return true;
  };

  const handleContentEnChange = (contents: string) => {
    setContentEn(contents);
  };

  const validationContentEn = () => {
    return true;
  };

  const handleUseYnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUseYn(event.target.checked);
  };

  const onSave = () => {
    if (
      validationVersion() &&
      validationContentKo() &&
      validationContentEn() &&
      selectedTerms
    ) {
      if (!data && c) {
        customAxios
          .post(`/mdx/V1.0/setting/agrTermsUse/${selectedTerms.id}/version`, {
            version,
            content: { ko: contentKo, en: contentEn },
            useYn: useYn ? 'Y' : 'N',
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: '추가 되었습니다.' }),
            );
            if (result.payload) {
              setOpen(false);
              getTermsVersionList(selectedTerms.id);
            }
          });
      } else if (data && u) {
        customAxios
          .put(`/mdx/V1.0/setting/agrTermsUse/version/${data.id}`, {
            version,
            content: { ko: contentKo, en: contentEn },
            useYn: useYn ? 'Y' : 'N',
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: '수정 되었습니다.' }),
            );
            if (result.payload) {
              setOpen(false);
              getTermsVersionList(selectedTerms.id);
            }
          });
      }
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Input
        id="version"
        label="version"
        variant="outlined"
        value={version}
        onChange={handleVersionChange}
        error={versionError}
        helperText={versionHelptext}
        fullWidth
        sx={{
          marginBottom: '24px',
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: '#A8A7A7',
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: '#0076CE',
            },
          },
        }}
      />
      <p>Ko</p>
      <div style={{ height: '300px' }}>
        <Editor
          key="ko"
          contents={contentKo}
          setContents={handleContentKoChange}
          imageUploadUrl="/mdx/V1.0/common/image/temp"
          styles={{ height: '220px' }}
        />
      </div>
      <p>En</p>
      <div style={{ height: '300px' }}>
        <Editor
          key="en"
          contents={contentEn}
          setContents={handleContentEnChange}
          imageUploadUrl="/mdx/V1.0/common/image/temp"
          styles={{ height: '220px' }}
        />
      </div>
      <FormControlLabel
        sx={{ marginTop: '15px' }}
        control={
          <Checkbox checked={useYn} onChange={handleUseYnChange} name="Use" />
        }
        label="Use"
      />
      <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
        {(c || u) && (
          <Button variant="contained" size="large" onClick={onSave}>
            {data ? t`Modify` : t`Add`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={onCancel}>
          {t`Cancel`}
        </Button>
      </Box>
    </div>
  );
};

export default TermsVersionRegisterForm;
