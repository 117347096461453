const ItemUpload = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 15.5L9.5 15.5L9.5 21.5L15.5 21.5L15.5 15.5L19.5 15.5L12.5 8.5L5.5 15.5ZM19.5 6.5L19.5 4.5L5.5 4.5L5.5 6.5L19.5 6.5Z" />
    </svg>
  );
};

export default ItemUpload;
