import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import store, { AppDispatch, RootState } from '../../app/store';

const name = 'confirmModal';

// https://wanago.io/2021/04/26/generic-confirmation-modal-redux-redux-toolkit-typescript/

const initialState: ConfirmModal = {
  type: undefined,
  title: undefined,
  okString: undefined,
  cancelString: undefined,
  message: '',
  isOpened: false,
  isConfirmed: false,
  isDeclined: false,
};

export const openConfirm = createAsyncThunk<
  boolean,
  {
    message: string;
    type?: string;
    title?: string;
    okString?: string;
    cancelString?: string;
  },
  { dispatch?: AppDispatch }
>(
  'confirmModal/openConfirm',
  async ({ message, type, title, okString, cancelString }, thunkApi) => {
    thunkApi.dispatch(
      openModal({ message, type, title, okString, cancelString }),
    );
    return new Promise<boolean>(resolve => {
      const unsubscribe = store.subscribe(() => {
        const state: RootState = store.getState();
        if (state.confirmModal.isConfirmed) {
          unsubscribe();
          resolve(true);
        }
        if (state.confirmModal.isDeclined) {
          unsubscribe();
          resolve(false);
        }
      });
    });
  },
);

const ConfirmModalSlice = createSlice({
  name,
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        message: string;
        type?: string;
        title?: string;
        okString?: string;
        cancelString?: string;
      }>,
    ) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.okString = action.payload.okString;
      state.cancelString = action.payload.cancelString;
      state.isOpened = true;
      state.isDeclined = false;
      state.isConfirmed = false;
    },
    confirmModal: state => {
      state.isConfirmed = true;
      state.isOpened = false;
      // state.message = '';
    },
    declineModal: state => {
      state.isDeclined = true;
      state.isOpened = false;
      state.type = undefined;
      // state.message = '';
    },
    initConfirm: state => {
      state.message = '';
    },
  },
});

export const { openModal, confirmModal, declineModal, initConfirm } =
  ConfirmModalSlice.actions;
export default ConfirmModalSlice.reducer;
