/* eslint-disable jsx-a11y/alt-text */
import Button from '@mui/material/Button';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { customAxios } from '../../lib/customAxios';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Input from '../common/Input';
import Typography from '@mui/material/Typography';

interface Props {
  setOpen: (open: boolean) => void;
  getMenu: () => void;
  data?: MenuDataInterface;
  parentData?: MenuDataInterface;
  order?: number;
}

const MenuRegisterForm = ({
  setOpen,
  getMenu,
  data,
  parentData,
  order,
}: Props) => {
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>('');
  const [nameHelptext, setNameHelptext] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [urlHelptext, setUrlHelptext] = useState<string>('');
  const [urlError, setUrlError] = useState<boolean>(false);
  const [menuIconSvg, setMenuIconSvg] = useState<string | null>(null);
  const [selectedMenuIconSvg, setSelectedMenuIconSvg] = useState<string | null>(
    null,
  );
  const [descr, setDescr] = useState<string>('');

  useEffect(() => {
    if (data) {
      setName(data.name);
      setUrl(data.navigateUrl);
      setMenuIconSvg(data.menuIconSvg);
      setSelectedMenuIconSvg(data.selectedMenuIconSvg);
      setDescr(data.descr);
    }
  }, [data]);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validationName = (value?: string): boolean => {
    const tempValue = value || name;
    if (tempValue === '') {
      setNameError(true);
      setNameHelptext(t`Please enter a menu name.`);
      return false;
    }
    setNameError(false);
    setNameHelptext('');
    return true;
  };

  const onChangeUrl = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const validationUrl = (value?: string): boolean => {
    const tempValue = value || url;
    if (tempValue === '') {
      setUrlError(true);
      setUrlHelptext(t`Please enter an URL.`);
      return false;
    }
    setUrlError(false);
    setUrlHelptext('');
    return true;
  };

  const onChangeDescr = (e: ChangeEvent<HTMLInputElement>) => {
    setDescr(e.target.value);
  };

  const handleCancle = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const nameV = validationName();
    const urlV = validationUrl();
    if (nameV && urlV) {
      if (parentData) {
        customAxios
          .post('/mdx/V1.0/setting/menu', {
            descr,
            name,
            navigateUrl: url,
            parentId: parentData.id,
            menuOrder: order,
            menuIconSvg,
            selectedMenuIconSvg,
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: t`Added menu.` }),
            );
            if (result.payload) {
              setOpen(false);
              getMenu();
            }
          });
      } else if (data) {
        customAxios
          .put(`/mdx/V1.0/setting/menu/${data.id}`, {
            descr,
            name,
            navigateUrl: url,
            parentId: data.parentId,
            menuOrder: data.menuOrder,
            menuIconSvg,
            selectedMenuIconSvg,
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: t`Modified menu.` }),
            );
            if (result.payload) {
              setOpen(false);
              getMenu();
            }
          });
      }
    }
  };

  const onChangeMenuIconSvg = (e: ChangeEvent<HTMLInputElement>) => {
    setMenuIconSvg(e.target.value);
  };

  const onChangeSelectedMenuIconSvg = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedMenuIconSvg(e.target.value);
  };

  const handleNameFocus = () => {
    setNameError(false);
    setNameHelptext('');
  };

  const handleNameBlur = () => {
    validationName();
  };

  const handleUrlFocus = () => {
    setUrlError(false);
    setUrlHelptext('');
  };

  const handleUrlBlur = () => {
    validationUrl();
  };

  return (
    <Wrap>
      <div className="row">
        <Typography className="title-span">{t`Menu Name`}</Typography>
        <Input
          id="menuName"
          placeholder={t`Menu Name`}
          variant="outlined"
          value={name}
          error={nameError}
          helperText={nameHelptext}
          inputProps={{ maxLength: 45 }}
          onChange={onChangeName}
          onFocus={handleNameFocus}
          onBlur={handleNameBlur}
          margin="none"
          fullWidth
        />
      </div>
      <div className="row">
        <Typography className="title-span">{t`Menu` + t`URL`}</Typography>
        <Input
          id="menuUrl"
          placeholder={t`Menu` + t`URL`}
          variant="outlined"
          value={url}
          error={urlError}
          helperText={urlHelptext}
          inputProps={{ maxLength: 200 }}
          onChange={onChangeUrl}
          onFocus={handleUrlFocus}
          onBlur={handleUrlBlur}
          margin="none"
          fullWidth
        />
      </div>
      <div className="row">
        <Typography className="title-span">Menu Icon Svg</Typography>
        <Input
          id="menuIconSvg"
          placeholder="Menu Icon Svg"
          variant="outlined"
          multiline
          rows={2}
          value={menuIconSvg}
          onChange={onChangeMenuIconSvg}
          margin="none"
          fullWidth
        />
      </div>
      {menuIconSvg && (
        <div>
          <div className="table-title">
            <Typography>아이콘 보기</Typography>
          </div>
          <div className="table-icon">
            <div className="icon-back">
              <img
                src={`data:image/svg+xml;base64,${btoa(menuIconSvg || '')}`}
              />
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <Typography className="title-span">Selected Menu Icon Svg</Typography>
        <Input
          id="menuIconSvg"
          placeholder="Selected Menu Icon Svg"
          variant="outlined"
          multiline
          rows={2}
          value={selectedMenuIconSvg}
          onChange={onChangeSelectedMenuIconSvg}
          margin="none"
          fullWidth
        />
      </div>
      {selectedMenuIconSvg && (
        <div>
          <div className="table-title">
            <Typography>아이콘 보기</Typography>
          </div>
          <div className="table-icon">
            <div className="icon-back">
              <img
                src={`data:image/svg+xml;base64,${btoa(
                  selectedMenuIconSvg || '',
                )}`}
              />
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <Typography className="title-span">{t`Menu Description`}</Typography>
        <Input
          id="menuDesc"
          placeholder={t`Menu Description`}
          variant="outlined"
          multiline
          rows={2}
          value={descr}
          inputProps={{ maxLength: 45 }}
          onChange={onChangeDescr}
          margin="none"
          fullWidth
        />
      </div>
      <Box className="btnGroup" sx={{ '& button': { ml: 1 }, marginTop: 3 }}>
        {(c || u) && (
          <Button variant="contained" size="large" onClick={handleSave}>
            {data ? t`Modify` : t`Add`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={handleCancle}>
          {t`Cancel`}
        </Button>
      </Box>
    </Wrap>
  );
};

export default MenuRegisterForm;

const Wrap = styled.div`
  .row {
    margin-bottom: 20px;
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .table-title {
    padding: 10px;
    background-color: #000;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .MuiTypography-root {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .table-icon {
    padding: 10px;
    border: 0.5px solid #9f9f9f;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    margin-bottom: 20px;
    .icon-back {
      background-color: #c8c8c8;
      padding: 10px;
      height: 24px;
    }
  }
`;
