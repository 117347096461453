import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  confirmModal,
  declineModal,
} from '../../features/confirmModal/ConfirmModalSlice';
import { t } from '@lingui/macro';

import styled from 'styled-components';

const ConfirmModal = () => {
  const dispatch = useAppDispatch();
  const { isOpened, message, type, title, okString, cancelString } =
    useAppSelector(state => state.confirmModal);

  const MessageType = ['success', 'error', 'warning'];

  const confirm = () => {
    dispatch(confirmModal());
  };

  const decline = () => {
    dispatch(declineModal());
  };

  return (
    <Wrap>
      <Dialog
        open={isOpened}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle
          id="confirm-dialog-title"
          style={{ display: 'flex', alignItems: 'center', padding: '0' }}
        >
          {type && type === 'success' && (
            <div className="title">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0003 2.6665C8.64033 2.6665 2.66699 8.63984 2.66699 15.9998C2.66699 23.3598 8.64033 29.3332 16.0003 29.3332C23.3603 29.3332 29.3337 23.3598 29.3337 15.9998C29.3337 8.63984 23.3603 2.6665 16.0003 2.6665ZM13.3337 22.6665L6.66699 15.9998L8.54699 14.1198L13.3337 18.8932L23.4537 8.77317L25.3337 10.6665L13.3337 22.6665Z"
                  fill="#4BB734"
                />
              </svg>
              {title || 'Success'}
            </div>
          )}
          {type && type === 'error' && (
            <div className="title">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0003 2.6665C8.62699 2.6665 2.66699 8.6265 2.66699 15.9998C2.66699 23.3732 8.62699 29.3332 16.0003 29.3332C23.3737 29.3332 29.3337 23.3732 29.3337 15.9998C29.3337 8.6265 23.3737 2.6665 16.0003 2.6665ZM22.667 20.7865L20.787 22.6665L16.0003 17.8798L11.2137 22.6665L9.33366 20.7865L14.1203 15.9998L9.33366 11.2132L11.2137 9.33317L16.0003 14.1198L20.787 9.33317L22.667 11.2132L17.8803 15.9998L22.667 20.7865Z"
                  fill="#E01919"
                />
              </svg>
              {title || 'Error'}
            </div>
          )}
          {type && type === 'warning' && (
            <div className="title">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33301 27.9998H30.6663L15.9997 2.6665L1.33301 27.9998ZM17.333 23.9998H14.6663V21.3332H17.333V23.9998ZM17.333 18.6665H14.6663V13.3332H17.333V18.6665Z"
                  fill="#FFA439"
                />
              </svg>
              {title || 'Warning'}
            </div>
          )}
          {!type && title && <div className="title">{title}</div>}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            paddingTop: '12px !important',
            paddingBottom: '32px !important',
          }}
        >
          <DialogContentText id="confirm-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Button
            onClick={confirm}
            variant="contained"
            size="large"
            className={type === 'error' ? 'error' : ''}
            // className={type === MessageType[1] ? 'button-error' : ''}
          >
            {okString || t`OK`}
          </Button>
          <Button onClick={decline} variant="outlined" size="large" autoFocus>
            {cancelString || t`Cancel`}
          </Button>
        </DialogActions>
      </Dialog>
    </Wrap>
  );
};

export default ConfirmModal;

const Wrap = styled.div`
  .button-error {
    background-color: #e01919 !important;
  }
  .title-h2 {
    padding-bottom: '12px';
  }
`;
