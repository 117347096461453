import React, { useState, ChangeEvent, useEffect } from 'react';
import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import { axios, AxiosResponse, customAxios } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import styled from 'styled-components';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Input from '../common/Input';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

/**
 * name 길이 제한 45
 * desrc 길이 제한 60
 */

interface Props {
  setOpen: (open: boolean) => void;
  getGroup: (pageIndex: number) => void;
  data?: GroupInterface;
  page: number;
}

const GroupRegisterForm = ({ setOpen, getGroup, data, page }: Props) => {
  const dispatch = useAppDispatch();
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const [name, setName] = useState('');
  const [nameHelptext, setNameHelptext] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [roleError, setRoleError] = useState<boolean>(false);
  const [roleHelptext, setRoleHelptext] = useState<string>('');
  const [descr, setDescr] = useState('');
  const [descrHelptext, setDescrHelptext] = useState<string>('');
  const [descrError, setDescrError] = useState<boolean>(false);
  const [role, setRole] = useState<RoleInterface[]>([]);
  const [selectedRole, setSelectedRole] = useState<number[]>([]);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescr(data.descr);

      axios
        .all([
          customAxios.get<AxiosResponse>(
            `/mdx/V1.0/setting/group/${data.id}/role`,
          ),
          customAxios.get<AxiosResponse>('/mdx/V1.0/setting/role'),
        ])
        .then(
          axios.spread((res1, res2) => {
            const dataMapping = res1.data as unknown as RoleInterface[];
            const data = res2.data as unknown as ResultType<RoleInterface>;
            const dataRole = data.content;
            setRole(dataRole);
            setSelectedRole(dataMapping.map(item => item.id));
          }),
        );
    } else {
      customAxios
        .get<ResultType<RoleInterface>>(`/mdx/V1.0/setting/role`)
        .then(response => {
          setRole(response.data.content);
        });
    }
  }, [data]);

  const onName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validationName = (value?: string): boolean => {
    const tempValue = value || name;
    if (tempValue === '') {
      setNameError(true);
      setNameHelptext(t`Please enter a group name.`);
      return false;
    }
    setNameError(false);
    setNameHelptext('');
    return true;
  };

  const onDescr = (e: ChangeEvent<HTMLInputElement>) => {
    setDescr(e.target.value);
  };

  const validationDescr = (value?: string): boolean => {
    const tempValue = value || descr;
    if (tempValue === '') {
      setDescrError(true);
      setDescrHelptext(t`Please enter a group description.`);
      return false;
    }
    setDescrError(false);
    setDescrHelptext('');
    return true;
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRole(selectedRole.concat([parseInt(event.target.id, 10)]));
    } else {
      setSelectedRole(
        selectedRole.filter(item => item !== parseInt(event.target.id, 10)),
      );
    }
  };

  // const validationRole = (): boolean => {
  //   if (selectedRole.length === 0) {
  //     setRoleError(true);
  //     setRoleHelptext('Role 선택하세요.');
  //     return false;
  //   }
  //   setRoleError(false);
  //   setRoleHelptext('');
  //   return true;
  // };

  const onSave = () => {
    const namev = validationName();
    const descrv = validationDescr();
    // const role = validationRole();
    if (namev && descrv) {
      if (!data && c) {
        customAxios
          .post<GroupInterface>('/mdx/V1.0/setting/group', {
            name,
            descr,
          })
          .then(res => {
            customAxios
              .post(`/mdx/V1.0/setting/group/${res.data.id}/role`, {
                roleIds: selectedRole,
              })
              .then(async () => {
                const result = await dispatch(
                  openAlert({ message: t`Added group.` }),
                );
                if (result.payload) {
                  setOpen(false);
                  getGroup(1);
                }
              });
          });
      } else if (data && u) {
        axios
          .all([
            customAxios.put(`/mdx/V1.0/setting/group/${data.id}`, {
              name,
              descr,
            }),
            customAxios.post(`/mdx/V1.0/setting/group/${data.id}/role`, {
              roleIds: selectedRole,
            }),
          ])
          .then(
            axios.spread(async (res1, res2) => {
              const result = await dispatch(
                openAlert({ message: t`Modified group.` }),
              );
              if (result.payload) {
                setOpen(false);
                getGroup(page);
              }
            }),
          );
      }
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  const handleNameFocus = () => {
    setNameError(false);
    setNameHelptext('');
  };
  const handleNameBlur = () => {
    validationName();
  };
  const handleDescrFocus = () => {
    setDescrError(false);
    setDescrHelptext('');
  };
  const handleDescrBlur = () => {
    validationDescr();
  };

  return (
    <Wrap>
      <Typography className="title-span">{t`Group Name`}</Typography>
      <Input
        id="name"
        variant="outlined"
        placeholder={t`Group Name`}
        value={name}
        onChange={onName}
        onFocus={handleNameFocus}
        onBlur={handleNameBlur}
        error={nameError}
        helperText={nameHelptext}
        inputProps={{ maxLength: 45 }}
        autoFocus
        fullWidth
        sx={{
          marginBottom: '24px',
        }}
      />
      <div style={{ marginBottom: '24px' }}>
        <Typography className="title-span">{t`Role`}</Typography>
        <FormControl error={roleError} fullWidth>
          <div className={`check-list-wrap${roleError ? ` error` : ``}`}>
            {role.map(item => (
              <FormControlLabel
                label={item.name}
                key={`${item.id}`}
                control={
                  <Checkbox
                    id={`${item.id}`}
                    onChange={handleRoleChange}
                    checked={selectedRole.includes(item.id)}
                  />
                }
              />
            ))}
          </div>
          {roleError && <FormHelperText>{roleHelptext}</FormHelperText>}
        </FormControl>
      </div>
      <Typography className="title-span">{`${t`Group`} ${t`Description`}`}</Typography>
      <Input
        id="descr"
        multiline
        rows={2}
        placeholder={`${t`Group`} ${t`Description`}`}
        value={descr}
        error={descrError}
        helperText={descrHelptext}
        inputProps={{ maxLength: 60 }}
        onChange={onDescr}
        onFocus={handleDescrFocus}
        onBlur={handleDescrBlur}
        fullWidth
        sx={{
          marginBottom: '24px',
        }}
      />

      <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
        {(c || u) && (
          <Button variant="contained" size="large" onClick={onSave}>
            {data ? t`Modify` : t`Add`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={onCancel}>
          {t`Cancel`}
        </Button>
      </Box>
    </Wrap>
  );
};

export default GroupRegisterForm;

const Wrap = styled.div`
  .check-list-wrap {
    border: 1px solid #c8c8c8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 19px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    label {
      height: 30px;
    }
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .error {
    border-color: #e01919;
  }
`;
