import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { closeMessage } from '../../features/messagebox/MessageboxSlice';
import { t } from '@lingui/macro';

const Messagebox = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(state => state.messagebox.open);
  const message = useAppSelector(state => state.messagebox.message);

  const handleClose = () => {
    dispatch(closeMessage());
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // popupWidth={544}
      sx={{ mt: 0, Width: 408 }}
    >
      <DialogTitle id="alert-dialog-title" />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          onClick={handleClose}
          autoFocus
        >
          {t`OK`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Messagebox;
