import { useEffect, useState, MouseEvent } from 'react';
import { customAxios } from '../../lib/customAxios';
import { t } from '@lingui/macro';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import IconAdd from '../../assets/images/icon_add.svg';
import ModalPopup from '../../components/common/ModalPopup';
import TermsRegisterForm from '../../components/admin/TermsRegisterForm';
import TermsVersionRegisterForm from '../../components/admin/TermsVersionRegisterForm';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import MoreVert from '../../assets/icon/MoreVert';

const TermsScreen = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { c, r, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const [termsList, setTermsList] = useState<TermsUseInterface[]>([]);
  const [termsPopupTitle, setTermsPopupTitle] = useState<string>('');
  const [termsPopup, setTermsPopup] = useState<boolean>(false);
  const [selectedTerms, setSelectedTerms] = useState<
    TermsUseInterface | undefined
  >(undefined);
  const [termsVersionList, setTermsVersionList] = useState<
    TermsVersionInterface[]
  >([]);
  const [termsVersionPopupTitle, setTermsVersionPopupTitle] =
    useState<string>('');
  const [termsVersionPopup, setTermsVersionPopup] = useState<boolean>(false);
  const [selectedTermsVersion, setSelectedTermsVersion] = useState<
    TermsVersionInterface | undefined
  >(undefined);
  const [selectedTermsRowId, setSelectedTermsRowId] = useState<number>();
  const [moreMenuTerms, setMoreMenuTerms] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [selectedTermsVersionRowId, setSelectedTermsVersionRowId] =
    useState<number>();
  const [moreMenuTermsVersion, setMoreMenuTermsVersion] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  useEffect(() => {
    getTermsList();
  }, [r, location.key]);

  const getTermsList = () => {
    if (r) {
      customAxios
        .get<ResultType<CategoryInterface>>(`/mdx/V1.0/setting/agrTermsUse`)
        .then(response => {
          setTermsList(response.data.content);
        });
    }
  };

  const getTermsVersion = (id: number) => {
    if (r && selectedTerms) {
      customAxios
        .get<ResultType<CategoryInterface>>(
          `/mdx/V1.0/setting/agrTermsUse/${id}/version?sort=version,DESC`,
        )
        .then(response => {
          setTermsVersionList(response.data.content);
        });
    }
  };

  const handleTermsModify = () => {
    if (u) {
      const item = termsList.find(item => item.id === selectedTermsRowId);
      if (item) {
        handleMoreMenuTermsClose();
        setTermsPopupTitle('약관 수정');
        setSelectedTerms(item);
        setTermsPopup(true);
      }
    }
  };

  const handleTermsDelete = async () => {
    if (d) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the category?` }),
      );
      if (result.payload) {
        customAxios
          .delete(`/mdx/V1.0/setting/agrTermsUse/${selectedTermsRowId}`)
          .then(() => {
            openAlert({ message: '삭제 되었습니다.' });
            handleMoreMenuTermsClose();
            getTermsList();
          });
      }
    }
  };

  //   const handleCategorysDelte = async () => {
  //     if (d) {
  //       const result = await dispatch(
  //         openConfirm({ message: t`Are you sure to delete the category?` }),
  //       );
  //       // if (result.payload) {
  //       //   customAxios.delete(`/mdx/V1.0/setting/category/${id}`).then(() => {
  //       //     handleCategoryClose();
  //       //     getCategory();
  //       //   });
  //       // }
  //     }
  //   };

  const handleTermsMoreInfo = (
    event: MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setSelectedTermsRowId(Number(id));
    setMoreMenuTerms(
      moreMenuTerms === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleMoreMenuTermsClose = () => {
    setMoreMenuTerms(null);
  };

  const termsCol: GridColumns = [
    {
      field: 'termsItem',
      headerName: t`Title`,
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div>ko:{params.row.termsItem.ko}</div>
            <div>en:{params.row.termsItem.en}</div>
          </>
        );
      },
    },
    {
      field: 'reqYn',
      headerName: '필수',
      minWidth: 100,
    },
    {
      field: 'useYn',
      headerName: '사용',
      minWidth: 100,
    },
    {
      field: 'modify',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconButton-terms-${params.row.id}`}
              onClick={event => {
                handleTermsMoreInfo(event, `${params.row.id}`);
              }}
              id={`terms-modify-${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  const handleTermsRowClick = (params: GridRowParams) => {
    if (params.row) {
      setSelectedTerms(params.row as TermsUseInterface);
      getTermsVersion(params.row.id);
    }
  };

  const handleTermsAdd = () => {
    if (c) {
      setTermsPopupTitle('약관 추가');
      setSelectedTerms(undefined);
      setTermsPopup(true);
    }
  };

  const handleTermsVersionAdd = () => {
    if (c) {
      setTermsVersionPopupTitle('약관 버전 추가');
      setSelectedTermsVersion(undefined);
      setTermsVersionPopup(true);
    }
  };

  const handleTermsVersionModify = () => {
    if (u) {
      const item = termsVersionList.find(
        item => item.id === selectedTermsVersionRowId,
      );
      if (item) {
        handleMoreMenuTermsVersionClose();
        setTermsVersionPopupTitle('약관 버전 수정');
        setSelectedTermsVersion(item);
        setTermsVersionPopup(true);
      }
    }
  };

  const handleTermsVersionDelete = async () => {
    if (d && selectedTerms) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the category?` }),
      );
      if (result.payload) {
        customAxios
          .delete(
            `/mdx/V1.0/setting/agrTermsUse/version/${selectedTermsVersionRowId}`,
          )
          .then(() => {
            openAlert({ message: '삭제 되었습니다.' });
            handleMoreMenuTermsVersionClose();
            getTermsVersion(selectedTerms.id);
          });
      }
    }
  };

  const handleMoreMenuTermsVersionClose = () => {
    setMoreMenuTermsVersion(null);
  };

  const handleMoreInfoTermsVerion = (
    event: MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setSelectedTermsVersionRowId(Number(id));
    setMoreMenuTermsVersion(
      moreMenuTermsVersion === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const termsVerionCol: GridColumns = [
    {
      field: 'version',
      headerName: '버전',
      minWidth: 100,
    },
    {
      field: 'content',
      headerName: '내용',
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div>ko:{params.row.content.ko}</div>
            <div>en:{params.row.content.en}</div>
          </>
        );
      },
    },
    {
      field: 'useYn',
      headerName: '사용',
      minWidth: 100,
    },
    {
      field: 'modify',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconButton-TermsVersion-${params.row.id}`}
              onClick={event => {
                handleMoreInfoTermsVerion(event, `${params.row.id}`);
              }}
              id={`termsversion-modify-${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  return (
    <Wrap>
      <div>
        <div className="itemTitle">
          <Typography>약관</Typography>
          <Box className="btnGroup" sx={{ '& button': { ml: 1, mb: 3 } }}>
            {c && (
              <Button
                variant="contained"
                size="small"
                onClick={handleTermsAdd}
                startIcon={<img src={IconAdd} alt="" />}
              >
                약관 추가
              </Button>
            )}
          </Box>
        </div>
        <div style={{ height: 265, width: '100%' }}>
          <DataGrid
            disableColumnMenu
            disableColumnFilter
            rows={termsList}
            columns={termsCol}
            rowHeight={90}
            onRowClick={handleTermsRowClick}
            hideFooterPagination
            hideFooter
          />
        </div>
        <Menu
          open={moreMenuTerms !== null}
          className="more-menu"
          onClose={handleMoreMenuTermsClose}
          anchorReference="anchorPosition"
          anchorPosition={
            moreMenuTerms !== null
              ? { top: moreMenuTerms.mouseY, left: moreMenuTerms.mouseX }
              : undefined
          }
          PaperProps={{
            style: { width: '154px' },
          }}
        >
          <MenuItem divider onClick={handleTermsModify}>
            {t`Modify`}
          </MenuItem>
          {d && <MenuItem onClick={handleTermsDelete}>{t`Delete`}</MenuItem>}
        </Menu>
      </div>
      <div>
        <div className="itemTitle">
          <p>약관 버전</p>
          <Box className="btnGroup" sx={{ '& button': { ml: 1, mb: 3 } }}>
            {c && (
              <Button
                variant="contained"
                size="small"
                onClick={handleTermsVersionAdd}
                startIcon={<img src={IconAdd} alt="" />}
              >
                약관 버전 추가
              </Button>
            )}
          </Box>
        </div>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            disableColumnMenu
            disableColumnFilter
            rows={termsVersionList}
            columns={termsVerionCol}
            rowHeight={90}
            hideFooterPagination
            hideFooter
          />
        </div>
        <Menu
          open={moreMenuTermsVersion !== null}
          className="more-menu"
          onClose={handleMoreMenuTermsVersionClose}
          anchorReference="anchorPosition"
          anchorPosition={
            moreMenuTermsVersion !== null
              ? {
                  top: moreMenuTermsVersion.mouseY,
                  left: moreMenuTermsVersion.mouseX,
                }
              : undefined
          }
          PaperProps={{
            style: { width: '154px' },
          }}
        >
          <MenuItem divider onClick={handleTermsVersionModify}>
            {t`Modify`}
          </MenuItem>
          {d && (
            <MenuItem onClick={handleTermsVersionDelete}>{t`Delete`}</MenuItem>
          )}
        </Menu>
      </div>
      <ModalPopup open={termsPopup} title={termsPopupTitle} popupWidth={544}>
        <TermsRegisterForm
          setOpen={setTermsPopup}
          getTermsList={getTermsList}
          data={selectedTerms}
        />
      </ModalPopup>
      <ModalPopup
        open={termsVersionPopup}
        title={termsVersionPopupTitle}
        popupWidth={544}
      >
        <TermsVersionRegisterForm
          setOpen={setTermsVersionPopup}
          getTermsVersionList={getTermsVersion}
          selectedTerms={selectedTerms}
          data={selectedTermsVersion}
        />
      </ModalPopup>
    </Wrap>
  );
};

export default TermsScreen;

const Wrap = styled.div`
  table {
    background: #fff;
    th {
      height: 45px;
      color: #2b2b2b;
      font-weight: bold;
      background: #f3f3f3;
      font-size: 16px;
    }
    td {
      height: 44px;
      font-size: 16px;
      border-bottom: 0px;
    }
  }
  .itemTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: keep-all;
    white-space: nowrap;
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #2b2b2b;
    }
  }
  .item-select-msg-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    .icon {
      margin-bottom: 19px;
      height: 24px;
    }
    .item-select-msg {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #878585;
    }
  }
  .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root {
    display: none;
  }
  .MuiDataGrid-cell {
    border-bottom: none;
  }
`;
