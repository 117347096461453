/* eslint-disable camelcase */
import { useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import ModalPopup from '../../components/common/ModalPopup';
import UserSetupGroup from '../../components/admin/UserSetupGoup';
import { customAxios } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import SearchBox from '../../components/common/SearchBox';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getDate } from '../../lib/common';
import Pagination from '@mui/material/Pagination';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import CircleIcon from '@mui/icons-material/Circle';
import { useLocation } from 'react-router-dom';
import MoreVert from '../../assets/icon/MoreVert';

const UserScreen = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { r, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const [user, setUser] = useState<UserInterface[]>([]);
  const [productPopup, setProductPopup] = useState<boolean>(false);
  const [popuptitle, setPopupTitle] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined);
  const [rowsPerPage] = useState<number>(50);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [selectedData, setSelectedData] = useState<UserInterface | undefined>(
    undefined,
  );
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [moreMenu, setMoreMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  useEffect(() => {
    getUser(1);
  }, [r, location.key]);

  const getUser = (pageIndex: number, searchParam?: string) => {
    setSearch(searchParam);
    if (r) {
      setPage(pageIndex);
      setSearch(searchParam);

      customAxios
        .get<ResultType<UserInterface>>(
          searchParam === undefined || searchParam === ''
            ? `/mdx/V1.0/setting/user?page=${
                pageIndex - 1
              }&size=${rowsPerPage}&sort=createdDt,DESC`
            : `/mdx/V1.0/setting/user?page=${
                pageIndex - 1
              }&search=${searchParam}&size=${rowsPerPage}&sort=createdDt,DESC`,
        )
        .then(response => {
          setUser(response.data.content);
          if (response.data.totalPages === 0) {
            setTotalPage(undefined);
          } else {
            setTotalPage(response.data.totalPages);
          }
        });
    }
  };

  const getUserPage = (pageIndex: number) => {
    getUser(pageIndex);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    getUser(value);
  };

  const handleMoreInfo = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    setSelectedRowId(Number(id));
    setMoreMenu(
      moreMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleMoreMenuClose = () => {
    setMoreMenu(null);
  };

  const approve = (data: UserInterface) => {
    setPopupTitle(t`User Approval`);
    setSelectedData(data);
    setProductPopup(true);
  };

  const handleUserModify = () => {
    handleMoreMenuClose();
    setPopupTitle('Edit User Information');
    setSelectedData(user.find(item => item.id === selectedRowId));
    setProductPopup(true);
  };

  const handleUserDelete = async () => {
    if (d) {
      handleMoreMenuClose();
      const result = await dispatch(
        openConfirm({
          message: t`Are you sure to delete the user?`,
          okString: 'Delete',
          title: 'Delete',
          type: 'error',
        }),
      );
      if (result.payload) {
        customAxios
          .delete(`/mdx/V1.0/setting/user/${selectedRowId}`)
          .then(() => {
            getUser(page);
            dispatch(
              openAlert({
                message: t`Deleted user.`,
                title: 'Confirm',
                type: 'success',
              }),
            );
          });
      }
    }
  };

  const userCol: GridColumns = [
    {
      field: 'name',
      headerName: t`Name`,
      minWidth: 170,
      sortable: false,
      headerClassName: 'grid-title-app-name',
      renderCell: (params: GridRenderCellParams<string>) => {
        return <div style={{ marginLeft: '32px' }}>{params.value} </div>;
      },
    },
    {
      field: 'status',
      headerName: t`Approval Status`,
      minWidth: 210,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (params.value === 'EMAIL_VERIFIED') {
          return (
            <Button
              variant="text"
              onClick={() => {
                approve(params.row);
              }}
              sx={{
                color: '#FFA439',
                padding: '0 !important',
                fontSize: '16px',
                '& .MuiButton-startIcon': {
                  marginLeft: '-1px',
                },
              }}
              startIcon={
                <CircleIcon
                  fontSize="small"
                  sx={{ color: '#FFA439', fontSize: '8px !important' }}
                />
              }
            >
              {t`Waiting for Approval`}
            </Button>
          );
        }
        if (
          params.value &&
          [
            'SUBS_APPROVED',
            'MORE_INFO_TERMS',
            'RESET_PW_REQUESTED',
            'RESET_PW_EXPIRED',
          ].includes(params.value)
        ) {
          return (
            <StatusApproved>
              <div className="circle-icon" />
              <div>{t`Approved`}</div>
            </StatusApproved>
          );
        }
        if (
          params.value &&
          ['SUBS_REQUESTED', 'MORE_INFO_REQUIRED'].includes(params.value)
        ) {
          return (
            <StatusRequired>
              <div className="circle-icon"> </div>
              <div>{t`Waiting for Authentication`}</div>
            </StatusRequired>
          );
        }
        if (params.value === 'SUBS_REJECTED') {
          return (
            <StatusRejected>
              <div className="circle-icon"> </div>
              <div>{t`Rejected`}</div>
            </StatusRejected>
          );
        }
        return <div>{params.value}</div>;
      },
    },
    { field: 'username', headerName: t`ID`, minWidth: 250, sortable: false },
    {
      field: 'department',
      headerName: t`Organization`,
      minWidth: 120,
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'groupNames',
      headerName: t`Group`,
      minWidth: 100,
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'createdDt',
      headerName: t`Date`,
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return getDate(params.value || '');
      },
    },
    {
      field: 'apprUserName',
      headerName: t`Approver`,
      minWidth: 120,
      sortable: false,
    },
    {
      field: 'modify',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconButton-${params.row.id}`}
              onClick={event => {
                handleMoreInfo(event, `${params.row.id}`);
              }}
              id={`app-modify-${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  const onSearchClick = (search: string) => {
    getUser(1, search);
  };

  return (
    <div>
      <div>
        <Box
          className="btnGroup"
          sx={{ '& button': { m: 1 }, justifyContent: 'space-between', mb: 2 }}
        >
          <SearchBox
            label="Search by name, id, and group"
            searchClick={onSearchClick}
          />
        </Box>
        <GridWrap
          style={{
            height: 'calc(100vh - 210px)',
            minHeight: '300px',
            width: '100%',
            marginTop: '24px',
          }}
        >
          <DataGrid
            disableColumnMenu
            disableColumnFilter
            rows={user}
            columns={userCol}
            rowHeight={44}
            hideFooterPagination
            hideFooter
            disableSelectionOnClick
          />
        </GridWrap>
        {totalPage && totalPage > 1 && (
          <Pagination
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
        )}
        <Menu
          open={moreMenu !== null}
          className="more-menu"
          onClose={handleMoreMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            moreMenu !== null
              ? { top: moreMenu.mouseY, left: moreMenu.mouseX }
              : undefined
          }
          PaperProps={{
            style: { width: '154px' },
          }}
        >
          <MenuItem divider onClick={handleUserModify}>
            Edit
          </MenuItem>
          {d && <MenuItem onClick={handleUserDelete}>{t`Delete`}</MenuItem>}
        </Menu>
      </div>
      <ModalPopup open={productPopup} title={popuptitle} popupWidth={544}>
        <UserSetupGroup
          data={selectedData}
          setOpen={setProductPopup}
          getUser={getUserPage}
          page={page}
        />
      </ModalPopup>
    </div>
  );
};

export default UserScreen;

const GridWrap = styled.div`
  .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root {
    display: none;
  }
  .MuiDataGrid-cell {
    border-bottom: none;
  }
`;

const StatusRequired = styled.div`
  color: #0076ce;
  display: flex;
  justify-content: center;
  align-items: center;
  .circle-icon {
    width: 6px;
    height: 6px;
    background: #0076ce;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
  }
`;

const StatusApproved = styled.div`
  color: #4bb734;
  display: flex;
  justify-content: center;
  align-items: center;
  .circle-icon {
    width: 6px;
    height: 6px;
    background: #4bb734;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
  }
`;
const StatusRejected = styled.div`
  color: #e33030;
  display: flex;
  justify-content: center;
  align-items: center;
  .circle-icon {
    width: 6px;
    height: 6px;
    background: #e33030;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
  }
`;
