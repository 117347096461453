import { useState, useEffect, ChangeEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from '../../app/hooks';
import { customAxios } from '../../lib/customAxios';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

interface Props {
  setTerms: (isTerms: boolean) => void;
  setTermsIds: (termsIds: number[]) => void;
  termsIds: number[];
  error?: boolean;
  helperText?: string;
  isLogin?: boolean;
}

const TermsForm = ({
  setTerms,
  setTermsIds,
  termsIds,
  error,
  helperText,
  isLogin,
}: Props) => {
  const { id, lang } = useAppSelector(state => state.userinfo);
  const [termsList, setTermsList] = useState<TermsInterface[]>([]);

  useEffect(() => {
    if (isLogin) {
      customAxios
        .get<TermsInterface[]>(
          `mdx/V1.0/setting/agrTermsUse/targets?lang=${lang}&userId=${id}`,
        )
        .then(response => {
          setTermsList(
            response.data.map(item => {
              return { ...item, checked: false };
            }),
          );
        });
    } else {
      customAxios
        .get<TermsInterface[]>(
          `/mdx/V1.0/setting/agrTermsUse/targets?lang=${lang}`,
        )
        .then(response => {
          setTermsList(
            response.data.map(item => {
              return { ...item, checked: false };
            }),
          );
        });
    }
  }, []);

  const isTermsAllCheck = (): boolean => {
    let isCheck = true;
    termsList.forEach(item => {
      if (!(item.checked === true && isCheck)) {
        isCheck = false;
      }
    });

    return termsList.length === 0 ? false : isCheck;
  };

  const handleAllTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const temp = termsList.map(item => {
      return { ...item, checked: event.target.checked };
    });
    setTermsList(temp);
    setTermsIds(temp.map(item => item.verId));
    let terms = true;
    temp.forEach(item => {
      if (item.reqYn === 'Y' && !item.checked) {
        terms = false;
      }
    });
    setTerms(terms);
  };

  const handleTermsClick = (terms: TermsInterface) => {
    window.open(`/terms/${lang}/${terms.verId}`, '_blank');
  };

  const isTermsCheck = (id: number) => {
    const terms = termsList.find(item => item.verId === id);
    return terms ? terms.checked : false;
  };

  const handleTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const temp = termsList.map(item => {
      return item.verId.toString() === event.target.id
        ? { ...item, checked: event.target.checked }
        : item;
    });
    setTermsList(temp);
    setTermsIds(temp.map(item => item.verId));
    let terms = true;
    temp.forEach(item => {
      if (item.reqYn === 'Y' && !item.checked) {
        terms = false;
      }
    });
    setTerms(terms);
  };

  return (
    <Wrap>
      <div className="terms-list-wrap">
        <FormControlLabel
          sx={{ marginTop: '15px' }}
          key="all"
          control={
            <Checkbox
              checked={isTermsAllCheck()}
              onChange={handleAllTermsChange}
              name="terms"
            />
          }
          label={t`Agree to all`}
        />
        <FormControl error={error} fullWidth>
          <div className={`terms-list${error ? ` error` : ``}`}>
            {termsList.map(terms => {
              return (
                <div key={`${terms.verId}_div`}>
                  <FormControlLabel
                    sx={{ marginTop: '10px' }}
                    key={`${terms.verId}_label`}
                    control={
                      <Checkbox
                        key={`${terms.verId}_ck`}
                        id={`${terms.verId}`}
                        checked={isTermsCheck(terms.verId)}
                        onChange={handleTermsChange}
                        name="terms"
                      />
                    }
                    label={
                      terms.reqYn === 'Y'
                        ? `${t`[Required]`} ${terms.termsItem}`
                        : `${t`[Optional]`} ${terms.termsItem}`
                    }
                  />
                  <IconButton
                    aria-label="more"
                    key={`${terms.verId}_bt`}
                    onClick={() => handleTermsClick(terms)}
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.59009 17.09L13.1701 12.5L8.59009 7.91L10.0001 6.5L16.0001 12.5L10.0001 18.5L8.59009 17.09Z"
                        fill="#C8C8C8"
                      />
                    </svg>
                  </IconButton>
                </div>
              );
            })}
          </div>
          {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </div>
    </Wrap>
  );
};

export default TermsForm;

const Wrap = styled.div`
  .terms-list-wrap {
    label {
      margin-top: 8px;
    }
    .terms-list {
      border: 0.5px solid #c8c8c8;
      border-radius: 4px;
      overflow: auto;
      max-height: 84px;
      div {
        display: flex;
        justify-content: space-between;
        label {
          margin-top: 0;
          margin-left: 0;
        }
      }
    }
    .error {
      border-color: #e01919;
    }
  }
`;
