import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { axios, customAxios } from '../../lib/customAxios';
import { showMessage } from '../../features/messagebox/MessageboxSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { formatVersion } from '../../lib/common';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styled from 'styled-components';
import ModalPopup from '../common/ModalPopup';
import DownloadProgress from '../common/DownloadProgress';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import { t } from '@lingui/macro';
import 'react-quill/dist/quill.snow.css';

interface Props {
  swproductlist: SwProductInterface[];
}

const SwAppVersionInfo = ({ swproductlist }: Props) => {
  const dispatch = useAppDispatch();
  const { appId, versionId } = useParams();
  const { pathname } = useLocation();
  const { r } = useAppSelector(state => state.mymenu.currentMenu);
  const [swVersion, setSwVersion] = useState<SwVersionInterface | undefined>(
    undefined,
  );
  const [swVersionFile, setSwVersionFile] = useState<FileInterface[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [openProgress, setOpenProgress] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (appId && versionId && r)
      getSwVersion(parseInt(appId, 10), parseInt(versionId, 10));
  }, [appId, versionId]);

  useEffect(() => {
    if (progress === 100) {
      setOpenProgress(0);
      dispatch(openAlert({ message: t`Downloaded`, title: t`OK` }));
    }
  }, [progress]);

  const getSwVersion = (_appId: number, _verId: number) => {
    customAxios
      .get<SwVersionInterface>(`/mdx/V1.0/swm/sw/${_appId}/version/${_verId}`)
      .then(response => {
        setSwVersion(response.data);
        if (response.data.swVersionFileList)
          setSwVersionFile(response.data.swVersionFileList);
      });
  };

  const downloadFile = async (idList: GridRowId[]) => {
    const result = await dispatch(
      openConfirm({
        message: t`Do you want to download?`,
        title: t`Download`,
        okString: t`Download`,
      }),
    );
    if (result.payload && appId && versionId) {
      customAxios
        .get<SwVersionInterface>(
          `/mdx/V1.0/swm/sw/${parseInt(appId, 10)}/version/${parseInt(
            versionId,
            10,
          )}`,
        )
        .then(response => {
          if (response.data.swVersionFileList) {
            const tempCheckFileData = response.data.swVersionFileList.filter(
              item => idList.includes(item.id),
            );
            const filePerArr: number[] = Array(tempCheckFileData.length);

            tempCheckFileData.forEach((item, itemIndex) => {
              setOpenProgress(openProgress + 1);
              axios
                .get<Blob>(item.filePath, {
                  responseType: 'blob',
                  onDownloadProgress: progressEvent => {
                    filePerArr[itemIndex] = Math.round(
                      ((100 / tempCheckFileData.length) *
                        progressEvent.loaded) /
                        progressEvent.total,
                    );
                    let total = 0;
                    filePerArr.forEach(size => {
                      total += size;
                    });
                    setProgress(total);
                  },
                })
                .then(response => {
                  const fileTemp = tempCheckFileData.find(
                    item => item.filePath === response.config.url,
                  );
                  const filename = fileTemp ? fileTemp.fileName : 'test';
                  const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  setOpenProgress(openProgress - 1);
                })
                .catch(() => {
                  setOpenProgress(openProgress - 1);
                  dispatch(
                    openConfirm({
                      message: t`An error occurred while downloading. Would you like to try again?`,
                      okString: t`Retry`,
                    }),
                  );
                });
            });
          }
        });
    }
  };

  const columns: GridColumns = [
    {
      field: 'fileName',
      headerName: t`File name`,
      flex: 1,
      minWidth: 500,
      sortable: false,
    },
    {
      field: 'download',
      headerName: ' ',
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="text"
            className="btn-text"
            onClick={() => {
              downloadFile(Array.of(params.row.id));
            }}
            startIcon={<DownloadIcon />}
          >
            {t`Download`}
          </Button>
        );
      },
    },
  ];

  const copyURL = () => navigator.clipboard.writeText(window.location.href);

  const selectAllRow = () => {
    setSelectionModel(
      swVersionFile.map(item => {
        return item.id;
      }),
    );
  };

  const download = () => {
    if (selectionModel.length > 0) {
      downloadFile(selectionModel);
    } else {
      dispatch(showMessage('선택된 항목이 없습니다.'));
    }
  };

  return (
    <Wrap>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 1 }}
      >
        <NavLink
          to={`/main/${pathname.split('/')[2]}/appList`}
        >{t`All Apps`}</NavLink>
        <NavLink
          to={`/main/${pathname.split('/')[2]}/versionList/${appId}`}
        >{`"${
          swproductlist.find(item => appId && item.id === parseInt(appId, 10))
            ?.name
        }"`}</NavLink>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontSize: 20 }}
          color="text.primary"
        >
          {`"${formatVersion(`${swVersion?.version}`)}"`}
        </Typography>
      </Breadcrumbs>
      <div>
        <Box
          className="btnGroup"
          sx={{ '& button': { mr: 0, marginLeft: '20px', mb: 2 } }}
        >
          <Button variant="outlined" onClick={copyURL}>
            {t`Copy URL`}
          </Button>
          <Button variant="outlined" onClick={selectAllRow}>
            {t`Select All`}
          </Button>
          <Button variant="outlined" onClick={download}>
            {t`Download all at once`}
          </Button>
        </Box>
      </div>
      <div
        style={{
          background: '#fff',
          padding: '1.4em',
          borderRadius: '8px',
          minHeight: 'calc(100vh - 250px)',
        }}
      >
        <div className="btnGroup">
          <NavLink to={`/main/${pathname.split('/')[2]}/versionList/${appId}`}>
            <Button variant="contained" className="btn-black">
              {t`View all version`}
            </Button>
          </NavLink>
        </div>
        <div style={{ height: 'auto', width: '100%', paddingTop: '20px' }}>
          <DataGrid
            rows={swVersionFile}
            columns={columns}
            selectionModel={selectionModel}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel);
            }}
            headerHeight={45}
            rowHeight={45}
            autoHeight
            checkboxSelection
            disableColumnFilter
            disableColumnMenu
            hideFooterPagination
            hideFooter
          />
        </div>
        <div style={{ marginTop: 20, padding: 16 }}>
          <p className="note-title">{t`Release Note`}</p>
          {swVersion && (
            <span
              className="ql-editor"
              style={{ whiteSpace: 'pre-wrap', marginTop: 20, padding: 16 }}
              dangerouslySetInnerHTML={{ __html: swVersion.releaseNote }}
            />
          )}
        </div>
      </div>
      <ModalPopup open={openProgress > 0} title="" popupWidth={392}>
        <DownloadProgress progress={progress} />
      </ModalPopup>
    </Wrap>
  );
};

export default SwAppVersionInfo;

const Wrap = styled.div`
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
  .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root {
    display: none;
  }
  .MuiDataGrid-columnHeaders {
    background: #f6f6f6;
    div {
      color: #4d4c4c !important;
      font-weight: normal !important;
    }
  }
  .MuiDataGrid-cell {
    border-bottom: none;
  }
  .btn-text {
    color: #0076ce;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .note-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
  .btnGroup {
    a {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    .btn-black {
      color: #fff !important;
      background: rgb(0, 0, 0);
      padding: 8px 10px;
      box-sizing: border-box;
    }
  }
`;
