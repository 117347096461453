import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import store, { AppDispatch, RootState } from '../../app/store';

const name = 'alertModal';

const initialState: AlertModal = {
  type: undefined,
  title: undefined,
  actionString: undefined,
  message: '',
  isOpened: false,
  isConfirmed: false,
};

export const openAlert = createAsyncThunk<
  boolean,
  { message: string; type?: string; title?: string; actionString?: string },
  { dispatch?: AppDispatch }
>(
  'alertModal/openAlert',
  async ({ message, type, title, actionString }, thunkApi) => {
    thunkApi.dispatch(openModal({ message, type, title, actionString }));
    return new Promise<boolean>(resolve => {
      const unsubscribe = store.subscribe(() => {
        const state: RootState = store.getState();
        if (state.alertModal.isConfirmed) {
          unsubscribe();
          resolve(true);
        }
      });
    });
  },
);

const AlertModalSlice = createSlice({
  name,
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        message: string;
        type?: string;
        title?: string;
        actionString?: string;
      }>,
    ) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.actionString = action.payload.actionString;
      state.isOpened = true;
      state.isConfirmed = false;
    },
    confirmModal: state => {
      state.isConfirmed = true;
      state.isOpened = false;
      state.type = undefined;
      // state.message = '';
    },
    initConfirm: state => {
      state.message = '';
    },
  },
});

export const { openModal, confirmModal, initConfirm } = AlertModalSlice.actions;
export default AlertModalSlice.reducer;
