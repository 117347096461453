import React, { useEffect, useState } from 'react';
import SwAppList from '../../components/app/SwAppList';
import SwAppVersionInfo from '../../components/app/SwAppVersionInfo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { customAxios } from '../../lib/customAxios';
import SwAppVersionList from '../../components/app/SwAppVersionList';
import { hideLoading } from '../../features/loading/LoadingSlice';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import { t } from '@lingui/macro';

export type subMenuType = 'appList' | 'versionList' | 'versionInfo';

const AppScreen = () => {
  const { submenu } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { r } = useAppSelector(state => state.mymenu.currentMenu);
  const [rowsPerPage] = useState<number>(50);
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [menuType, setMenuType] = useState<subMenuType>('appList');
  const [swProductList, setSwProductList] = useState<SwProductInterface[]>([]);
  const [disturbDsn] = useState<string>('EXTERN_DISTURB');
  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      submenu &&
      ['appList', 'versionList', 'versionInfo'].includes(submenu)
    ) {
      setMenuType(submenu as subMenuType);
    } else {
      navigate(`/main/extapp/appList`);
    }
    if (submenu === 'appList') {
      getSwProductList();
    }
  }, [location.key]);

  useEffect(() => {
    if (r) getSwProductList();
  }, [r]);

  const getSwProductList = (searchParam?: string) => {
    setSearch(searchParam);
    const searchWord = searchParam && searchParam !== '' ? searchParam : search;
    if (r) {
      dispatch(hideLoading());
      customAxios
        .get<ResultType<SwProductInterface>>(
          searchWord === undefined || searchWord === ''
            ? `/mdx/V1.0/swm/sw?disturbDsn=${disturbDsn}&size=${rowsPerPage}`
            : `/mdx/V1.0/swm/sw?disturbDsn=${disturbDsn}&size=${rowsPerPage}&search=${searchWord}`,
        )
        .then(response => {
          if (response.data.totalPages === 0) {
            setTotalPage(undefined);
          } else {
            setTotalPage(response.data.totalPages);
          }
          if (
            searchWord &&
            searchWord !== '' &&
            response.data.content.length === 0
          ) {
            dispatch(
              openAlert({
                message: t`No results found for the content you entered.
                Please check the search terms again.`,
                type: 'error',
              }),
            );
          } else {
            setSwProductList(response.data.content);
          }
        });
    }
  };

  return (
    <div>
      {menuType === 'appList' && (
        <SwAppList
          disturbDsn={disturbDsn}
          swproductlist={swProductList}
          getSwProductList={getSwProductList}
          totalPage={totalPage}
          page={page}
          setPage={setPage}
        />
      )}
      {menuType === 'versionList' && (
        <SwAppVersionList swproductlist={swProductList} />
      )}
      {menuType === 'versionInfo' && (
        <SwAppVersionInfo swproductlist={swProductList} />
      )}
    </div>
  );
};

export default AppScreen;
