import styled from 'styled-components';
import CopyrightLogo from '../../assets/images/copyrightlogo.svg';

const CopyRight = () => {
  return (
    <Wrap>
      <div>
        <div className="copyright">
          <img alt="Seegene" src={CopyrightLogo} style={{ width: 88 }} />
          Copyright&nbsp;
          <span style={{ fontWeight: 'bold' }}>Seegene Corp.</span>&nbsp;All
          Rights Reserved.
          <p className="version">Version 1.00.000</p>
        </div>
      </div>
    </Wrap>
  );
};

export default CopyRight;

const Wrap = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  .copyright {
    margin: 10px 0px;
    img {
      margin-right: 20px;
    }
    display: flex;
    align-items: center;
    font-size: 12px;
    .version {
      margin-left: 20px;
    }
  }
`;
