import styled from 'styled-components';
import Input from '../common/Input';
import { t } from '@lingui/macro';
import { ChangeEvent, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppSelector } from '../../app/hooks';
import { customAxios } from '../../lib/customAxios';
import Typography from '@mui/material/Typography';

interface Props {
  id: number;
  page: number;
  setOpen: (open: boolean) => void;
  getUser: (pageIndex: number) => void;
  setCompletion: (completion: boolean) => void;
}

const UserRejectForm = ({
  id,
  page,
  setOpen,
  getUser,
  setCompletion,
}: Props) => {
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const [rejectionReasons, setRejectionReasons] = useState<string>('');
  const [rejectionReasonsError, setRejectionReasonsError] =
    useState<boolean>(false);
  const [rejectionReasonsHelptext, setRejectionReasonsHelptext] =
    useState<string>('');

  const handleChangeRejection = (e: ChangeEvent<HTMLInputElement>) => {
    setRejectionReasons(e.target.value);
  };

  const handleRejectionFocus = () => {
    setRejectionReasonsError(false);
    setRejectionReasonsHelptext('');
  };

  const validationReasons = () => {
    if (rejectionReasons === '') {
      setRejectionReasonsError(true);
      setRejectionReasonsHelptext('Please enter rejection reasons.');
      return false;
    }
    setRejectionReasonsError(false);
    setRejectionReasonsHelptext('');
    return true;
  };

  const handleRejectionBlur = () => {
    validationReasons();
  };

  const onSave = () => {
    if (c || u) {
      const vReasons = validationReasons();
      if (vReasons) {
        customAxios
          .put(`/mdx/V1.0/setting/user/${id}`, {
            status: 'SUBS_REJECTED',
            reason: rejectionReasons,
          })
          .then(() => {
            getUser(page);
            setOpen(false);
            setCompletion(true);
          });
      }
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Wrap>
      <div>
        <Typography className="title-span">{t`Rejection reasons`}</Typography>
        <Input
          id="descr"
          multiline
          rows={4}
          placeholder={t`Rejection reasons`}
          value={rejectionReasons}
          error={rejectionReasonsError}
          helperText={rejectionReasonsHelptext}
          inputProps={{ maxLength: 60 }}
          onChange={handleChangeRejection}
          onFocus={handleRejectionFocus}
          onBlur={handleRejectionBlur}
          fullWidth
          sx={{
            marginBottom: '24px',
          }}
        />
      </div>
      <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
        {(c || u) && (
          <Button variant="contained" size="large" onClick={onSave}>
            {t`OK`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={onCancel}>
          {t`Cancel`}
        </Button>
      </Box>
    </Wrap>
  );
};

export default UserRejectForm;

const Wrap = styled.div`
  .check-list-wrap {
    border: 1px solid #c8c8c8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 19px;
    height: 172px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    label {
      height: 30px;
    }
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .error {
    border-color: #e01919;
  }
`;
