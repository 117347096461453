/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { t } from '@lingui/macro';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { customAxios, axios, AxiosResponse } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { showMessage } from '../../features/messagebox/MessageboxSlice';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ModalPopup from '../common/ModalPopup';
import UserRejectForm from './UserRejectForm';
import Typography from '@mui/material/Typography';
import { FormHelperText } from '@mui/material';

interface Props {
  setOpen: (open: boolean) => void;
  getUser: (pageIndex: number) => void;
  data?: UserInterface;
  page: number;
}

const UserSetupGroup = ({ setOpen, data, getUser, page }: Props) => {
  const dispatch = useAppDispatch();
  const { u } = useAppSelector(state => state.mymenu.currentMenu);
  const [userGroup, setUserGroup] = useState<string>('');
  const [group, setGroup] = useState<GroupInterface[]>([]);
  const [userInfo, setUserInfo] = useState<UserInterface | undefined>(
    data || undefined,
  );
  const [rejectionReasonsPopup, setRejectionReasonsPopup] = useState(false);
  const [rejectionCompletion, setRejectionCompletion] = useState(false);
  const [groupError, setGroupError] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      getUserInfo();
      if (data.status === 'SUBS_APPROVED' || data.status === 'EMAIL_VERIFIED') {
        getGroupAndUserGroupMapping();
      }
    }
  }, [data]);

  useEffect(() => {
    if (rejectionCompletion) {
      setOpen(false);
    }
  }, [rejectionCompletion]);

  const getUserInfo = () => {
    if (data) {
      customAxios
        .get<UserInterface>(`/mdx/V1.0/setting/user/${data.id}`)
        .then(res => {
          setUserInfo(res.data);
        });
    }
  };

  const getGroupAndUserGroupMapping = () => {
    if (u && data) {
      axios
        .all([
          customAxios.get<AxiosResponse>(
            `/mdx/V1.0/setting/user/${data.id}/group`,
          ),
          customAxios.get<AxiosResponse>('/mdx/V1.0/setting/group'),
        ])
        .then(
          axios.spread((res1, res2) => {
            const dataMapping = res1.data as unknown as GroupInterface[];
            const data = res2.data as unknown as ResultType<GroupInterface>;
            setGroup(data.content);
            if (dataMapping.length > 0) setUserGroup(`${dataMapping[0].id}`);
          }),
        );
    }
  };

  const handleChangeUserGroup = (event: SelectChangeEvent) => {
    setUserGroup(event.target.value);
    if (event.target.value !== undefined || event.target.value !== '') {
      setGroupError(false);
    }
  };

  const onSave = () => {
    if (userGroup) {
      const groupIds = [userGroup];
      if (u && data) {
        if (data.status === 'EMAIL_VERIFIED') {
          axios
            .all([
              customAxios.put(`/mdx/V1.0/setting/user/${data.id}`, {
                status: 'SUBS_APPROVED',
              }),
              customAxios.post(`/mdx/V1.0/setting/user/${data.id}/group`, {
                groupIds,
              }),
            ])
            .then(
              axios.spread((res1, res2) => {
                getUser(page);
                setOpen(false);
              }),
            );
        } else {
          customAxios
            .post(`/mdx/V1.0/setting/user/${data.id}/group`, {
              groupIds,
            })
            .then(() => {
              getUser(page);
              setOpen(false);
            });
        }
      }
    } else {
      setGroupError(true);
    }
  };

  const onReject = () => {
    setRejectionReasonsPopup(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Wrap>
        <Stack spacing={3}>
          <Stack>
            <Typography className="input-title">ID</Typography>
            <Typography className="value-text">{userInfo?.username}</Typography>
          </Stack>
          <Stack>
            <Typography className="input-title">{t`Name`}</Typography>
            <Typography className="value-text">{userInfo?.name}</Typography>
          </Stack>
          {!userInfo?.username.includes('@seegene.com') && (
            <Stack>
              <Typography className="input-title">{t`E-mail`}</Typography>
              <Typography className="value-text">
                {userInfo?.subEmail}
              </Typography>
            </Stack>
          )}
          <Stack>
            <FormControl error={groupError}>
              <InputLabel id="userGroup-label">{t`Group`}</InputLabel>
              <Select
                labelId="userGroup-label"
                id="userGroup"
                IconComponent={props => (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    {...props}
                  >
                    <path
                      d="M7.41 9.13354L12 13.7135L16.59 9.13354L18 10.5435L12 16.5435L6 10.5435L7.41 9.13354Z"
                      fill="#878585"
                    />
                  </svg>
                )}
                value={userGroup}
                label={t`Group`}
                onChange={handleChangeUserGroup}
              >
                {group.map(item => {
                  return (
                    <MenuItem key={`${item.id}`} value={`${item.id}`}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {groupError && (
                <FormHelperText>Please select a user group</FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Box className="btnGroup" sx={{ '& button': { m: 1 }, marginTop: 3 }}>
            {u &&
              data &&
              (data.status === 'SUBS_APPROVED' ||
                data.status === 'EMAIL_VERIFIED') && (
                <Button variant="contained" size="large" onClick={onSave}>
                  {data && data.status === 'EMAIL_VERIFIED'
                    ? 'Approve'
                    : 'Edit'}
                </Button>
              )}
            {u && data && data.status === 'EMAIL_VERIFIED' && (
              <Button
                variant="contained"
                size="large"
                onClick={onReject}
                sx={{
                  backgroundColor: '#e01919 !important',
                  '&:hover': {
                    backgroundColor: '#fa2323 !important',
                  },
                }}
              >{t`Reject`}</Button>
            )}

            <Button variant="outlined" size="large" onClick={onCancel}>
              {t`Cancel`}
            </Button>
          </Box>
        </Stack>
      </Wrap>
      <ModalPopup
        open={rejectionReasonsPopup}
        title={t`Rejection reasons`}
        popupWidth={544}
      >
        <UserRejectForm
          id={data?.id || -1}
          page={page}
          setOpen={setRejectionReasonsPopup}
          setCompletion={setRejectionCompletion}
          getUser={getUser}
        />
      </ModalPopup>
    </>
  );
};

export default UserSetupGroup;

const Wrap = styled.div`
  .value-text {
    height: 56px;
    line-height: 56px;
    margin-bottom: -8px;
    padding: 0px 16px;
    font-weight: 700;
  }
  .MuiOutlinedInput-root.Mui-focused > fieldset {
    border-color: #b3b2b2 !important;
  }
`;
