import { useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppDispatch } from '../../app/hooks';
import { showMessage } from '../../features/messagebox/MessageboxSlice';
import { AxiosError, customAxios } from '../../lib/customAxios';

interface Props {
  contents: string;
  setContents: (contents: string) => void;
  imageUploadUrl: string;
  styles: React.CSSProperties;
}

const Editor = ({ contents, setContents, imageUploadUrl, styles }: Props) => {
  const dispatch = useAppDispatch();

  const QuillRef = useRef<ReactQuill>();

  const imageHandler = () => {
    const input = document.createElement('input');
    const formData = new FormData();

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files;
      if (file !== null) {
        formData.append('addFiles', file[0]);
        try {
          const res = await customAxios.post(imageUploadUrl, formData);
          const { imageTempPaths } = res.data;

          const range = QuillRef.current?.getEditor().getSelection()?.index;
          if (range !== null && range !== undefined) {
            const quill = QuillRef.current?.getEditor();
            quill?.setSelection(range, 1);
            quill?.clipboard.dangerouslyPasteHTML(
              range,
              `<img src=${imageTempPaths} alt="image" />`,
            );
          }
        } catch (error) {
          const err = error as AxiosError;
          dispatch(showMessage(err.response?.data.message));
        }
      }
    };
  };

  // quill에서 사용할 모듈을 설정하는 코드 입니다.
  // 원하는 설정을 사용하면 되는데, 저는 아래와 같이 사용했습니다.
  // useMemo를 사용하지 않으면, 키를 입력할 때마다, imageHandler 때문에 focus가 계속 풀리게 됩니다.
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
            { align: [] },
          ],
          ['image', 'video'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  );

  return (
    <ReactQuill
      style={styles}
      ref={element => {
        if (element !== null) {
          QuillRef.current = element;
        }
      }}
      value={contents}
      onChange={setContents}
      modules={modules}
      theme="snow"
      placeholder="내용을 입력하세요."
    />
  );
};

export default Editor;
