import { ChangeEvent, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { customAxios } from '../../lib/customAxios';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Input from '../common/Input';
import Typography from '@mui/material/Typography';

interface Props {
  category?: CategoryInterface;
  setOpen: (isOpen: boolean) => void;
  selectedData?: ItemInterface;
  getItems: (cgId: number) => void;
}

const ItemRegisterForm = ({
  category,
  setOpen,
  selectedData,
  getItems,
}: Props) => {
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const dispatch = useAppDispatch();
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<boolean>(false);
  const [codeHelptext, setCodeHelptext] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [nameHelptext, setNameHelptext] = useState<string>('');

  useEffect(() => {
    if (selectedData) {
      setCode(selectedData.code);
      setName(selectedData.name);
    }
  }, [selectedData]);

  const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const validationCode = (value?: string): boolean => {
    const tempValue = value || code;
    if (tempValue === '') {
      setCodeError(true);
      setCodeHelptext(t`Please enter an item code.`);
      return false;
    }
    setCodeError(false);
    setCodeHelptext('');
    return true;
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validationName = (value?: string): boolean => {
    const tempValue = value || name;
    if (tempValue === '') {
      setNameError(true);
      setNameHelptext(t`Please enter an item name.`);
      return false;
    }
    setNameError(false);
    setNameHelptext('');
    return true;
  };

  const onSave = () => {
    const codev = validationCode();
    const namev = validationName();
    if (codev && namev) {
      if (c && selectedData === undefined && category) {
        customAxios
          .put(`/mdx/V1.0/setting/category/${category.id}`, {
            ...category,
            categoryItems: [{ code, name, mode: 'INSERT' }],
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: t`Added item.` }),
            );
            if (result.payload) {
              getItems(category.id);
              setOpen(false);
            }
          });
      } else if (u && selectedData && category) {
        customAxios
          .put(`/mdx/V1.0/setting/category/${category.id}`, {
            ...category,
            categoryItems: [{ ...selectedData, code, name, mode: 'UPDATE' }],
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: t`Modified item.` }),
            );
            if (result.payload) {
              getItems(category.id);
              setOpen(false);
            }
          });
      }
    }
  };

  const handleCancle = () => {
    setOpen(false);
  };

  const handleCodeFocus = () => {
    setCodeError(false);
    setCodeHelptext('');
  };
  const handleCodeBlur = () => {
    validationCode();
  };

  const handleNameFocus = () => {
    setNameError(false);
    setNameHelptext('');
  };
  const handleNameBlur = () => {
    validationName();
  };

  return (
    <Wrap>
      <div>
        <div className="row">
          <Typography className="title-span">{t`Code`}</Typography>
          <Input
            id="code"
            placeholder={t`Code`}
            variant="outlined"
            value={code}
            onChange={onChangeCode}
            onFocus={handleCodeFocus}
            onBlur={handleCodeBlur}
            error={codeError}
            helperText={codeHelptext}
            inputProps={{ maxLength: 45 }}
            autoFocus
            fullWidth
          />
        </div>
        <div className="row">
          <Typography className="title-span">{t`Item Name`}</Typography>
          <Input
            id="name"
            placeholder={t`Item Name`}
            variant="outlined"
            value={name}
            onChange={onChangeName}
            onFocus={handleNameFocus}
            onBlur={handleNameBlur}
            error={nameError}
            helperText={nameHelptext}
            inputProps={{ maxLength: 45 }}
            fullWidth
          />
        </div>
        <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
          {(c || u) && (
            <Button variant="contained" size="large" onClick={onSave}>
              {selectedData ? t`Modify` : t`Add`}
            </Button>
          )}
          <Button variant="outlined" size="large" onClick={handleCancle}>
            {t`Cancel`}
          </Button>
        </Box>
      </div>
    </Wrap>
  );
};

export default ItemRegisterForm;

const Wrap = styled.div`
  .btnGroup {
    margin-top: 24px;
  }
  .input-title {
    margin-top: 24px;
  }
  .row {
    margin-bottom: 24px;
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
`;
