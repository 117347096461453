import { useEffect, useState } from 'react';
import {
  generateMasterPw,
  generateMasterPwOdd,
  loadMasterPwGen,
} from '../../services/generate.service';

const SwPasswordScreen = () => {
  const [password, setPassword] = useState<string>('');
  const [passwordOdd, setPasswordOdd] = useState<string>('');
  useEffect(() => {
    loadWasm();
  }, []);

  const loadWasm = async () => {
    const result = await loadMasterPwGen();
    try {
      if (result) {
        setPassword(generateMasterPw().toString());
        setPasswordOdd(generateMasterPwOdd().toString());
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getToday = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month: string =
      (today.getMonth() + 1).toString().length === 1
        ? `0${today.getMonth() + 1}`
        : `${today.getMonth() + 1}`;
    const day: string =
      today.getDate().toString().length === 1
        ? `0${today.getDate()}`
        : `${today.getDate()}`;
    return `${month}-${day}-${year}`;
  };

  return (
    <div className="content" style={{ height: 'calc(100% - 60px)' }}>
      <div
        className="swproductMain"
        style={{ marginTop: '20px', height: '100%' }}
      >
        <div style={{ marginBottom: '20px' }}>For Seegene Hub</div>
        <div style={{ marginBottom: '20px' }}>
          Master Key ({getToday()}) : {password}
        </div>
      </div>
    </div>
  );
};

export default SwPasswordScreen;
