import { NodeModel } from '@minoru/react-dnd-treeview';
import styled from 'styled-components';

interface Props {
  node: NodeModel;
  depth: number;
}

const PlaceholderDiv = styled.div`
  background-color: #1967d2;
  height: 2px;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  top: 0;
`;

const Placeholder: React.FC<Props> = Props => {
  const left = Props.depth * 24;
  return <PlaceholderDiv style={{ left }} />;
};

export default Placeholder;
