/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, ChangeEvent, useEffect } from 'react';
import { t } from '@lingui/macro';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { customAxios } from '../../lib/customAxios';
import FileUpload from '../common/FileUpload';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Input from '../common/Input';
import Typography from '@mui/material/Typography';

interface Props {
  disturbDsn: string;
  setProductPopup: (open: boolean) => void;
  data?: SwProductInterface;
  getSwProduct: () => void;
}

const SwAppAddModify = ({
  disturbDsn,
  setProductPopup,
  data,
  getSwProduct,
}: Props) => {
  const dispatch = useAppDispatch();
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const [name, setName] = useState<string>('');
  const [nameHelptext, setNameHelptext] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [descr, setDescr] = useState<string>('');
  const [descrHelptext, setDescrHelptext] = useState<string>('');
  const [descrError, setDescrError] = useState<boolean>(false);
  const [marketStatus, setMarketStatus] = useState<emarketStatusType>();
  const [marketStatusHelptext, setMarketStatusHelptext] = useState<string>('');
  const [marketStatusError, setMarketStatusError] = useState<boolean>(false);
  const [isChageImage, setChageImage] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [fileName, setFileName] = useState<FileInterface[]>([]);
  const [marketStatusItems, setMarketStatusItems] = useState<ItemInterface[]>(
    [],
  );

  useEffect(() => {
    customAxios
      .get<ItemInterface[]>(`/mdx/V1.0/setting/category/cgCd/SW003/item`)
      .then(res => {
        setMarketStatusItems(res.data);
      });
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      if (imagePath === '') {
        const formData = new FormData();
        formData.append('addFiles', files[0]);
        customAxios.post('/mdx/V1.0/common/image/temp', formData).then(res => {
          setImagePath(res.data.imageTempPaths[0]);
          setChageImage(true);
          setFiles([]);
        });
      } else {
        dispatch(
          openAlert({ message: '이미지는 파일 하나만 설정 가능합니다.' }),
        );
        setFiles([]);
      }
    }
  }, [files]);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescr(data.descr);
      setMarketStatus(data.marketStatus);
      setImagePath(data.imagePath || '');
    }
  }, [data]);

  const onChangeProductName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    validationProductName(e.target.value);
  };

  const validationProductName = (value?: string): boolean => {
    const tempValue = value || name;
    if (tempValue === '') {
      setNameError(true);
      setNameHelptext(t`Please enter an app name.`);
      return false;
    }
    setNameError(false);
    setNameHelptext('');
    return true;
  };

  const onChangeProductDesc = (e: ChangeEvent<HTMLInputElement>) => {
    setDescr(e.target.value);
    validationProductDesc(e.target.value);
  };

  const validationProductDesc = (value?: string): boolean => {
    const tempValue = value || descr;
    if (tempValue === '') {
      setDescrError(true);
      setDescrHelptext(t`Please enter an app description.`);
      return false;
    }
    setDescrError(false);
    setDescrHelptext('');
    return true;
  };

  const onChangeMarketStatus = (event: SelectChangeEvent) => {
    setMarketStatus(event.target.value as emarketStatusType);
    validationMarketStatus(event.target.value);
  };

  const validationMarketStatus = (value?: string): boolean => {
    const tempValue = value || marketStatus;
    if (tempValue === undefined || tempValue === '') {
      setMarketStatusError(true);
      setMarketStatusHelptext(t`Please select an IVD/RUO.`);
      return false;
    }
    setMarketStatusError(false);
    setMarketStatusHelptext('');
    return true;
  };

  const saveData = (type: string) => {
    const param = isChageImage
      ? {
          name,
          descr,
          marketStatus,
          disturbDsn,
          imageTempPath: imagePath,
        }
      : {
          name,
          descr,
          marketStatus,
          disturbDsn,
        };
    if (type === 'post') {
      customAxios.post('/mdx/V1.0/swm/sw', param).then(async () => {
        const result = await dispatch(
          openAlert({
            message: t`Added app.`,
            type: 'success',
            title: 'Confirm',
          }),
        );
        if (result.payload) {
          setProductPopup(false);
          getSwProduct();
        }
      });
    } else if (data && type === 'put') {
      customAxios.put(`/mdx/V1.0/swm/sw/${data.id}`, param).then(async () => {
        const result = await dispatch(
          openAlert({
            message: t`Modified app.`,
            type: 'success',
            title: 'Confirm',
          }),
        );
        if (result.payload) {
          setProductPopup(false);
          getSwProduct();
        }
      });
    }
  };

  const handleDeleteImg = () => {
    setImagePath('');
  };

  const handleSave = () => {
    const nameV = validationProductName();
    const statusV = validationMarketStatus();
    const descV = validationProductDesc();
    if (nameV && statusV && descV) {
      if (c && !data) {
        saveData('post');
      } else if (u && data) {
        saveData('put');
      }
    }
  };

  const handleCancel = () => {
    setProductPopup(false);
  };

  const handleProductNameFocus = () => {
    setNameError(false);
    setNameHelptext('');
  };

  const handleProductNameBlur = () => {
    validationProductName();
  };

  const handleProductDescFocus = () => {
    setDescrError(false);
    setDescrHelptext('');
  };

  const handleProductDescBlur = () => {
    validationProductDesc();
  };

  return (
    <Wrap>
      <div className="scroll-wrap">
        <div>
          <Typography className="title-span">{t`App Name`}</Typography>
          <Input
            id="name"
            variant="outlined"
            placeholder={t`App Name`}
            value={name}
            onChange={onChangeProductName}
            onFocus={handleProductNameFocus}
            onBlur={handleProductNameBlur}
            error={nameError}
            helperText={nameHelptext}
            inputProps={{ maxLength: 45 }}
            fullWidth
            sx={{
              marginBottom: '24px',
            }}
          />
        </div>
        <div>
          <Typography className="title-span">{t`App Description`}</Typography>
          <Input
            id="descr"
            type="text"
            placeholder={t`App Description`}
            variant="outlined"
            multiline
            rows={4.5}
            value={descr}
            error={descrError}
            helperText={descrHelptext}
            inputProps={{ maxLength: 400 }}
            sx={{
              marginBottom: '24px',
            }}
            fullWidth
            onChange={onChangeProductDesc}
            onFocus={handleProductDescFocus}
            onBlur={handleProductDescBlur}
          />
        </div>
        <div>
          <Typography className="title-span">{t`IVD/RUO`}</Typography>
          <FormControl
            fullWidth
            error={marketStatusError}
            sx={{ marginBottom: '16px' }}
          >
            <Select
              id="marketStatus"
              variant="outlined"
              value={`${marketStatus}`}
              onChange={onChangeMarketStatus}
              IconComponent={props => (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  {...props}
                >
                  <path
                    d="M7.41 9.13354L12 13.7135L16.59 9.13354L18 10.5435L12 16.5435L6 10.5435L7.41 9.13354Z"
                    fill="#333333"
                  />
                </svg>
              )}
            >
              {marketStatusItems.map(item => (
                <MenuItem key={item.code} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{marketStatusHelptext}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <Typography className="label">Imgae</Typography>
          <FileUpload fileName={fileName} setFiles={setFiles} files={files} />
        </div>
        {imagePath && (
          <div className="file-preview-box">
            <Typography className="title">{t`Preview`}</Typography>
            <div className="list-wrap">
              <div className="list">
                <Checkbox defaultChecked />
                <img
                  src={imagePath}
                  alt={name}
                  style={{ margin: '0px 45px' }}
                />
              </div>
              <IconButton aria-label="delete" onClick={handleDeleteImg}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10"
                    cy="10"
                    r="9.5"
                    fill="white"
                    stroke="#616161"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2105 6.7736C12.915 6.47749 12.4718 6.47749 12.1763 6.7736L10.0095 8.9451L7.84268 6.7736C7.54721 6.47749 7.104 6.47749 6.80852 6.7736C6.51305 7.06971 6.51305 7.51388 6.80852 7.81L8.97534 9.9815L6.80852 12.153C6.51305 12.4491 6.51305 12.8933 6.80852 13.1894C6.95626 13.3374 7.15324 13.3868 7.35022 13.3868C7.54721 13.3868 7.74419 13.3374 7.89193 13.1894L10.0587 11.0179L12.2256 13.1894C12.3733 13.3374 12.5703 13.3868 12.7673 13.3868C12.9643 13.3868 13.1612 13.3374 13.309 13.1894C13.6044 12.8933 13.6044 12.4491 13.309 12.153L11.1422 9.9815L13.309 7.81C13.506 7.51388 13.506 7.06971 13.2105 6.7736Z"
                    fill="#616161"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
        )}
      </div>

      <Box className="btnGroup btn-footer">
        {(c || u) && (
          <Button variant="contained" size="large" onClick={handleSave}>
            {data ? t`Modify` : t`Add`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={handleCancel}>
          {t`Cancel`}
        </Button>
      </Box>
    </Wrap>
  );
};

export default SwAppAddModify;

const Wrap = styled.div`
  width: 100%;
  .scroll-wrap {
    overflow: auto;
    height: 520px;
    margin-bottom: 90px;
    margin-right: -7px;
    padding-right: 7px;
    padding-top: 10px;
    position: relative;
  }
  .btnGroup.btn-footer {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    background: #fff;
    border-top: #eee;
    padding-right: 24px;
    padding-bottom: 24px;
    margin-top: 24px;
    button {
      margin: 4px;
    }
  }
  .label {
    font-size: 14px;
    margin: 0px 0px 4px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 21px; */
    color: #4d4c4c;
  }

  .file-preview-box {
    border: 1px solid #9f9f9f;
    border-radius: 4px;
    margin-top: 32px;
    .title {
      height: 45px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      background: #000000;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .list-wrap {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .list {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      align-items: center;
    }
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .MuiSelect-icon {
    top: calc(50% - 0.7em);
  }
  .MuiOutlinedInput-root.Mui-focused > fieldset {
    border-color: #b3b2b2 !important;
  }
`;
