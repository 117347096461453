const MoreVert = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.993 9.80488C13.1834 9.80488 14.1358 10.7805 14.1358 12C14.1358 13.2195 13.1834 14.1951 11.993 14.1951C10.8025 14.1951 9.8501 13.2195 9.8501 12C9.8501 10.7805 10.8025 9.80488 11.993 9.80488ZM9.8501 4.19512C9.8501 5.41463 10.8025 6.39024 11.993 6.39024C13.1834 6.39024 14.1358 5.41463 14.1358 4.19512C14.1358 2.97561 13.1834 2 11.993 2C10.8025 2 9.8501 2.97561 9.8501 4.19512ZM9.8501 19.8049C9.8501 21.0244 10.8025 22 11.993 22C13.1834 22 14.1358 21.0244 14.1358 19.8049C14.1358 18.5854 13.1834 17.6098 11.993 17.6098C10.8025 17.6098 9.8501 18.5854 9.8501 19.8049Z"
        fill="#616161"
      />
    </svg>
  );
};

export default MoreVert;
