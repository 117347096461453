import { i18n } from '@lingui/core';

export const getDateTime = (dateStr: string | number): string => {
  const date = new Date(dateStr);
  return `${formatDateTime(
    new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000),
  )}`;
};
export const getDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  return `${formatDate(
    new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000),
  )}`;
};

function formatDateTime(date: Date): string {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const monthStr = month >= 10 ? `${month}` : `0${month}`;
  const dayStr = day >= 10 ? `${day}` : `0${day}`;
  const hourStr = hour >= 10 ? `${hour}` : `0${hour}`;
  const minuteStr = minute >= 10 ? `${minute}` : `0${minute}`;
  const secondStr = second >= 10 ? `${second}` : `0${second}`;

  return `${date.getFullYear()}-${monthStr}-${dayStr} ${hourStr}:${minuteStr}:${secondStr}`;
}

function formatDate(date: Date): string {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const monthStr = month >= 10 ? `${month}` : `0${month}`;
  const dayStr = day >= 10 ? `${day}` : `0${day}`;

  return i18n.locale === 'ko'
    ? `${date.getFullYear()}-${monthStr}-${dayStr}`
    : `${monthStr}-${dayStr}-${date.getFullYear()}`;
}

export const formatVersion = (version: string): string => {
  if (version.length === 6) {
    version = `${version.substring(0, 1)}.${version.substring(
      1,
      3,
    )}.${version.substring(3, 6)}`;
  } else if (version.length === 10) {
    version = `${version.substring(0, 1)}.${version.substring(
      1,
      3,
    )}.${version.substring(3, 6)}.${version.substring(4, 10)}`;
  }
  return version;
};

export const validationPassword = (password: string): boolean => {
  const regPassword =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-_()~!@#$%^&*+]).{8,20}$/; // 특수기호, 대문자, 소문자, 숫자 포함, 8자 이상 20자 이하

  const regexNo = /(\w)\1\1\1/; // 동일한 숫자 연속사용 ex)1111,2222

  const regexp = /[0-9]{4}/g; //  연속된 숫자 ex)1234, 2345

  return (
    regPassword.test(password) &&
    !regexNo.test(password) &&
    !regexp.test(password)
  );
};

export const validationEmail = (email: string): boolean => {
  const regEmail =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  return regEmail.test(email);
};

export const getCookie = (key: string): string => {
  let result = '';

  const cookieArr = document.cookie.split(';');

  cookieArr.map(value => {
    if (value.indexOf(`${key}=`) === 0) {
      result = value.slice(`${key}=`.length, value.length);
    }
    return value;
  });

  return result;
};

export const deleteCookie = (key: string) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1999 00:00:10 GMT;`;
};

export const movePageLocalStorag = (isRemove: boolean): string => {
  const url = sessionStorage.getItem('page');

  if (isRemove) {
    sessionStorage.removeItem('page');
  }
  let moveUrl = '';
  if (!(url !== null && url !== '' && url)) {
    moveUrl = '/main';
  } else {
    moveUrl = url;
  }
  return moveUrl;
};
// export const findMenuByUrl = (
//   menuList: MenuInterface[],
//   url: string,
// ): MenuInterface => {
//   const menuList.
// };
