/* eslint-disable camelcase */
import { useState, useEffect, MouseEvent } from 'react';
import Button from '@mui/material/Button';
import ModalPopup from '../../components/common/ModalPopup';
import RoleRegisterForm from '../../components/admin/RoleRegisterForm';
import { AxiosError, customAxios } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import IconAdd from '../../assets/images/icon_add.svg';
import styled from 'styled-components';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import { t } from '@lingui/macro';
import ListItemIcon from '@mui/material/ListItemIcon';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import ArrowIconRight from '../../assets/images/arrow-right.svg';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

interface MenuPermissionInterface extends MenuDataInterface {
  permissionStr: string;
  permission: number;
}

const RoleScreen = () => {
  const CREATE = 0;
  const READ = 1;
  const UPDATE = 2;
  const DELETE = 3;

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { c, r, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const [role, setRole] = useState<RoleInterface[]>([]);
  const [rolePopup, setRolePopup] = useState<boolean>(false);
  const [popupTitle, setPopupTitle] = useState<string>('');
  const [selectedData, setSelectedData] = useState<RoleInterface | undefined>(
    undefined,
  );
  const [selectedRole, setSelectedRole] = useState<number>();
  const [mappingMenu, setMappingMenu] = useState<MenuPermissionInterface[]>([]);
  const [menu, setMenu] = useState<MenuPermissionInterface[]>([]);
  const [isOpenMenu, setOpenMenu] = useState<number[]>([]);
  const [roleCheck, setRoleCheck] = useState<number[]>([]);
  const [anchorElRole, setAnchorElRole] = useState<{
    [key: string]: HTMLElement;
  } | null>(null);
  const [parent1lvl, setParent1lvl] = useState<number[]>([]);

  useEffect(() => {
    getRole();
    getMenu();
  }, [r, location.key]);

  const getRole = () => {
    if (r) {
      customAxios
        .get<ResultType<RoleInterface>>(`/mdx/V1.0/setting/role`)
        .then(response => {
          setRole(response.data.content);
        });
    }
  };

  const getMenu = () => {
    if (r) {
      customAxios
        .get<ResultType<MenuPermissionInterface>>(
          '/mdx/V1.0/setting/menu?sort=menuOrder',
        )
        .then(response => {
          const allMenu: MenuPermissionInterface[] = response.data.content;
          setMenu(
            allMenu.map(item =>
              allMenu.findIndex(node => item.id === node.parentId) === -1
                ? { ...item, permissionStr: '1111', permission: 15 }
                : item,
            ),
          );
          setParent1lvl(
            allMenu.filter(item => item.parentId === 1).map(item => item.id),
          );
          setOpenMenu(
            allMenu.filter(item => item.parentId === 1).map(item => item.id),
          );
        });
    }
  };

  const onAdd = () => {
    setSelectedData(undefined);
    setPopupTitle(t`Add Role`);
    setRolePopup(true);
  };

  const handleMenuOpen = (id: number) => {
    if (isOpenMenu.includes(id)) {
      setOpenMenu(isOpenMenu.filter(menuId => id !== menuId));
    } else {
      setOpenMenu(isOpenMenu.concat([id]));
    }
  };

  const getPermission = (
    permission: string | undefined,
    type: number,
  ): boolean => {
    if (permission !== undefined)
      return permission.slice(type, type + 1) === '1';
    return false;
  };

  // const isCreateChecked = (id: number): boolean => {
  //   return getPermission(
  //     mappingMenu.find(item => item.id === id)?.permissionStr,
  //     CREATE,
  //   );
  // };

  // const isReadChecked = (id: number): boolean => {
  //   return getPermission(
  //     mappingMenu.find(item => item.id === id)?.permissionStr,
  //     READ,
  //   );
  // };

  // const isUpdateChecked = (id: number): boolean => {
  //   return getPermission(
  //     mappingMenu.find(item => item.id === id)?.permissionStr,
  //     UPDATE,
  //   );
  // };

  // const isDeleteChecked = (id: number): boolean => {
  //   return getPermission(
  //     mappingMenu.find(item => item.id === id)?.permissionStr,
  //     DELETE,
  //   );
  // };

  // CRUD, R 만 선택가능하도록 수정
  const setPermission = (
    permissionStr: string,
    type: number,
    isChecked: boolean,
  ): string => {
    const permissionArr: string[] = permissionStr.split('');
    if (isChecked && (type === CREATE || type === UPDATE || type === DELETE)) {
      permissionArr[CREATE] = '1';
      permissionArr[UPDATE] = '1';
      permissionArr[DELETE] = '1';
    } else if (isChecked && type === READ) {
      permissionArr[type] = '1';
    } else if (
      !isChecked &&
      (type === CREATE || type === UPDATE || type === DELETE)
    ) {
      permissionArr[CREATE] = '0';
      permissionArr[UPDATE] = '0';
      permissionArr[DELETE] = '0';
    } else if (!isChecked && type === READ) {
      permissionArr[type] = '0';
    }

    if (permissionArr.join('') === '0000') {
      return permissionStr;
    }

    return permissionArr.join('');
  };

  // const onCreateCheckChange = (id: number) => {
  //   if (selectedRole) {
  //     const tempMappingMenu: MenuPermissionInterface[] = mappingMenu.map(item =>
  //       item.id === id
  //         ? {
  //             ...item,
  //             permissionStr: setPermission(
  //               item.permissionStr,
  //               CREATE,
  //               !getPermission(item.permissionStr, CREATE),
  //             ),
  //           }
  //         : item,
  //     );
  //     saveMappingMenu(tempMappingMenu);
  //   }
  // };

  // const onReadCheckChange = (id: number) => {
  //   if (selectedRole) {
  //     const tempMappingMenu: MenuPermissionInterface[] = mappingMenu.map(item =>
  //       item.id === id
  //         ? {
  //             ...item,
  //             permissionStr: setPermission(
  //               item.permissionStr,
  //               READ,
  //               !getPermission(item.permissionStr, READ),
  //             ),
  //           }
  //         : item,
  //     );
  //     saveMappingMenu(tempMappingMenu);
  //   }
  // };

  // const onUpdateCheckChange = (id: number) => {
  //   if (selectedRole) {
  //     const tempMappingMenu: MenuPermissionInterface[] = mappingMenu.map(item =>
  //       item.id === id
  //         ? {
  //             ...item,
  //             permissionStr: setPermission(
  //               item.permissionStr,
  //               UPDATE,
  //               !getPermission(item.permissionStr, UPDATE),
  //             ),
  //           }
  //         : item,
  //     );
  //     saveMappingMenu(tempMappingMenu);
  //   }
  // };

  // const onDeleteCheckChange = (id: number) => {
  //   if (selectedRole) {
  //     const tempMappingMenu: MenuPermissionInterface[] = mappingMenu.map(item =>
  //       item.id === id
  //         ? {
  //             ...item,
  //             permissionStr: setPermission(
  //               item.permissionStr,
  //               DELETE,
  //               !getPermission(item.permissionStr, DELETE),
  //             ),
  //           }
  //         : item,
  //     );
  //     saveMappingMenu(tempMappingMenu);
  //   }
  // };

  const getParents = (
    id: number | null,
    items: MenuPermissionInterface[],
    includeItem: boolean,
  ): MenuPermissionInterface[] => {
    const item = menu.find(item => item.id === id);

    if (item) {
      if (item.parentId !== null || item.parentId !== undefined) {
        items = getParents(item.parentId, items, true);
      }
      if (includeItem && items.findIndex(temp => temp.id === item.id) === -1)
        items = items.concat([item]);
      return items;
    }

    return items;
  };

  const getParentChecked = (
    id: number | null,
    menuArr: MenuPermissionInterface[],
  ): MenuPermissionInterface[] => {
    const item = mappingMenu.find(item => item.id === id);
    if (item) {
      menuArr.push(item);
      // 해당 노드 상위 노드의 하위 노드 확인
      if (
        mappingMenu.filter(
          node => node.id !== item.id && node.parentId === item.parentId,
        ).length === 0
      ) {
        menuArr = getParentChecked(item.parentId, menuArr);
      }
    }
    return menuArr;
  };

  // const handleMenuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const id: number = parseInt(event.target.id, 10);
  //   let tempMappingMenu: MenuPermissionInterface[] = [];
  //   if (event.target.checked) {
  //     tempMappingMenu = mappingMenu.concat(
  //       getParents(id, [], true).filter(
  //         item => mappingMenu.findIndex(target => target.id === item.id) === -1,
  //       ),
  //     );
  //   } else {
  //     const temp: MenuPermissionInterface[] = getParentChecked(id, []);
  //     tempMappingMenu = mappingMenu.filter(
  //       item => temp.findIndex(target => target.id === item.id) === -1,
  //     );
  //   }
  //   saveMappingMenu(tempMappingMenu);
  // };

  const saveMappingMenu = (savedMenu: MenuPermissionInterface[]) => {
    if (selectedRole) {
      setMappingMenu(savedMenu);
      customAxios.post(`/mdx/V1.0/setting/role/${selectedRole}/menu`, {
        roleMenus: savedMenu.map(item => {
          return item.permissionStr !== undefined
            ? { ...item, permission: parseInt(item.permissionStr, 2) }
            : item;
        }),
      });
    }
  };

  const createListItem = (target: MenuPermissionInterface, lvl: number) => {
    const subMenuArr = menu.filter(subMenu => subMenu.parentId === target.id);
    if (subMenuArr.length > 0) {
      return (
        <div key={`${target.id}-div`}>
          <ListItem key={target.id} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={() => {
                handleMenuOpen(target.id);
              }}
              dense
            >
              {isOpenMenu.includes(target.id) ? <ExpandLess /> : <ExpandMore />}
              <ListItemText id={`${target.id}`} primary={target.name} />
            </ListItemButton>
          </ListItem>
          <Collapse
            in={isOpenMenu.includes(target.id)}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" key={`${target.id}-list`}>
              {subMenuArr.map(menuItem => createListItem(menuItem, lvl + 1))}
            </List>
          </Collapse>
        </div>
      );
    }
    if (target.parentId === 1) {
      return (
        <div key={`${target.id}-div`}>
          <ListItem key={target.id} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={() => {
                handleMenuOpen(target.id);
              }}
              dense
            >
              {isOpenMenu.includes(target.id) ? <ExpandLess /> : <ExpandMore />}
              <ListItemText id={`${target.id}-parent`} primary={target.name} />
            </ListItemButton>
          </ListItem>
          <Collapse
            in={isOpenMenu.includes(target.id)}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" key={`${target.id}-list`}>
              {getListItem(target, lvl)}
            </List>
          </Collapse>
        </div>
      );
    }
    return getListItem(target, lvl - 1);
  };

  const getListItem = (target: MenuPermissionInterface, lvl: number) => {
    return (
      <ListItem
        key={target.id}
        disablePadding
        sx={{
          pl: `${lvl * 70}px`,
          pr: '100px',
          mb: '2px',
          backgroundColor: '#fff',
        }}
      >
        <ListItemText id={`${target.id}`} primary={target.name} />
        <div>
          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                checked={isReadOnly(target.id)}
                onChange={e => {
                  handleReadOnly(e, target.id);
                }}
                name={target.name}
                checkedIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                      fill="#4D4C4C"
                    />
                  </svg>
                }
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                      fill="#C8C8C8"
                    />
                  </svg>
                }
              />
            }
            label="읽기전용"
          />
          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                checked={isAllPermission(target.id)}
                onChange={e => {
                  handleAllPermission(e, target.id);
                }}
                name={target.name}
                checkedIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                      fill="#4D4C4C"
                    />
                  </svg>
                }
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                      fill="#C8C8C8"
                    />
                  </svg>
                }
              />
            }
            label="생성/업데이트/삭제"
          />
        </div>
        {/* <Button
          variant="text"
          sx={{
            color: isCreateChecked(target.id) ? '#0076CE' : '#C8C8C8',
            '&:hover': {
              color: '#2290E2',
            },
          }}
          onClick={() => {
            onCreateCheckChange(target.id);
          }}
        >
          Create
        </Button>
        <Button
          variant="text"
          sx={{
            color: isReadChecked(target.id) ? '#0076CE' : '#C8C8C8',
            '&:hover': {
              color: '#2290E2',
            },
          }}
          onClick={() => {
            onReadCheckChange(target.id);
          }}
        >
          Read
        </Button>
        <Button
          variant="text"
          sx={{
            color: isUpdateChecked(target.id) ? '#0076CE' : '#C8C8C8',
            '&:hover': {
              color: '#2290E2',
            },
          }}
          onClick={() => {
            onUpdateCheckChange(target.id);
          }}
        >
          Update
        </Button>
        <Button
          variant="text"
          sx={{
            color: isDeleteChecked(target.id) ? '#0076CE' : '#C8C8C8',
            '&:hover': {
              color: '#2290E2',
            },
          }}
          onClick={() => {
            onDeleteCheckChange(target.id);
          }}
        >
          Delete
        </Button> */}
      </ListItem>
    );
  };

  const isReadOnly = (id: number) => {
    return (
      getPermission(
        mappingMenu.find(item => item.id === id)?.permissionStr,
        READ,
      ) &&
      !getPermission(
        mappingMenu.find(item => item.id === id)?.permissionStr,
        CREATE,
      )
    );
  };

  const isAllPermission = (id: number) => {
    return getPermission(
      mappingMenu.find(item => item.id === id)?.permissionStr,
      CREATE,
    );
  };

  const handleReadOnly = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    if (selectedRole) {
      let tempMappingMenu: MenuPermissionInterface[] = [];
      if (event.target.checked) {
        let tempMappingMenuAdd: MenuPermissionInterface[] = mappingMenu;
        if (!mappingMenu.find(item => item.id === id)) {
          tempMappingMenuAdd = mappingMenu.concat(
            getParents(id, [], true).filter(
              item =>
                mappingMenu.findIndex(target => target.id === item.id) === -1,
            ),
          );
        }
        tempMappingMenu = tempMappingMenuAdd.map(item =>
          item.id === id
            ? {
                ...item,
                permissionStr: setPermission(
                  item.permissionStr,
                  CREATE,
                  !event.target.checked,
                ),
              }
            : item,
        );
      } else {
        const temp: MenuPermissionInterface[] = getParentChecked(id, []);
        tempMappingMenu = mappingMenu.filter(
          item => temp.findIndex(target => target.id === item.id) === -1,
        );
      }
      saveMappingMenu(tempMappingMenu);
    }
  };

  const handleAllPermission = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    if (selectedRole) {
      let tempMappingMenu: MenuPermissionInterface[] = [];
      if (event.target.checked) {
        let tempMappingMenuAdd: MenuPermissionInterface[] = mappingMenu;
        if (mappingMenu.find(item => item.id === id)) {
          tempMappingMenu = tempMappingMenuAdd.map(item =>
            item.id === id
              ? {
                  ...item,
                  permissionStr: setPermission(
                    item.permissionStr,
                    CREATE,
                    event.target.checked,
                  ),
                }
              : item,
          );
        } else {
          tempMappingMenuAdd = mappingMenu.concat(
            getParents(id, [], true).filter(
              item =>
                mappingMenu.findIndex(target => target.id === item.id) === -1,
            ),
          );
          tempMappingMenu = tempMappingMenuAdd.map(item =>
            item.id === id
              ? {
                  ...item,
                  permissionStr: setPermission(
                    item.permissionStr,
                    CREATE,
                    event.target.checked,
                  ),
                }
              : item,
          );
          tempMappingMenu = tempMappingMenuAdd.map(item =>
            item.id === id
              ? {
                  ...item,
                  permissionStr: setPermission(
                    item.permissionStr,
                    READ,
                    event.target.checked,
                  ),
                }
              : item,
          );
        }
      } else {
        const temp: MenuPermissionInterface[] = getParentChecked(id, []);
        tempMappingMenu = mappingMenu.filter(
          item => temp.findIndex(target => target.id === item.id) === -1,
        );
      }
      saveMappingMenu(tempMappingMenu);
    }
  };

  const handleRoleClick = (
    event: MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setAnchorElRole({ [id]: event.currentTarget });
  };

  const getOpenRoleMenu = (id: string): boolean => {
    return !!(anchorElRole !== null && anchorElRole[id]);
  };

  const getAnchorElRole = (id: string) => {
    return anchorElRole !== null && anchorElRole[id] ? anchorElRole[id] : null;
  };

  const handleRoleClose = () => {
    setAnchorElRole(null);
  };

  const handleRoleModify = (id: number) => {
    handleRoleClose();
    setSelectedData(role.find(item => item.id === id));
    setPopupTitle(t`Modify Role`);
    setRolePopup(true);
  };

  const handleRoleDelete = async (id: number) => {
    if (d) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the role?` }),
      );
      if (result.payload) {
        customAxios
          .delete(`/mdx/V1.0/setting/role/${id}`)
          .then(() => {
            getRole();
            dispatch(openAlert({ message: t`Deleted role.` }));
          })
          .catch((ex: AxiosError<ErrorType>) => {
            if (ex.response?.status === 405) {
              dispatch(
                openAlert({
                  message: `Group Mapping 또는 Menu Mapping 데이터 삭제 후 다시 시도하세요.`,
                }),
              );
            }
          });
      }
    }
  };

  const handleRolesDelete = async () => {
    if (d) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the role?` }),
      );
      // if (result.payload) {
      //   customAxios
      //     .delete(`/mdx/V1.0/setting/role/${id}`)
      //     .then(() => {
      //       getRole();
      //     })
      //     .catch((ex: AxiosError<ErrorType>) => {
      //       if (ex.response?.status === 405) {
      //         dispatch(
      //            openAlert({ message:
      //             `Group Mapping 또는 Menu Mapping 데이터 삭제 후 다시 시도하세요.`
      //           }),
      //         );
      //       }
      //     });
      // }
    }
  };

  const handleRoleSelected = (roleId: number) => {
    const currentIndex = roleCheck.indexOf(roleId);
    const newChecked = [...roleCheck];

    if (currentIndex === -1) {
      newChecked.push(roleId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setRoleCheck(newChecked);
    setSelectedRole(roleId);
    getMenuAndMappingInfo(roleId);
    setOpenMenu(parent1lvl);
  };

  const getMenuAndMappingInfo = (id: number) => {
    customAxios
      .get<MenuPermissionInterface[]>(`/mdx/V1.0/setting/role/${id}/menu`)
      .then(response => {
        setMappingMenu(
          response.data.map(item =>
            item.permission === null
              ? item
              : {
                  ...item,
                  permissionStr: item.permission.toString(2).padStart(4, '0'),
                },
          ),
        );
      });
  };

  return (
    <Wrap>
      <div className="admin">
        <Grid container spacing={1}>
          <Grid item xs={3.5}>
            <div className="titleGroup">
              <Typography className="title">{t`Role`}</Typography>
              <Box className="btnGroup" sx={{ '& button': { ml: 1 } }}>
                {c && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={onAdd}
                    startIcon={<img src={IconAdd} alt="" />}
                  >
                    {t`Add Role`}
                  </Button>
                )}
                {d && roleCheck.length > 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleRolesDelete}
                  >
                    {t`Delet Role`}
                  </Button>
                )}
              </Box>
            </div>
            <List className="inner-box left-box roleList" disablePadding>
              {role.map(item => {
                return (
                  <ListItem
                    key={item.id.toString()}
                    secondaryAction={
                      <IconButton
                        key={item.id.toString()}
                        edge="end"
                        aria-label="role"
                        id={`${item.id}`}
                        onClick={event => {
                          handleRoleClick(event, `${item.id}`);
                        }}
                        aria-controls={`basic-app-${item.id}`}
                        aria-haspopup="true"
                        aria-expanded={
                          getOpenRoleMenu(`${item.id}`) ? 'true' : undefined
                        }
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.99295 7.80488C11.1834 7.80488 12.1358 8.78049 12.1358 10C12.1358 11.2195 11.1834 12.1951 9.99295 12.1951C8.80248 12.1951 7.8501 11.2195 7.8501 10C7.8501 8.78049 8.80248 7.80488 9.99295 7.80488ZM7.8501 2.19512C7.8501 3.41463 8.80248 4.39024 9.99295 4.39024C11.1834 4.39024 12.1358 3.41463 12.1358 2.19512C12.1358 0.97561 11.1834 0 9.99295 0C8.80248 0 7.8501 0.97561 7.8501 2.19512ZM7.8501 17.8049C7.8501 19.0244 8.80248 20 9.99295 20C11.1834 20 12.1358 19.0244 12.1358 17.8049C12.1358 16.5854 11.1834 15.6098 9.99295 15.6098C8.80248 15.6098 7.8501 16.5854 7.8501 17.8049Z"
                            fill="#616161"
                          />
                        </svg>
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      key={item.id.toString()}
                      role={undefined}
                      selected={selectedRole === item.id}
                      dense
                      onClick={() => {
                        handleRoleSelected(item.id);
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          key={`${item.id}`}
                          checked={roleCheck.indexOf(item.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': `${item.id}-label` }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`${item.id}-label`}
                        primary={item.name}
                        key={`${item.id}`}
                      />
                    </ListItemButton>
                    <Menu
                      id={`basic-app-${item.id}`}
                      anchorEl={getAnchorElRole(`${item.id}`)}
                      open={getOpenRoleMenu(`${item.id}`)}
                      onClose={handleRoleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      className="more-menu"
                      PaperProps={{
                        style: { width: '154px' },
                      }}
                    >
                      {u && (
                        <MenuItem
                          divider
                          onClick={() => {
                            handleRoleModify(item.id);
                          }}
                        >
                          {t`Modify`}
                        </MenuItem>
                      )}
                      {d && (
                        <MenuItem
                          onClick={() => {
                            handleRoleDelete(item.id);
                          }}
                        >
                          {t`Delete`}
                        </MenuItem>
                      )}
                    </Menu>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={ArrowIconRight} alt="" />
          </Grid>
          <Grid item xs={6}>
            <div className="titleGroup">
              <Typography className="title">{t`Menu`}</Typography>
            </div>
            <List className="right-box" disablePadding>
              {menu
                .filter(item => item.parentId === 1)
                .map(item => createListItem(item, 1))}
            </List>
          </Grid>
        </Grid>
      </div>
      <ModalPopup open={rolePopup} title={popupTitle} popupWidth={544}>
        <RoleRegisterForm
          setOpen={setRolePopup}
          getRole={getRole}
          data={selectedData}
        />
      </ModalPopup>
    </Wrap>
  );
};

export default RoleScreen;

const Wrap = styled.div`
  .titleGroup {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .titleGroup .title {
    margin-top: 15px;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    /* margin-bottom: 21px; */
    color: #4d4c4c;
  }
  .inner-box {
    background-color: #fff;
    border-radius: 8px;
    padding: 18px 0 !important;
    min-height: 50%;
    border: 1px solid #a8a7a7;
  }
  .right-box {
    max-width: 749px;
    li.MuiListItem-root > .MuiListItemButton-root {
      background: #f6f6f6;
      border: 1px solid #c8c8c8;
      border-radius: 8px 8px 0 0;
      margin: 4px 0px;
      height: 40px;
      padding: 0px 10px;
      color: #4d4c4c;
    }
    li.MuiListItem-root > .MuiListItemButton-root > .MuiListItemText-root {
      padding: 0px 10px;
    }
    .MuiCollapse-root {
      /* background-color: #fff; */
      /* padding: 16px 16px 16px 48px; */
      margin-bottom: 4px;
      margin-top: -4px;
    }
    .checkbox .MuiTypography-root {
      color: #0076ce !important;
      font-size: 14px !important;
      line-height: 18px !important;
      font-weight: 700 !important;
    }
  }

  .MuiFormControlLabel-root {
    margin-left: 0px;
  }

  .MuiListItemButton-root {
    height: 44px;
    &:hover {
      background-color: #f6f6f6;
      margin-right: 21px;
    }
  }
  .Mui-selected {
    background-color: #f6f6f6;
    margin-right: 21px;
    &::before {
      width: 2px;
      height: 40px;
      display: block;
      background: #000;
      z-index: 9999;
    }
  }
  .roleMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .MuiListItemSecondaryAction-root {
    right: 30px;
  }
  .MuiIconButton-root {
    margin-right: -10px;
    padding: 5px;
  }
  .roleList .MuiCheckbox-root {
    margin-left: -5px;
  }
  .MuiCollapse-root {
    border: 1px solid #c8c8c8;
    border-top: 0;
  }
  .MuiCollapse-root li:last-child {
    margin-bottom: 0;
  }
`;
