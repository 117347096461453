import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { showMessage } from '../../features/messagebox/MessageboxSlice';
import Editor from '../common/Editor';
import FileUpload from '../common/FileUpload';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { customAxios } from '../../lib/customAxios';
import { t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const NoticeWrite = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const [files, setFiles] = useState<File[]>([]);
  const [fileName, setFileName] = useState<FileInterface[]>([]);
  const [pSubject, setPSubject] = useState<string>('');
  const [pContent, setPContent] = useState<string>('');
  const [deleteFileIds, setDeleteFileIds] = useState<number[]>([]);
  const [loadFile, setLoadFile] = useState<number>(0);

  useEffect(() => {
    if (noticeId) {
      customAxios
        .get<NoticeInterface>(`/mdx/V1.0/common/notice/${noticeId}`)
        .then(response => {
          setPSubject(response.data.subject);
          setPContent(response.data.content);
          setFileName(
            response.data.noticeFileList.map(fileInfo => {
              return {
                fileName: fileInfo.fileName,
                id: fileInfo.id,
                filePath: fileInfo.filePath,
              };
            }),
          );
        });
    }
  }, [noticeId]);

  const onSubjectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPSubject(event.target.value);
  };

  const onContentChange = (contents: string) => {
    setPContent(contents);
  };

  const validation = (): boolean => {
    if (pSubject === '') {
      dispatch(showMessage('제목을 입력하세요'));
      return false;
    }
    if (pContent === '') {
      dispatch(showMessage('내용을 입력하세요'));
      return false;
    }
    return true;
  };

  const getImagePaths = (content: string): string[] => {
    const path: string[] | undefined = content
      .match(/<img src=(.*?)>/g)
      ?.map(item => {
        return item.substring(
          '<img src="'.length,
          item.length - '" alt="image">'.length,
        );
      });
    return path !== undefined ? path : [];
  };

  const onSaveData = () => {
    if (validation()) {
      const formData = new FormData();
      const imageTempPaths: string[] = getImagePaths(pContent);
      formData.append(
        'noticeRequest',
        new Blob(
          [
            JSON.stringify({
              subject: pSubject,
              content: pContent,
              imageTempPaths,
              deleteFileIds,
            }),
          ],
          {
            type: 'application/json',
          },
        ),
      );
      files.map(file => formData.append('addFiles', file));

      if (noticeId) {
        customAxios
          .put(`/mdx/V1.0/common/notice/${noticeId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent =>
              setLoadFile(progressEvent.loaded),
          })
          .then(() => {
            navigate(`/main/notice/noticeList`);
          });
      } else {
        customAxios
          .post(`/mdx/V1.0/common/notice`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent =>
              setLoadFile(progressEvent.loaded),
          })
          .then(() => {
            navigate(`/main/notice/noticeList`);
          });
      }
    }
  };
  return (
    <Wrap>
      <div className="content">
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <Typography className="title">
            {t`Title`} <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            id="subject"
            variant="outlined"
            placeholder={t`Title`}
            value={pSubject}
            onChange={onSubjectChange}
            sx={{
              width: '480px',
            }}
          />
        </Stack>
        <div className="editor">
          <Editor
            contents={pContent}
            setContents={onContentChange}
            imageUploadUrl="/mdx/V1.0/common/image/temp"
            styles={{ height: '548px' }}
          />
        </div>
        <div>
          <FileUpload
            showFileList
            fileName={fileName}
            setFiles={setFiles}
            files={files}
            deleteFileIds={deleteFileIds}
            setDeleteFileIds={setDeleteFileIds}
            loadFileSize={loadFile}
          />
        </div>
        <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
          <Button variant="contained" size="large" onClick={onSaveData}>
            {t`Save`}
          </Button>
          <NavLink to="/main/notice/noticeList">
            <Button variant="outlined" size="large">
              {t`Cancel`}
            </Button>
          </NavLink>
        </Box>
      </div>
    </Wrap>
  );
};

export default NoticeWrite;

const Wrap = styled.div`
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-right: 10px;
  }
  .content {
    background: #fff;
    padding: 63px 78px;
    border-radius: 8px;
    border: 1px solid #a8a7a7;
  }
  .editor {
    padding: 0px 0px 50px 0px;
  }
  .btnGroup {
    display: flex;
    justify-content: flex-end;
  }
  a {
    text-decoration: none;
  }
`;
