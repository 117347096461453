import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { customAxios } from '../../lib/customAxios';
import { setUserinfo } from '../../features/userinfo/UserinfoSlice';
import Typography from '@mui/material/Typography';

interface Props {
  id: number;
  setPopup: (isPopup: boolean) => void;
}

const MoreTermsInfoForm = ({ id, setPopup }: Props) => {
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector(state => state.userinfo);
  const [termsList, setTermsList] = useState<TermsInterface[]>([]);

  useEffect(() => {
    customAxios
      .get<TermsInterface[]>(
        `mdx/V1.0/setting/agrTermsUse/targets?lang=${lang}&userId=${id}`,
      )
      .then(response => {
        setTermsList(response.data);
      });
  }, []);

  const close = () => {
    setPopup(false);
  };

  const handleTermsConfirm = () => {
    window.open(`/terms/${lang}/${termsList[0].verId}`, '_blank');
  };

  const handleConfirm = () => {
    customAxios
      .put(`/mdx/V1.0/setting/user/${id}`, {
        status: 'SUBS_APPROVED',
        agrTermsUseVerIdList: termsList.map(item => {
          return item.verId;
        }),
      })
      .then(() => {
        setPopup(false);
        customAxios
          .get<LoginInterface>(`/mdx/V1.0/setting/user/mine`)
          .then(res1 => {
            dispatch(setUserinfo(res1.data));
          });
      });
  };

  return (
    <Wrap>
      <div className="close">
        <IconButton onClick={close}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 1.91L13.09 0.5L7.5 6.09L1.91 0.5L0.5 1.91L6.09 7.5L0.5 13.09L1.91 14.5L7.5 8.91L13.09 14.5L14.5 13.09L8.91 7.5L14.5 1.91Z"
              fill="#2B2B2B"
            />
          </svg>
        </IconButton>
      </div>
      <div className="content">
        <div>
          <Typography component="h2">약관 변경 안내</Typography>
        </div>
        <div>
          <Typography component="h4">
            안녕하세요, <br />
            Seegne Cloud Manager 이용약관이 개정되어, 변경내용을 안내해
            드립니다.
          </Typography>
        </div>
        <div>
          <Button
            variant="text"
            className="textButton"
            onClick={handleTermsConfirm}
          >
            변경된 약관 확인하기
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            className="btn"
            fullWidth
            onClick={handleConfirm}
          >
            동의하기
          </Button>
        </div>
      </div>
    </Wrap>
  );
};

export default MoreTermsInfoForm;

const Wrap = styled.div`
  height: 629px;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
    margin-bottom: 15px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 15px 0px;
  }
  .content {
    margin: 0 32px;
  }
  .textButton {
    color: #0076ce;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    margin-bottom: 15px;
  }
  .close {
    display: flex;
    justify-content: end;
    margin-top: 53px;
    margin-bottom: 15px;
  }
`;
