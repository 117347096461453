import React from 'react';
import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';

interface Props {
  monitorProps: DragLayerMonitorProps<MenuDataInterface>;
}

const TreeCustomPreview: React.FC<Props> = props => {
  const { item } = props.monitorProps;
  return (
    <div>
      <div>{item.text}</div>
    </div>
  );
};

export default TreeCustomPreview;
