/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { NodeModel, useDragOver } from '@minoru/react-dnd-treeview';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAppSelector } from '../../app/hooks';
import styled from 'styled-components';
import ArrowIconDown from '../../assets/images/arrow-down.svg';
import ArrowIconUp from '../../assets/images/arrow-up.svg';
import { t } from '@lingui/macro';

interface Props {
  node: NodeModel<MenuDataInterface>;
  depth: number;
  isOpen: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onDelete: (id: NodeModel['id']) => void;
  onAdd: (id: NodeModel['id']) => void;
  onModify: (id: NodeModel['id']) => void;
}

const TreeCustomNode: React.FC<Props> = props => {
  const { c, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const [hover, setHover] = useState<boolean>(false);
  const { id, droppable, data } = props.node;
  const indent = props.depth * 10;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  return (
    <Wrap
      className={
        data?.isParent
          ? `treecustom node-${props.depth}-depth`
          : `treecustom node-${props.depth}-depth not`
      }
      style={{ paddingInlineStart: indent }}
      {...dragOverProps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="toggle-arrow">
        {data?.isParent ? (
          <div onClick={handleToggle}>
            {props.isOpen ? (
              <img src={ArrowIconDown} alt="" />
            ) : (
              <img src={ArrowIconUp} alt="" />
            )}
          </div>
        ) : (
          <div style={{ width: '24px' }} />
        )}
      </div>
      <div style={{ minWidth: '20%', padding: '0px 10px' }}>
        <Typography variant="body2">{`${props.node.text}`}</Typography>
      </div>
      <div className="tree-btngroup">
        {c &&
          (props.depth === 0 ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="20"
                    height="20"
                    rx="10"
                    fill="#2B2B2B"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 6C12.4142 6 12.75 6.33579 12.75 6.75V11.25H17.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75H12.75V17.25C12.75 17.6642 12.4142 18 12 18C11.5858 18 11.25 17.6642 11.25 17.25V12.75H6.75C6.33579 12.75 6 12.4142 6 12C6 11.5858 6.33579 11.25 6.75 11.25H11.25V6.75C11.25 6.33579 11.5858 6 12 6Z"
                    fill="white"
                  />
                </svg>
              }
              onClick={() => props.onAdd(id)}
            >
              {t`Add`}
            </Button>
          ) : (
            <Button
              variant="text"
              className="add"
              startIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                </svg>
              }
              onClick={() => props.onAdd(id)}
            >
              {t`Add Menu`}
            </Button>
          ))}
        {u &&
          (props.depth === 0 ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z" />
                </svg>
              }
              onClick={() => props.onModify(id)}
            >
              {t`Modify`}
            </Button>
          ) : (
            <Button
              variant="text"
              className="modify"
              startIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m14.06 9.02.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                </svg>
              }
              onClick={() => props.onModify(id)}
            >
              {t`Modify Menu`}
            </Button>
          ))}
        {!data?.isParent &&
          d &&
          (props.depth === 0 ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" />
                </svg>
              }
              onClick={() => props.onDelete(id)}
            >
              {t`Delete`}
            </Button>
          ) : (
            <Button
              variant="text"
              className="delete"
              startIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                    fill="#E01919"
                  />
                </svg>
              }
              onClick={() => props.onDelete(id)}
            >
              {t`Delete Menu`}
            </Button>
          ))}
      </div>
    </Wrap>
  );
};

export default TreeCustomNode;

const Wrap = styled.div`
  .toggle-arrow {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .btn-circle {
    display: block;
    width: 20px;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-width: 20px;
    border-radius: 50% !important;
    padding: 0px;
    background: #878585 !important;
    font-weight: 400 !important;
    line-height: 21px;
  }
`;
