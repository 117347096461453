import { Store } from '@reduxjs/toolkit';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { finishLoading, startLoading } from '../features/loading/LoadingSlice';
import { showMessage } from '../features/messagebox/MessageboxSlice';
import {
  refreshtokenSuccess,
  userinfoInit,
} from '../features/userinfo/UserinfoSlice';

let store: Store;
let refreshtokenNum = 0;
let isShowMessage = true;

export const injectStore = (_store: Store) => {
  store = _store;
};

export const setShowMessage = (_isShowMessage: boolean) => {
  isShowMessage = _isShowMessage;
};

const customAxios: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
});

customAxios.interceptors.request.use(
  config => {
    config.headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${store.getState().userinfo.token}`,
    };
    store.dispatch(startLoading());
    return config;
  },
  err => {
    store.dispatch(finishLoading());
    return Promise.reject(err);
  },
);

customAxios.interceptors.response.use(
  config => {
    store.dispatch(finishLoading());
    return config;
  },
  async function (err) {
    store.dispatch(finishLoading());
    const {
      config,
      response: { status },
    } = err;
    if (status === 401) {
      setShowMessage(false);
      if (err.response.data.error === 'Unauthorized' && refreshtokenNum < 1) {
        refreshtokenNum += 1;
        const originalRequest = config;
        try {
          const { data } = await customAxios.post<RefreshtokenType>(
            `/mdx/V1.0/auth/refreshtoken`,
          );
          const { accessToken } = data;
          store.dispatch(refreshtokenSuccess(accessToken));
          originalRequest.headers = { Authorization: `Bearer ${accessToken}` };
          store.dispatch(finishLoading());
          return customAxios(err.response.config);
          setShowMessage(true);
        } catch (e) {
          store.dispatch(userinfoInit());
          const ex = e as unknown as AxiosError<ErrorUserType>;
          if (ex.response) {
            ex.response.data.message =
              'Refresh token was expired. Please make a new signin request.';
            store.dispatch(
              showMessage(
                'Refresh token was expired. Please make a new signin request.',
              ),
            );
          }
          store.dispatch(finishLoading());
          return Promise.reject(ex);
        }
      }
    } else if (err.response.data.message && isShowMessage) {
      // store.dispatch(showMessage(err.response.data.message));
    }
    setShowMessage(true);
    return Promise.reject(err);
  },
);

export type { AxiosResponse, AxiosError };
export { customAxios, axios };

// customAxios.interceptors.request.use() 리프레쉬 토큰 처리
