import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import '../../App.scss';
import { Button } from '@mui/material';

const MainLayout = () => {
  const handleTerms = () => {
    window.open(`/terms/en/1`, '_blank');
  };

  const handlePrivacy = () => {
    window.open(`/terms/en/2`, '_blank');
  };
  return (
    <Wrap>
      <div style={{ background: '#000' }}>
        <div>
          <Sidebar />
        </div>
      </div>
      <div className="right">
        <div className="mt">
          <Outlet />
        </div>
        <div className="termsbutton-wrap">
          <Button variant="text" className="textBtn gray" onClick={handleTerms}>
            Terms of Service
          </Button>
          <span>·</span>
          <Button
            variant="text"
            className="textBtn black"
            onClick={handlePrivacy}
          >
            Privacy Policy
          </Button>
        </div>
      </div>
    </Wrap>
  );
};

export default MainLayout;

// const Wrap = styled.div`
//   display: 'flex';
//   flex: '1 1 auto';
//   max-width: '100%';
//   padding-top: 64;
// `;

const Wrap = styled.div`
  display: flex;
  position: relative;
  .right {
    width: 100%;
    background: #f3f3f3;
  }
  .mt {
    margin-top: 98px;
    padding: 0px 50px 0px 50px;
    height: calc(100vh - 145px);
    position: relative;
  }
  .termsbutton-wrap {
    padding: 0 50px 0 50px;
    .textBtn {
      margin: 0 8px 0 8px;
      height: 42px;
      font-size: 14px !important;
      line-height: 18px;
      font-weight: 700 !important;
      &.gray {
        color: #616161;
      }
      &.black {
        color: #000;
      }
      &:hover {
        color: #908e8e;
      }
    }
  }
`;
