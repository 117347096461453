import { createSlice } from '@reduxjs/toolkit';

const initialState: LoadingStateType = {
  count: 0,
  loading: false,
  showLoading: true,
};

export const LoadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: state => {
      state.count += 1;
      state.loading = state.count > 0;
    },
    finishLoading: state => {
      state.count -= 1;
      if (state.count < 0) {
        state.count = 0;
      }
      state.loading = state.count > 0;
      if (!state.loading) {
        state.showLoading = true;
      }
    },
    hideLoading: state => {
      state.showLoading = false;
    },
  },
});

export const { startLoading, finishLoading, hideLoading } =
  LoadingSlice.actions;
export default LoadingSlice.reducer;
