import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { axios, customAxios } from '../../lib/customAxios';
import { useAppDispatch } from '../../app/hooks';
import { showMessage } from '../../features/messagebox/MessageboxSlice';
import { Breadcrumbs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  GridSelectionModel,
} from '@mui/x-data-grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import ModalPopup from '../common/ModalPopup';
import DownloadProgress from '../common/DownloadProgress';
import { t } from '@lingui/macro';
import 'react-quill/dist/quill.snow.css';

const NoticeView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { noticeId } = useParams();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [noticeFile, setNoticeFile] = useState<FileInterface[]>([]);
  const [noticeInfo, setNoticeInfo] = useState<NoticeInterface | undefined>(
    undefined,
  );
  const [prevId, setPrevId] = useState<number>(-1);
  const [nextId, setNextId] = useState<number>(-1);
  const [openProgress, setOpenProgress] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const columns: GridColumns = [
    {
      field: 'fileName',
      headerName: t`File name`,
      flex: 1,
      minWidth: 500,
    },
    {
      field: 'download',
      headerName: ' ',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="text"
            onClick={() => {
              downloadFile(Array.of(params.row.id));
            }}
            startIcon={<DownloadIcon />}
          >
            {t`Download`}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (noticeId) {
      customAxios
        .get<NoticeInterface>(`/mdx/V1.0/common/notice/${noticeId}`)
        .then(response => {
          setNoticeInfo(response.data);
          setNoticeFile(response.data.noticeFileList);
          setPrevId(response.data.prevId);
          setNextId(response.data.nextId);
        });
    }
  }, [noticeId]);

  const selectAllRow = () => {
    setSelectionModel(
      noticeFile.map(item => {
        return item.id;
      }),
    );
  };

  const download = () => {
    if (selectionModel.length > 0) {
      downloadFile(selectionModel);
    } else {
      dispatch(showMessage('선택된 항목이 없습니다.'));
    }
  };

  const downloadFile = (idList: GridRowId[]) => {
    if (noticeInfo) {
      customAxios
        .get<NoticeInterface>(`/mdx/V1.0/common/notice/${noticeInfo.id}`)
        .then(response => {
          if (response.data.noticeFileList) {
            const tempCheckFileData = response.data.noticeFileList.filter(
              item => idList.includes(item.id),
            );
            const filePerArr: number[] = Array(tempCheckFileData.length);
            setOpenProgress(true);

            tempCheckFileData.forEach((item, itemIndex) => {
              axios
                .get<Blob>(item.filePath, {
                  responseType: 'blob',
                  onDownloadProgress: progressEvent => {
                    filePerArr[itemIndex] = Math.round(
                      ((100 / tempCheckFileData.length) *
                        progressEvent.loaded) /
                        progressEvent.total,
                    );
                    let total = 0;
                    filePerArr.forEach(size => {
                      total += size;
                    });
                    setProgress(total);
                  },
                })
                .then(response => {
                  const fileTemp = tempCheckFileData.find(
                    item => item.filePath === response.config.url,
                  );
                  const filename = fileTemp ? fileTemp.fileName : 'test';
                  const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  setOpenProgress(false);
                })
                .catch(() => {
                  setOpenProgress(false);
                });
            });
          }
        });
    }
  };

  return (
    <Wrap>
      {noticeInfo && (
        <>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ mb: 1 }}
          >
            <NavLink to="/main/notice/noticeList">{t`Notice`}</NavLink>
            <Typography
              sx={{ display: 'flex', alignItems: 'center', fontSize: 20 }}
              color="text.primary"
            >
              &ldquo; {noticeInfo.subject} &rdquo;
            </Typography>
          </Breadcrumbs>
          <div>
            <Box
              className="btnGroup"
              sx={{ '& button': { mr: 0, ml: 2, mb: 2 } }}
            >
              <Button variant="outlined" onClick={selectAllRow}>
                {t`Select All`}
              </Button>
              <Button variant="outlined" onClick={download}>
                {t`Download all at once`}
              </Button>
            </Box>
          </div>
          <div
            style={{
              background: '#fff',
              padding: '1.4em',
              borderRadius: '8px',
              minHeight: 'calc(100vh - 250px)',
              // border: '1px solid #A8A7A7',
            }}
          >
            <div style={{ height: 'auto', width: '100%' }}>
              <DataGrid
                rows={noticeFile}
                columns={columns}
                selectionModel={selectionModel}
                onSelectionModelChange={newSelectionModel => {
                  setSelectionModel(newSelectionModel);
                }}
                headerHeight={45}
                rowHeight={45}
                autoHeight
                checkboxSelection
                hideFooterPagination
                hideFooter
              />
            </div>
            <span
              className="ql-editor"
              style={{ whiteSpace: 'pre-wrap', marginTop: 20, padding: 16 }}
              dangerouslySetInnerHTML={{ __html: noticeInfo.content }}
            />
            <div>
              <Stack
                direction="row"
                spacing={5}
                justifyContent="center"
                alignItems="center"
              >
                {prevId && (
                  <Button
                    onClick={() => {
                      navigate(`/main/notice/noticeInfo/${prevId}`);
                    }}
                    className="grey"
                    startIcon={
                      <svg
                        width="39"
                        height="38"
                        viewBox="0 0 39 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.8995 26.2675L17.6478 19L24.8995 11.7325L22.667 9.5L13.167 19L22.667 28.5L24.8995 26.2675Z"
                          fill="#908E8E"
                        />
                      </svg>
                    }
                    disabled={prevId === null}
                  >
                    {t`Previous`}
                  </Button>
                )}
                {nextId && (
                  <Button
                    onClick={() => {
                      navigate(`/main/notice/noticeInfo/${nextId}`);
                    }}
                    className="grey"
                    endIcon={
                      <svg
                        width="39"
                        height="38"
                        viewBox="0 0 39 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.1006 26.2675L21.3523 19L14.1006 11.7325L16.3331 9.5L25.8331 19L16.3331 28.5L14.1006 26.2675Z"
                          fill="#908E8E"
                        />
                      </svg>
                    }
                    disabled={nextId === null}
                  >
                    {t`Next`}
                  </Button>
                )}
              </Stack>
            </div>
          </div>
        </>
      )}
      <ModalPopup open={openProgress} title="" popupWidth={392}>
        <DownloadProgress progress={progress} />
      </ModalPopup>
    </Wrap>
  );
};

export default NoticeView;

const Wrap = styled.div`
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
  .grey {
    color: #878585;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .MuiDataGrid-columnHeaders {
    background: #c8c8c8;
  }
  .note-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
  .noticInfo {
    p {
      line-height: 21px;
      height: 28px;
    }
  }
  .noticeContent {
    padding: 24px 0px;
    p {
      img {
        width: 100%;
      }
    }
  }
  .noticeFileList {
    /* display: flex; */
  }
  table {
    width: 100%;
    td {
      font-size: 16px;
      width: 100%;
    }
  }
`;
