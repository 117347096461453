import { useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { customAxios } from '../../lib/customAxios';
import styled from 'styled-components';
import Logo from '../../assets/images/app-logo.svg';
import Typography from '@mui/material/Typography';
import { logout, userinfoInit } from '../../features/userinfo/UserinfoSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const EmailVerificationScreen = () => {
  const { confirmToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    customAxios.put(`/mdx/V1.0/auth/user/email_token/${confirmToken}`, {
      confirmToken,
    });
  }, []);

  const onClick = () => {
    navigate('/login');
  };

  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} className="login-top-logo" alt="logo" />
          <Typography component="h2">Complete Email Authentication</Typography>
          <Typography component="h4">
            Congratulations! Your email has been successfully verified. Your
            account will soon be approved by the admin.
          </Typography>
          <Button
            className="btn"
            variant="contained"
            onClick={onClick}
            fullWidth
          >
            OK
          </Button>
        </div>
      </div>
    </Wrap>
  );
};

export default EmailVerificationScreen;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #f3f3f3;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }
  .logo-bottom {
    margin-bottom: 24px;
  }
  .textColor {
    color: #0076ce;
  }
`;
