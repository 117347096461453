import { useEffect, useState } from 'react';
import NoticeList from '../../components/notice/NoticeList';
import NoticeView from '../../components/notice/NoticeView';
import NoticeWrite from '../../components/notice/NoticeWrite';
import { useNavigate, useParams } from 'react-router-dom';

export type subMenuType = 'noticeList' | 'noticeInfo' | 'noticeWrite';

const NoticeScreen = () => {
  const { submenu } = useParams();
  const navigate = useNavigate();
  const [menuType, setMenuType] = useState<subMenuType>('noticeList');

  useEffect(() => {
    if (
      submenu &&
      ['noticeInfo', 'noticeList', 'noticeWrite'].includes(submenu)
    ) {
      setMenuType(submenu as subMenuType);
    } else {
      navigate(`/main/notice/noticeList`);
    }
  }, [submenu]);

  return (
    <>
      {menuType === 'noticeList' && <NoticeList />}
      {menuType === 'noticeInfo' && <NoticeView />}
      {menuType === 'noticeWrite' && <NoticeWrite />}
    </>
  );
};

export default NoticeScreen;
