/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import { NavLink } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { dynamicActivate, getLocal, locales } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SeegeneLogo from '../../assets/images/seegene_logo.svg';
import styled from 'styled-components';
import { setLang } from '../../features/userinfo/UserinfoSlice';
import { t } from '@lingui/macro';
import CopyRight from '../../components/common/CopyRight';
import Typography from '@mui/material/Typography';
import LoginForm from '../../components/auth/LoginForm';

const LoginScreen = () => {
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector(state => state.userinfo);

  const handleLangChange = (event: SelectChangeEvent) => {
    const pLang = getLocal(event.target.value);
    dynamicActivate(pLang);
    dispatch(setLang(pLang));
  };

  const handleTerms = () => {
    window.open(`/terms/${lang}/1`, '_blank');
  };

  const handlePrivacy = () => {
    window.open(`/terms/${lang}/2`, '_blank');
  };

  return (
    <Wrap>
      <div>
        <div className="login-box">
          <div style={{ width: '100%' }}>
            <Box sx={{ textAlign: 'center' }}>
              <img
                alt="Seegene"
                src={SeegeneLogo}
                style={{
                  marginBottom: 32,
                  display: 'inline-block',
                  maxWidth: '100%',
                  width: 240,
                }}
              />
            </Box>
            <Typography className="title">{t`Login`}</Typography>
            <div className="btn-box">
              <NavLink to="/ad">
                <Button fullWidth variant="contained">
                  Employee Login (Sign in with SSO)
                </Button>
              </NavLink>
            </div>
            <div className="hr">or</div>
            <LoginForm />
          </div>
          <div className="link-text-wrap">
            <div className="link-text">
              <NavLink to="/searchid">FIND ACCOUNT</NavLink>
              <NavLink to="/password">FIND PASSWORD</NavLink>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="lang-setting-box" style={{ display: 'none' }}>
        <FormControl>
          <Select
            id="langSelect"
            IconComponent={props => (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
              >
                <path
                  d="M7.41 9.13354L12 13.7135L16.59 9.13354L18 10.5435L12 16.5435L6 10.5435L7.41 9.13354Z"
                  fill="#333333"
                />
              </svg>
            )}
            value={lang || window.navigator.language.split('-')[0]}
            onChange={handleLangChange}
            sx={{ width: '100px' }}
          >
            {Object.keys(locales).map(item => (
              <MenuItem value={item} key={item}>
                {locales[item as keyof typeof locales]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="button-wrap">
          <Button variant="text" className="black" onClick={handleTerms}>
            {t`Terms & Conditions`}
          </Button>
          <Button variant="text" className="black" onClick={handlePrivacy}>
            {t`Privacy Policy`}
          </Button>
        </div>
      </div> */}
      <div className="button-wrap">
        <Button variant="text" className="textBtn gray" onClick={handleTerms}>
          Terms of Service
        </Button>
        <Button
          variant="text"
          className="textBtn black"
          onClick={handlePrivacy}
        >
          Privacy Policy
        </Button>
      </div>
      <CopyRight />
    </Wrap>
  );
};

export default LoginScreen;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  flex-direction: column;

  a {
    color: #0076ce;
    text-decoration: none;
  }

  .hr {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: #878585;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin: 8px 0px;
  }
  .hr::before {
    content: '';
    flex-grow: 1;
    background: #878585;
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin-right: 28px;
  }
  .hr::after {
    content: '';
    flex-grow: 1;
    background: #878585;
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin-left: 28px;
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 12px;
  }
  input {
    width: 100%;
  }

  .btn-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    a {
      text-decoration: none !important;
    }
    button {
      border-radius: 8px;
      margin-bottom: 15px;
      padding: 16px;
      height: 46px;
      box-sizing: border-box;
      font-weight: 700;
      font-size: 16px;
      /* color: #fff !important; */
      line-height: 24px;
    }
  }

  .link-text-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    position: absolute;
    bottom: 33px;

    .link-text {
      /* position: absolute; */
      a {
        color: #0076ce;
        text-decoration: none;
        font-size: 14px !important;
        line-height: 18px !important;
        font-weight: 700 !important;
        &:hover {
          color: #2290e2;
        }
        &::after {
          content: '';
          /* width: 1px;
          height: 13px;
          background: #9f9f9f;
          display: inline-block; */
          margin-left: 19px;
          margin-right: 19px;
        }
        &:nth-last-child(1)::after {
          display: none;
        }
      }
    }
  }
  .lang-setting-box {
    margin-top: 20px;
    width: 498px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiSelect-icon {
      top: calc(50% - 0.7em);
    }
    .MuiOutlinedInput-root.Mui-focused > fieldset {
      border-color: #b3b2b2 !important;
    }
    .lang-setting-label {
      margin-right: 45px;
    }
  }
  .button-wrap {
    margin-top: 25px;
    .textBtn {
      margin-left: 8px;
      height: 42px;
      font-size: 14px !important;
      line-height: 18px;
      font-weight: 700 !important;
      &.gray {
        color: #616161;
      }
      &.black {
        color: #000;
      }
      &:hover {
        color: #908e8e;
      }
    }
  }
`;
