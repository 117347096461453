import { ChangeEvent, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import UpdateInfoForm, { getCheckedIds } from './UpdateInfoForm';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { showMessage } from '../../features/messagebox/MessageboxSlice';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { customAxios } from '../../lib/customAxios';
import { setUserinfo } from '../../features/userinfo/UserinfoSlice';
import { t } from '@lingui/macro';
import TermsForm from './TermsForm';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Logo from '../../assets/images/app-logo.svg';
import { Typography } from '@mui/material';

interface Props {
  setComplete: (isComplete: boolean) => void;
}

const MoreInfoForm = ({ setComplete }: Props) => {
  const dispatch = useAppDispatch();
  const { id, username } = useAppSelector(state => state.userinfo);
  const [swUpdate, setSwUpdate] = useState<boolean>(false);
  const [swUpdateArr] = useState<number[]>([]);
  const [swUpdateError, setSwUpdateError] = useState<boolean>(false);
  const [swUpdateHelperText, setSwUpdateHelperText] = useState<string>('');
  const [isTerms, setTerms] = useState<boolean>(false);
  const [termsIds, setTermsIds] = useState<number[]>([]);
  const [termsError, setTermsError] = useState<boolean>(false);
  const [termsHelperText, setTermsHelperText] = useState<string>('');

  const handleSwUpdateChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(
        showMessage(
          t`I want to receive an e-mail notification regarding software updates.`,
        ),
      );
    }
    setSwUpdate(event.target.checked);
  };

  const validationTerms = (): boolean => {
    if (!isTerms) {
      setTermsError(true);
      setTermsHelperText(
        'It is required to consent to the terms and conditions.',
      );
      return false;
    }
    setTermsError(false);
    setTermsHelperText('');
    return true;
  };

  const validationSwUpdate = (): boolean => {
    if (swUpdate) {
      if (getCheckedIds().length === 0) {
        setSwUpdateHelperText(
          t`Please select the software to receive update notifications.`,
        );
        setSwUpdateError(true);
        return false;
      }
    }
    setSwUpdateHelperText('');
    setSwUpdateError(false);
    return true;
  };

  const handleConfirmClick = () => {
    const vterms = validationTerms();
    const vswupdate = validationSwUpdate();
    if (vterms && vswupdate)
      customAxios
        .put(`/mdx/V1.0/setting/user/${id}`, {
          swProductIdList: getCheckedIds(),
          status: 'EMAIL_VERIFIED',
          agrTermsUseVerIdList: termsIds,
        })
        .then(response => {
          dispatch(setUserinfo(response.data));
          setComplete(true);
        });
  };

  const isSeegene = (): boolean => {
    return username.includes('@seegene.com');
  };

  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} className="login-top-logo" alt="" />
          <Typography className="title">Terms and Conditions</Typography>
          {/* <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={swUpdate}
                  onChange={handleSwUpdateChange}
                  name="swUpdate"
                />
              }
              label={t`Receive e-mails when updating software`}
            />
          </div>  */}
          {/* <div style={{ paddingTop: '24px', display: 'none' }}>
            <div className="sw">{t`Select Software`}</div>
            <FormControl error={swUpdateError} variant="outlined" fullWidth>
              <UpdateInfoForm
                checkIds={swUpdateArr}
                disabled={!swUpdate}
                type={
                  isSeegene()
                    ? ['EXTERN_DISTURB', 'INTERN_DISTURB']
                    : ['EXTERN_DISTURB']
                }
                className={`check-list-wrap ${swUpdateError ? `error` : ``}`}
              />
              {swUpdateError && (
                <FormHelperText>{swUpdateHelperText}</FormHelperText>
              )}
            </FormControl>
          </div> */}
          <TermsForm
            setTerms={setTerms}
            setTermsIds={setTermsIds}
            termsIds={termsIds}
            error={termsError}
            helperText={termsHelperText}
          />
          <Button
            variant="contained"
            onClick={handleConfirmClick}
            fullWidth
            size="medium"
            sx={{ height: 46, marginTop: '24px' }}
          >
            SIGN UP
          </Button>
        </div>
      </div>
    </Wrap>
  );
};

export default MoreInfoForm;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f3f3f3;
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
  .msg {
    font-size: 14px;
    margin: 5px 0px 20px 0px;
    letter-spacing: -0.2px;
  }
  .check-list-wrap {
    border: 1px solid #878585;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 19px;
    height: 172px;
    overflow: auto;
  }
  .error {
    border-color: #e01919;
  }
  .sw {
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4d4c4c;
    background: #fff;
    width: 110px;
    height: 20px;
    position: absolute;
    transform: translate(0px, -8px);
    text-align: center;
    z-index: 100;
  }
`;
