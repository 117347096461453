import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { customAxios } from '../../lib/customAxios';
import { t } from '@lingui/macro';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import styled from 'styled-components';

interface SwProductInfoInterface extends SwProductInterface {
  pushInfo: boolean;
}

interface Props {
  checkIds: number[];
  disabled?: boolean;
  className?: string;
  onChage?: () => void;
  type: string[]; // INTERN_DISTURB EXTERN_DISTURB
}

let productArr: SwProductInfoInterface[] = [];

export const getCheckedIds = (): number[] => {
  return productArr.filter(item => item.pushInfo).map(item => item.id);
};

const UpdateInfoForm = ({
  checkIds,
  onChage,
  disabled,
  className,
  type,
}: Props) => {
  const [product, setProduct] = useState<SwProductInfoInterface[]>([]);
  const [productIntern, setProductIntern] = useState<SwProductInfoInterface[]>(
    [],
  );

  useEffect(() => {
    customAxios
      .get<ResultType<SwProductInterface>>('/mdx/V1.0/swm/sw/notAuth')
      .then(response => {
        if (type.includes('EXTERN_DISTURB')) {
          setProduct(
            response.data.content
              .filter(
                (item: SwProductInterface) =>
                  item.disturbDsn === 'EXTERN_DISTURB',
              )
              .map((item: SwProductInterface) => {
                return { ...item, pushInfo: checkIds.includes(item.id) };
              }),
          );
        }
        if (type.includes('INTERN_DISTURB')) {
          setProductIntern(
            response.data.content
              .filter(
                (item: SwProductInterface) =>
                  item.disturbDsn === 'INTERN_DISTURB',
              )
              .map((item: SwProductInterface) => {
                return { ...item, pushInfo: checkIds.includes(item.id) };
              }),
          );
        }
      });
  }, []);

  useEffect(() => {
    productArr = [...product, ...productIntern];
    if (onChage) onChage();
  }, [product, productIntern]);

  useEffect(() => {
    setProduct(
      product.map((item: SwProductInfoInterface) => {
        return { ...item, pushInfo: checkIds.includes(item.id) };
      }),
    );
    setProductIntern(
      productIntern.map((item: SwProductInfoInterface) => {
        return { ...item, pushInfo: checkIds.includes(item.id) };
      }),
    );
  }, [checkIds]);

  const isAllChecked = (): boolean => {
    return (
      product.filter(item => item.pushInfo === true).length ===
        product.length &&
      productIntern.filter(item => item.pushInfo === true).length ===
        productIntern.length
    );
  };

  const isIndeterminate = (): boolean => {
    const count =
      product.filter(item => item.pushInfo === true).length +
      productIntern.filter(item => item.pushInfo === true).length;
    return count > 0 && count !== product.length + productIntern.length;
  };

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProduct(
      product.map(item => {
        return { ...item, pushInfo: event.target.checked };
      }),
    );
    setProductIntern(
      productIntern.map(item => {
        return { ...item, pushInfo: event.target.checked };
      }),
    );
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProduct(
      product.map(item =>
        `${item.id}` === event.target.id
          ? { ...item, pushInfo: event.target.checked }
          : item,
      ),
    );
  };

  const handleProductInternChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProductIntern(
      productIntern.map(item =>
        `${item.id}` === event.target.id
          ? { ...item, pushInfo: event.target.checked }
          : item,
      ),
    );
  };

  const isChecked = (id: number): boolean => {
    const target = product.find(item => item.id === id);
    if (target) {
      return target.pushInfo;
    }
    return false;
  };

  const isCheckedIntern = (id: number): boolean => {
    const target = productIntern.find(item => item.id === id);
    if (target) {
      return target.pushInfo;
    }
    return false;
  };

  return (
    <Wrap className={className}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {(product.length > 0 || productIntern.length > 0) && (
          <FormControlLabel
            label={t`Select All`}
            disabled={disabled || false}
            control={
              <Checkbox
                checked={isAllChecked()}
                indeterminate={isIndeterminate()}
                onChange={handleAllChange}
              />
            }
          />
        )}
        {product.length > 0 && (
          <>
            <FormLabel component="legend">{t`External Deploy`}</FormLabel>
            <FormGroup>
              {product.map(item => (
                <FormControlLabel
                  label={item.name}
                  key={`${item.id}`}
                  disabled={disabled || false}
                  control={
                    <Checkbox
                      id={`${item.id}`}
                      onChange={handleProductChange}
                      checked={isChecked(item.id)}
                    />
                  }
                />
              ))}
            </FormGroup>
          </>
        )}
        {productIntern.length > 0 && (
          <>
            <FormLabel component="legend">{t`Internal Deploy`}</FormLabel>
            <FormGroup>
              {productIntern.map(item => (
                <FormControlLabel
                  label={item.name}
                  key={`${item.id}`}
                  disabled={disabled || false}
                  control={
                    <Checkbox
                      id={`${item.id}`}
                      onChange={handleProductInternChange}
                      checked={isCheckedIntern(item.id)}
                    />
                  }
                />
              ))}
            </FormGroup>
          </>
        )}
      </div>
    </Wrap>
  );
};

export default UpdateInfoForm;

const Wrap = styled.div`
  .MuiFormLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.6);
  }
`;
