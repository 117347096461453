import { ChangeEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useAppSelector } from '../../app/hooks';
import UpdateInfoForm, { getCheckedIds } from './UpdateInfoForm';
import Button from '@mui/material/Button';
import { customAxios } from '../../lib/customAxios';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { NavLink, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { validationEmail } from '../../lib/common';

const MyPageForm = () => {
  const navigate = useNavigate();
  const { id, username } = useAppSelector(state => state.userinfo);
  const [name, setName] = useState<string>('');
  const [nameHelptext, setNameHelptext] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailHelptext, setEmailHelptext] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [swUpdateIds, setSwUpdateIds] = useState<number[]>([]);
  const [department, setDepartment] = useState<string>('');
  const [organizationHelptext, setOrganizationHelptext] = useState<string>('');
  const [organizationError, setOrganizationError] = useState<boolean>(false);

  useEffect(() => {
    customAxios
      .get<UserInterface>(`/mdx/V1.0/setting/user/${id}/`)
      .then(res => {
        setName(res.data.name);
        setEmail(res.data.subEmail);
        setSwUpdateIds(res.data.swIdList);
        setDepartment(res.data.department);
      });
  }, []);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (e.target.value === '') {
      setNameError(true);
      setNameHelptext(t`Please enter a name.`);
    } else {
      setNameError(false);
      setNameHelptext('');
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (e.target.value === '') {
      setEmailError(true);
      setEmailHelptext('Please enter an email address.');
    } else if (!validationEmail(e.target.value)) {
      setEmailError(true);
      setEmailHelptext(
        'Please enter your email address in the correct format.',
      );
    } else if (e.target.value === username) {
      setEmailError(true);
      setEmailHelptext(
        'Please enter email address that is not identical to ID. ',
      );
    } else {
      setEmailError(false);
      setEmailHelptext('');
    }
  };

  const handleChangeDepartment = (e: ChangeEvent<HTMLInputElement>) => {
    setDepartment(e.target.value);
    if (e.target.value === '') {
      setOrganizationError(true);
      setOrganizationHelptext('Please enter an organization.');
    } else {
      setOrganizationError(false);
      setOrganizationHelptext('');
    }
  };

  const onSave = () => {
    if (!emailError && !organizationError) {
      const swProductIdList = getCheckedIds();
      customAxios
        .put(`/mdx/V1.0/setting/user/${id}`, {
          name,
          subEmail: email,
          username,
          department,
          swProductIdList,
        })
        .then(() => {
          navigate('/main/mypage');
        });
    }
  };

  const isSeegene = (): boolean => {
    return username.includes('@seegene.com');
  };

  return (
    <Wrap>
      <div>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <Typography className="title">{t`ID`}</Typography>
          <TextField
            id="name"
            variant="outlined"
            className="mypageInput"
            disabled
            value={username}
            sx={{
              width: '480px',
              backgroundColor: '#F2F2F2',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <Typography className="title">{t`Name`}</Typography>
          <TextField
            id="name"
            variant="outlined"
            className="mypageInput"
            value={name}
            disabled
            onChange={handleChangeName}
            error={nameError}
            helperText={nameHelptext}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              width: '480px',
              backgroundColor: '#F2F2F2',
            }}
          />
        </Stack>
        {!isSeegene() && (
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
            <Typography className="title">
              Recovery Email <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              id="email"
              variant="outlined"
              className="mypageInput"
              value={email}
              onChange={handleChangeEmail}
              error={emailError}
              helperText={emailHelptext}
              sx={{
                width: '480px',
              }}
            />
          </Stack>
        )}
        {/* <Stack
          direction="row"
          spacing={2}
          sx={{ mb: 2 }}
          style={{ display: 'none' }}
        >
          <Typography className="title">
            {t`Receive e-mails when updating software`}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <div className="sw-update-wrap">
            <UpdateInfoForm
              checkIds={swUpdateIds}
              type={
                isSeegene()
                  ? ['EXTERN_DISTURB', 'INTERN_DISTURB']
                  : ['EXTERN_DISTURB']
              }
            />
          </div>
        </Stack> */}
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
          <Typography className="title">{t`Organization`}</Typography>
          <TextField
            id="department"
            variant="outlined"
            className="mypageInput"
            value={department}
            onChange={handleChangeDepartment}
            error={organizationError}
            helperText={organizationHelptext}
            sx={{
              width: '280px',
            }}
          />
        </Stack>

        {!isSeegene() && (
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 4 }}>
            <Typography className="title">{t`Change Password`}</Typography>
            <NavLink to="/auth/resetpassword">
              <Button variant="contained" className="btn-black">
                Change
              </Button>
            </NavLink>
          </Stack>
        )}
      </div>
      <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
        <div>
          <Button variant="contained" size="large" onClick={onSave}>
            Save
          </Button>
          <NavLink to="/main/mypage">
            <Button variant="outlined" size="large">
              Cancel
            </Button>
          </NavLink>
        </div>
      </Box>
    </Wrap>
  );
};

export default MyPageForm;

const Wrap = styled.div`
  height: calc(100% - 120px);
  background: #ffffff;
  border-radius: 8px;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #a8a7a7;
  .title {
    width: 20%;
    min-width: 120px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .btn-black {
    background: rgb(0, 0, 0) !important;
    padding: 12px 24px;
    box-sizing: border-box;
    letter-spacing: 0.01em;
    /* font-style: normal;  */
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .sw-update-wrap {
    width: 480px;
    height: 172px;
    overflow: auto;
    border: 1px solid #c8c8c8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 19px;
  }
  .MuiCheckbox-root {
    padding: 7px 9px 7px 9px;
  }
  a {
    text-decoration: none;
  }
`;
