import { useState } from 'react';
import styled from 'styled-components';
import ResetPasswordEmailCompletion from '../../components/auth/ResetPasswordEmailCompletion';
import ResetPasswordEmailForm from '../../components/auth/ResetPasswordEmailForm';
import CopyRight from '../../components/common/CopyRight';

const ResetPassowrdEmailScreen = () => {
  const [completion, setCompletion] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  return (
    <Wrap>
      {!completion && (
        <ResetPasswordEmailForm
          setCompletion={setCompletion}
          setCompletUsername={setUsername}
        />
      )}
      {completion && <ResetPasswordEmailCompletion username={username} />}
      <CopyRight />
    </Wrap>
  );
};

export default ResetPassowrdEmailScreen;

const Wrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
