import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';

interface Props {
  label: string;
  searchClick: (keyword: string) => void;
}

const SearchBox = ({ label, searchClick }: Props) => {
  const location = useLocation();
  const [keyword, setKeyword] = useState<string>('');

  useEffect(() => {
    setKeyword('');
  }, [location.key]);

  const onChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const onEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      searchClick(keyword);
    }
  };

  const remove = () => {
    setKeyword('');
    searchClick('');
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      id="keyword"
      sx={{
        width: '490px',
        '& .MuiOutlinedInput-root:hover': {
          '& > fieldset': {
            borderColor: '#A8A7A7',
          },
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
          '& > fieldset': {
            borderColor: '#0076CE',
          },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                fill="#4D4C4C"
              />
            </svg>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="search"
              onClick={remove}
              onMouseDown={handleMouseDownPassword}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#8C8A8A"
                />
              </svg>
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
      placeholder={label}
      value={keyword}
      onChange={onChangeKeyword}
      onKeyUp={onEnter}
    />
  );
};

export default SearchBox;
