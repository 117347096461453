import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { customAxios } from '../../lib/customAxios';
import Stack from '@mui/material/Stack';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { useAppSelector } from '../../app/hooks';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const MyPageView = () => {
  const { id } = useAppSelector(state => state.userinfo);
  const [userInfo, setUserInfo] = useState<UserInterface>();
  const [product, setProduct] = useState<SwProductInterface[]>([]);

  useEffect(() => {
    customAxios
      .get<UserInterface>(`/mdx/V1.0/setting/user/${id}/`)
      .then(res => {
        setUserInfo(res.data);
        if (res.data.swIdList.length > 0) {
          customAxios
            .get<ResultType<SwProductInterface>>('/mdx/V1.0/swm/sw/notAuth')
            .then(response => {
              setProduct(
                response.data.content.filter((item: SwProductInterface) =>
                  res.data.swIdList.includes(item.id),
                ),
              );
            });
        }
      });
  }, []);

  return (
    <Wrap>
      <div className="mypage-box">
        <div className="box-header-design"> </div>
        <div style={{ padding: '0px 40px' }}>
          <div>
            <Typography className="mypage-name">{userInfo?.name}</Typography>
            <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
              <div style={{ width: '36px' }}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30 6H6C4.35 6 3.015 7.35 3.015 9L3 27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V9C33 7.35 31.65 6 30 6ZM30 12L18 19.5L6 12V9L18 16.5L30 9V12Z"
                    fill="#878585"
                  />
                </svg>
              </div>
              <Typography>{userInfo?.username}</Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <div style={{ width: '36px' }}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 16.5C26.49 16.5 28.485 14.49 28.485 12C28.485 9.51 26.49 7.5 24 7.5C21.51 7.5 19.5 9.51 19.5 12C19.5 14.49 21.51 16.5 24 16.5ZM12 16.5C14.49 16.5 16.485 14.49 16.485 12C16.485 9.51 14.49 7.5 12 7.5C9.51 7.5 7.5 9.51 7.5 12C7.5 14.49 9.51 16.5 12 16.5ZM12 19.5C8.505 19.5 1.5 21.255 1.5 24.75V28.5H22.5V24.75C22.5 21.255 15.495 19.5 12 19.5ZM24 19.5C23.565 19.5 23.07 19.53 22.545 19.575C24.285 20.835 25.5 22.53 25.5 24.75V28.5H34.5V24.75C34.5 21.255 27.495 19.5 24 19.5Z"
                    fill="#878585"
                  />
                </svg>
              </div>
              <Typography>{userInfo?.department}</Typography>
            </Stack>
            {/* <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <div style={{ width: '36px' }}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30 9H18L15 6H6C4.35 6 3 7.35 3 9V27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V12C33 10.35 31.65 9 30 9ZM21 27V22.5H15V16.5H21V12L28.5 19.5L21 27Z"
                    fill="#878585"
                  />
                </svg>
              </div>
              <Typography>
                {product.map(item => item.name).join(', ')}
              </Typography>
            </Stack> */}
          </div>
          <div className="button-wrap">
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <NavLink to="/main/updatemypage">
                <Button
                  variant="text"
                  startIcon={
                    <svg
                      width="37"
                      height="36"
                      viewBox="0 0 37 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.5 16.5C26.99 16.5 28.985 14.49 28.985 12C28.985 9.51 26.99 7.5 24.5 7.5C22.01 7.5 20 9.51 20 12C20 14.49 22.01 16.5 24.5 16.5ZM12.5 16.5C14.99 16.5 16.985 14.49 16.985 12C16.985 9.51 14.99 7.5 12.5 7.5C10.01 7.5 8 9.51 8 12C8 14.49 10.01 16.5 12.5 16.5ZM12.5 19.5C9.005 19.5 2 21.255 2 24.75V28.5H23V24.75C23 21.255 15.995 19.5 12.5 19.5ZM24.5 19.5C24.065 19.5 23.57 19.53 23.045 19.575C24.785 20.835 26 22.53 26 24.75V28.5H35V24.75C35 21.255 27.995 19.5 24.5 19.5Z"
                        fill="#878585"
                      />
                    </svg>
                  }
                >
                  {t`Edit my information`}
                </Button>
              </NavLink>
            </Stack>
          </div>
        </div>
      </div>
    </Wrap>
  );
};

export default MyPageView;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  .box-header-design {
    height: 155px;
    background: #333333;
    border-radius: 16px 16px 0px 0px;
  }
  .mypage-box {
    width: 498px;
    height: 747px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: relative;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
    }
  }
  p.mypage-name {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    padding: 40px 0px 66px 0px;
    display: flex;
    justify-content: center;
    color: #4d4c4c;
  }
  .button-wrap {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    padding: 50px 0px;
    width: 88%;
    border-top: 1px solid #a8a7a7;
    button {
      font-style: normal;
      font-weight: 400 !important;
      font-size: 24px !important;
      line-height: 32px;
      color: #4d4c4c;
    }
  }
  a {
    text-decoration: none;
  }
`;
