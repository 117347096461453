import { ChangeEvent, useEffect, useState, MouseEvent } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { axios, customAxios } from '../../lib/customAxios';
import SearchBox from '../common/SearchBox';
import ModalPopup from '../common/ModalPopup';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import { formatVersion, getDate } from '../../lib/common';
import SWAppVersionAddModify from './SWAppVersionAddModify';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import IconAdd from '../../assets/images/icon_add.svg';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import DownloadProgress from '../common/DownloadProgress';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import { hideLoading } from '../../features/loading/LoadingSlice';
import Skeleton from '@mui/material/Skeleton';
import { t } from '@lingui/macro';
import FileDownload from '../../assets/icon/FileDownload';
import MoreVert from '../../assets/icon/MoreVert';

interface Props {
  swproductlist: SwProductInterface[];
}

const SwAppVersionList = ({ swproductlist }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { appId } = useParams();
  const { c, r, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const { loading, showLoading } = useAppSelector(state => state.loading);
  const [rowsPerPage] = useState<number>(50);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [moreMenu, setMoreMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [swVersionList, setSwVersionList] = useState<SwVersionInterface[]>([]);
  const [selectedData, setSelectedData] = useState<
    SwVersionInterface | undefined
  >(undefined);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined);
  const [popuptitle, setPopupTitle] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [modifyVersionPopup, setModifyVersionPopup] = useState(false);
  const [openProgress, setOpenProgress] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (appId && r) getSwVersionList(1);
  }, [appId, r]);

  useEffect(() => {
    if (progress === 100) {
      setOpenProgress(0);
      dispatch(openAlert({ message: t`Downloaded`, title: t`OK` }));
    }
  }, [progress]);

  const handleMoreInfo = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    setSelectedRowId(Number(id));
    setMoreMenu(
      moreMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleMoreMenuClose = () => {
    setMoreMenu(null);
  };

  const getSwVersionList = (pageIndex: number, search?: string) => {
    dispatch(hideLoading());
    setPage(pageIndex);
    setSearchWord(search || '');
    customAxios
      .get<ResultType<SwVersionInterface>>(
        !search || (search && search === '')
          ? `/mdx/V1.0/swm/sw/${appId}/version?page=${
              pageIndex - 1
            }&size=${rowsPerPage}&sort=version,DESC`
          : `/mdx/V1.0/swm/sw/${appId}/version?page=${
              pageIndex - 1
            }&size=${rowsPerPage}&sort=version,DESC&search=${search}`,
      )
      .then(response => {
        setSwVersionList(response.data.content);
        setPage(response.data.number + 1);
        if (response.data.totalPages === 0) {
          setTotalPage(undefined);
        } else {
          setTotalPage(response.data.totalPages);
        }
      });
  };

  const onSearchClick = (search: string) => {
    getSwVersionList(page, search);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    getSwVersionList(value, searchWord);
  };

  const handleAdd = () => {
    setPopupTitle(t`Add App Version`);
    setSelectedData(undefined);
    setModifyVersionPopup(true);
  };

  const handleModify = () => {
    const data = swVersionList.find(item => item.id === selectedRowId);
    if (data) {
      handleMoreMenuClose();
      setPopupTitle(t`Modify App Version`);
      setSelectedData(data);
      setModifyVersionPopup(true);
    }
  };

  const handleDelete = async () => {
    if (d) {
      handleMoreMenuClose();
      const result = await dispatch(
        openConfirm({
          type: 'error',
          message: t`Are you sure to delete the version?`,
          title: t`Delete`,
          okString: t`Delete`,
        }),
      );
      if (result.payload) {
        customAxios
          .delete(`/mdx/V1.0/swm/sw/${appId}/version/${selectedRowId}`)
          .then(() => {
            getSwVersionList(page);
            dispatch(
              openAlert({
                message: t`Deleted version.`,
                title: t`OK`,
                type: 'success',
              }),
            );
          });
      }
    }
  };

  const download = async (data: SwVersionInterface) => {
    const result = await dispatch(
      openConfirm({
        message: t`Do you want to download?`,
        title: t`Download`,
        okString: t`Download`,
      }),
    );
    if (result.payload) {
      customAxios
        .get<SwVersionInterface>(`/mdx/V1.0/swm/sw/${appId}/version/${data.id}`)
        .then(response => {
          const tempCheckFileData = response.data.swVersionFileList;
          if (tempCheckFileData && tempCheckFileData?.length > 0) {
            const filePerArr: number[] = Array(tempCheckFileData.length);
            tempCheckFileData.forEach((item, itemIndex) => {
              setOpenProgress(openProgress + 1);
              axios
                .get<Blob>(item.filePath, {
                  responseType: 'blob',
                  onDownloadProgress: progressEvent => {
                    filePerArr[itemIndex] = Math.round(
                      ((100 / tempCheckFileData.length) *
                        progressEvent.loaded) /
                        progressEvent.total,
                    );
                    let total = 0;
                    filePerArr.forEach(size => {
                      total += size;
                    });
                    setProgress(total);
                  },
                })
                .then(response => {
                  const fileTemp = tempCheckFileData.find(
                    item => item.filePath === response.config.url,
                  );
                  const filename = fileTemp ? fileTemp.fileName : 'test';
                  const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  setOpenProgress(openProgress - 1);
                })
                .catch(() => {
                  setOpenProgress(openProgress - 1);
                  dispatch(
                    openConfirm({
                      message: t`An error occurred while downloading. Would you like to try again?`,
                      okString: t`Retry`,
                    }),
                  );
                });
            });
          }
        });
    }
  };

  const goVersionInfo = (data: SwVersionInterface) =>
    navigate(`/main/${pathname.split('/')[2]}/versionInfo/${appId}/${data.id}`);

  const columns: GridColumns = [
    {
      field: 'version',
      headerName: t`Version`,
      flex: 1,
      minWidth: 250,
      sortable: false,
      headerClassName: 'grid-title-app-name',
      cellClassName: 'grid-title-app-name',
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value ? formatVersion(params.value.toString()) : '';
      },
    },
    {
      field: 'releaseDt',
      headerName: t`Latest Deployed Date`,
      minWidth: 300,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value ? getDate(params.value.toString()) : '';
      },
    },
    {
      field: 'download',
      headerName: t`Download`,
      minWidth: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <Button
            className="text-btn"
            variant="text"
            onClick={() => {
              download(params.row);
            }}
            startIcon={<FileDownload />}
          >
            {t`Download`}
          </Button>
        );
      },
    },
    {
      field: 'more',
      headerName: '',
      width: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="text"
            className="text-btn"
            onClick={() => {
              goVersionInfo(params.row);
            }}
          >
            {t`More`}
          </Button>
        );
      },
    },
    {
      field: 'modify',
      headerName: ' ',
      width: 50,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconButton-${params.row.id}`}
              onClick={event => {
                handleMoreInfo(event, `${params.row.id}`);
              }}
              id={`${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  const handelCellClick = (params: GridCellParams) => {
    if (['version', 'releaseDt'].includes(params.field)) {
      navigate(
        `/main/${pathname.split('/')[2]}/versionInfo/${appId}/${params.row.id}`,
      );
    }
  };

  return (
    <Wrap>
      {loading && !showLoading ? (
        <Skeleton
          width={320}
          variant="rectangular"
          style={{ marginBottom: '17px' }}
        >
          <div style={{ height: '38px' }} />
        </Skeleton>
      ) : (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 1 }}
        >
          <NavLink to={`/main/${pathname.split('/')[2]}/appList`}>
            {t`All Apps`}
          </NavLink>
          <Typography
            sx={{ display: 'flex', alignItems: 'center', fontSize: 20 }}
            color="text.primary"
          >
            {`"${
              swproductlist.find(
                item => appId && item.id === parseInt(appId, 10),
              )?.name
            }"`}
          </Typography>
        </Breadcrumbs>
      )}
      <Box
        className="btnGroup"
        sx={{ '& button': { m: 1 }, justifyContent: 'space-between', mb: 2 }}
      >
        {loading ? (
          <Skeleton width={431} height={56} variant="rectangular" />
        ) : (
          <>
            <SearchBox label={t`Release Note`} searchClick={onSearchClick} />
            <Box>
              {c && (
                <Buttons
                  variant="contained"
                  onClick={handleAdd}
                  size="small"
                  startIcon={<img src={IconAdd} alt="" />}
                >
                  {t`Add Version`}
                </Buttons>
              )}
            </Box>
          </>
        )}
      </Box>
      <div>
        {loading && !showLoading ? (
          <div>
            <div
              style={{
                width: '1568px',
                height: '700px',
                backgroundColor: '#FFF',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '39px',
                  backgroundColor: '#f3f3f3',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginLeft: '68px', width: '119px' }}>
                  <Skeleton width={39} height={24} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '398px', width: '122px' }}>
                  <Skeleton width={39} height={24} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '220px', width: '214px' }}>
                  <Skeleton width={39} height={24} variant="rectangular" />
                </div>
              </div>
              {/* 바디 시작 */}
              <div style={{ display: 'flex', marginTop: '8px' }}>
                <div style={{ marginLeft: '68px', width: '152px' }}>
                  <Skeleton width={100} height={24} variant="rectangular" />
                </div>
                <div
                  style={{
                    marginLeft: '372px',
                    width: '111px',
                  }}
                >
                  <Skeleton width={100} height={24} variant="rectangular" />
                </div>
                <div
                  style={{
                    marginLeft: '227px',
                    width: '88px',
                  }}
                >
                  <Skeleton width={88} height={24} variant="rectangular" />
                </div>
                <div style={{ marginLeft: '227px', width: '45px' }}>
                  <Skeleton width={45} height={24} variant="rectangular" />
                </div>
              </div>
              {Array.from(Array(56)).map((item, i) => {
                const index = i;
                return (
                  <div
                    style={{ display: 'flex', marginTop: '20px' }}
                    key={`version1-${index}`}
                  >
                    <div
                      style={{ marginLeft: '68px', width: '152px' }}
                      key={`version2-${index}`}
                    >
                      <Skeleton
                        width={100}
                        height={24}
                        variant="rectangular"
                        key={`version-${index}`}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '372px',
                        width: '111px',
                      }}
                      key={`date1-${index}`}
                    >
                      <Skeleton
                        width={100}
                        height={24}
                        variant="rectangular"
                        key={`date-${index}`}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '227px',
                        width: '88px',
                      }}
                      key={`download1-${index}`}
                    >
                      <Skeleton
                        width={88}
                        height={24}
                        variant="rectangular"
                        key={`download-${index}`}
                      />
                    </div>
                    <div
                      style={{ marginLeft: '227px', width: '45px' }}
                      key={`more1-${index}`}
                    >
                      <Skeleton
                        width={45}
                        height={24}
                        variant="rectangular"
                        key={`more-${index}`}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <Skeleton width={1568} height={34} variant="rectangular" />
          </div>
        ) : (
          <>
            <GridWrap
              style={{
                height: 'calc(100vh - 250px)',
                minHeight: '300px',
                width: '100%',
              }}
            >
              <DataGrid
                disableColumnMenu
                disableColumnFilter
                onCellClick={handelCellClick}
                rows={swVersionList}
                columns={columns}
                hideFooterPagination
                hideFooter
              />
            </GridWrap>
            {totalPage && totalPage > 1 && (
              <Pagination
                count={totalPage}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              />
            )}
            <Menu
              open={moreMenu !== null}
              className="more-menu"
              onClose={handleMoreMenuClose}
              anchorReference="anchorPosition"
              anchorPosition={
                moreMenu !== null
                  ? { top: moreMenu.mouseY, left: moreMenu.mouseX }
                  : undefined
              }
              PaperProps={{
                style: { width: '154px' },
              }}
            >
              <MenuItem divider onClick={handleModify}>
                {t`Modify`}
              </MenuItem>
              {d && <MenuItem onClick={handleDelete}>{t`Delete`}</MenuItem>}
            </Menu>
          </>
        )}
      </div>
      <ModalPopup open={modifyVersionPopup} popupWidth={544} title={popuptitle}>
        <SWAppVersionAddModify
          swId={appId || ''}
          setOpen={setModifyVersionPopup}
          swVersion={selectedData}
          getSwVersionList={getSwVersionList}
          page={page}
        />
      </ModalPopup>
      <ModalPopup open={openProgress > 0} title="" popupWidth={392}>
        <DownloadProgress progress={progress} />
      </ModalPopup>
    </Wrap>
  );
};

export default SwAppVersionList;

const Buttons = styled(Button)``;

const Wrap = styled.div`
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
`;

const GridWrap = styled.div`
  .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root {
    display: none;
  }
  .MuiDataGrid-cell {
    border-bottom: none !important;
  }
  .text-btn {
    font-weight: 700 !important;
    color: #0076ce;
    font-size: 16px;
    line-height: 24px;
  }
  .text-btn:hover {
    color: #2290e2;
  }
  .text-btn svg {
    fill: #0076ce;
  }
  .text-btn:hover svg {
    fill: #2290e2;
  }
`;
