import Button from '@mui/material/Button';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout, userinfoInit } from '../../features/userinfo/UserinfoSlice';
import { t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { customAxios } from '../../lib/customAxios';
import Logo from '../../assets/images/app-logo.svg';

const MoreInfoCompletion = () => {
  const dispatch = useAppDispatch();
  const { id, username } = useAppSelector(state => state.userinfo);

  const handleConfirmClick = () => {
    customAxios
      .post(`/mdx/V1.0/setting/user/${id}/logout`)
      .then(() => {
        if (username.indexOf('@seegene.com') < 0) {
          dispatch(logout());
        }
      }) // 사용자 정보 초기화
      .catch(() => {
        if (username.indexOf('@seegene.com') < 0) {
          dispatch(userinfoInit());
        }
      })
      .finally(() => {
        if (!!username && username.indexOf('@seegene.com') > 0) {
          window.location.replace(
            `https://login.windows.net/301bf889-c43d-435f-be4a-d53ec728b61b/oauth2/logout?post_logout_redirect_uri=${window.location.origin}`,
          );
        }
      });
  };

  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} className="login-top-logo" alt="logo" />
          <Typography className="title">Complete Signup</Typography>
          <Typography>
            {`You have completed the signup process.\nThank you for joining our service. Before you\ncan begin using our service, please verify the\nemail address you provided to sign up.`}
          </Typography>
          <Button
            variant="contained"
            onClick={handleConfirmClick}
            size="medium"
            fullWidth
          >
            NEXT
          </Button>
        </div>
      </div>
    </Wrap>
  );
};

export default MoreInfoCompletion;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f3f3f3;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 32px 0px;
  }
`;
