import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';
import ConfirmModal from './components/common/ConfirmModal';
import Loading from './components/common/Loading';
import { startLoading } from './features/loading/LoadingSlice';
import Messagebox from './components/common/Messagebox';
import { initMymenu, setMymenu } from './features/mymenu/MymenuSlice';
import {
  refreshtokenSuccess,
  setLang,
  setUserinfo,
  userFailure,
  userinfoInit,
} from './features/userinfo/UserinfoSlice';
import { getCookie, movePageLocalStorag } from './lib/common';
import { AxiosError, customAxios, setShowMessage } from './lib/customAxios';
import Routers from './Routers';
import AlertModal from './components/common/AlertModal';
import { i18n } from '@lingui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './styles/common/utils.scss';

const App = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, login, userStatus, lang } = useAppSelector(
    state => state.userinfo,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const menulength = useAppSelector(state => state.mymenu.menu.length);

  const themeKo = createTheme({
    typography: {
      allVariants: {
        fontFamily:
          "'Noto Sans KR', -apple-system, 'Malgun Gothic', sans-serif ",
      },
    },
  });

  const themeEn = createTheme({
    typography: {
      allVariants: {
        fontFamily: "'roboto', 'Inter', -apple-system, sans-serif",
      },
    },
  });

  useEffect(() => {
    if (lang === '' || lang === undefined) {
      dispatch(setLang(i18n.locale));
    }
  }, []);

  const pathTest = (pathname: string) =>
    [
      '/emailverification',
      '/register',
      '/password',
      '/searchid',
      '/terms',
    ].find(item => pathname.indexOf(item) >= 0);

  useEffect(() => {
    if (id >= 0 && userStatus === 'SUBS_APPROVED') {
      // 로그인
      if (menulength <= 0) {
        setLoading(true);
        customAxios
          .get<MenuInterface[]>('/mdx/V1.0/setting/my_menu')
          .then(response => {
            dispatch(setMymenu(response.data));
            // navigate(movePageLocalStorag(false));
            setLoading(false);
          });
      } else {
        setLoading(false);
        // navigate(movePageLocalStorag(true));
      }
    } else if (pathTest(location.pathname.toLocaleLowerCase())) {
      setLoading(false);
      dispatch(initMymenu()); // 사용자 정보 초기화
    } else {
      // 화면을 새로고침 하는 경우, url 입력해서 들어오는 경우
      setLoading(true);
      setShowMessage(false);
      customAxios
        .post<RefreshtokenType>(`/mdx/V1.0/auth/refreshtoken`)
        .then(response => {
          dispatch(refreshtokenSuccess(response.data.accessToken));
          setShowMessage(true);
          customAxios
            .get<LoginInterface>(`/mdx/V1.0/setting/user/mine`)
            .then(res1 => {
              dispatch(setUserinfo(res1.data));
            })
            .catch((ex: AxiosError<ErrorMineInterface>) => {
              if (ex.response && ex.response.data) {
                dispatch(
                  userFailure(
                    ex.response.status,
                    '',
                    ex.response.data.status,
                    ex.response.data.id,
                    ex.response.data.username,
                  ),
                );

                navigate('/login');
                setLoading(false);
              } else {
                dispatch(userinfoInit()); // 사용자 정보 초기화
                navigate('/login');
                setLoading(false);
              }
            });
        })
        .catch(() => {
          // 기존에 SSO로 로그인한 경우 refreshtoken 없는 경우 자동으로 SSO 로그인
          // 로그아웃한 경우 제외 하기 위해 login 조건 추가
          setShowMessage(true);
          if (
            (location.pathname.indexOf('/main') === 0 ||
              location.pathname === '/') &&
            id < -1 &&
            !login &&
            getCookie('ssoSuccess') === 'azure'
          ) {
            dispatch(startLoading());
            if (location.pathname.includes('/main/')) {
              localStorage.setItem('page', location.pathname);
            }
            window.location.replace(
              `${process.env.REACT_APP_AD_URL}/mdx/oauth2/authorization/azure`,
            );
          } else {
            setLoading(false);
            dispatch(initMymenu()); // 사용자 정보 초기화
            navigate('/login');
          }
        });
    }
  }, [id]);

  return (
    <ThemeProvider theme={i18n.locale === 'ko' ? themeKo : themeEn}>
      <div className={i18n.locale}>
        {!loading && <Routers />}
        <Loading />
        <Messagebox />
        <ConfirmModal />
        <AlertModal />
      </div>
    </ThemeProvider>
  );
};

export default App;
