import { useState } from 'react';
import styled from 'styled-components';
import SearchIdForm from '../../components/auth/SearchIdForm';
import SearchIdFormCompletion from '../../components/auth/SearchIdFormCompletion';
import CopyRight from '../../components/common/CopyRight';

const SearchIdScreen = () => {
  const [email, setEmail] = useState<string>('');

  return (
    <Wrap>
      {email === '' && <SearchIdForm setComfirmEmail={setEmail} />}
      {email !== '' && <SearchIdFormCompletion email={email} />}
      <CopyRight />
    </Wrap>
  );
};

export default SearchIdScreen;

const Wrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
