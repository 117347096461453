import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const SsoScreen = () => {
  const navigate = useNavigate();
  const userId = useAppSelector(state => state.userinfo.id);

  useEffect(() => {
    if (userId <= 0) {
      window.location.replace(
        `${process.env.REACT_APP_AD_URL}/mdx/oauth2/authorization/azure`,
      );
    } else {
      navigate('/main');
    }
  }, []);

  return (
    <Modal open>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 100,
        }}
      >
        <CircularProgress disableShrink />
      </Box>
    </Modal>
  );
};

export default SsoScreen;
