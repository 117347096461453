import Button from '@mui/material/Button';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import LogoSmall from '../../components/common/LogoSmall';
import CopyRight from '../../components/common/CopyRight';
import Typography from '@mui/material/Typography';
import { logout, userinfoInit } from '../../features/userinfo/UserinfoSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { customAxios } from '../../lib/customAxios';

const WaitingScreen = () => {
  const dispatch = useAppDispatch();
  const { id, username } = useAppSelector(state => state.userinfo);

  const handleConfirmClick = () => {
    customAxios
      .post(`/mdx/V1.0/setting/user/${id}/logout`)
      .then(() => {
        if (username.indexOf('@seegene.com') < 0) {
          dispatch(logout());
        }
      }) // 사용자 정보 초기화
      .catch(() => {
        if (username.indexOf('@seegene.com') < 0) {
          dispatch(userinfoInit());
        }
      })
      .finally(() => {
        if (!!username && username.indexOf('@seegene.com') > 0) {
          window.location.replace(
            `https://login.windows.net/301bf889-c43d-435f-be4a-d53ec728b61b/oauth2/logout?post_logout_redirect_uri=${window.location.origin}`,
          );
        }
      });
  };

  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <LogoSmall />
          <Typography component="h2">Waiting For Approval</Typography>
          <Typography component="h4">{`Your account is waiting for approval. We will\nnotify you when your account is approved.`}</Typography>
          <Button
            variant="contained"
            onClick={handleConfirmClick}
            fullWidth
            size="medium"
          >
            {t`OK`}
          </Button>
        </div>
      </div>
      <CopyRight />
    </Wrap>
  );
};

export default WaitingScreen;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: #f3f3f3;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-top: 24px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: 12px;
    margin-bottom: 24px;
  }
`;
