import React, { ChangeEvent, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import { AxiosError, customAxios, setShowMessage } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  userFailure,
  loginRequest,
  setUserinfo,
} from '../../features/userinfo/UserinfoSlice';
import styled from 'styled-components';
import { validationEmail } from '../../lib/common';
import Input from '../common/Input';
import InputPassword from '../common/InputPassword';
import ModalPopup from '../common/ModalPopup';
import MoreTermsInfoForm from './MoreTermsInfoForm';
import Typography from '@mui/material/Typography';
import PasswordTooltip from './PasswordTooltip';

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userStatus, error, id } = useAppSelector(state => state.userinfo);
  const [username, setUsername] = useState<string>('');
  const [usernameHelperText, setUsernameHelperText] = useState<string>('');
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordHelperText, setPasswordHelperText] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [termsPopup, setTermsPopup] = useState<boolean>(false);

  useEffect(() => {
    if (userStatus === 'SUBS_APPROVED') {
      // App.tsx 에서 화면 이동
      navigate('/main');
    } else if (userStatus === 'RESET_PW_REQUESTED') {
      navigate('/auth/resetpassword');
    } else if (userStatus === 'EMAIL_VERIFIED') {
      navigate('/auth/waiting');
    } else if (userStatus === 'MORE_INFO_REQUIRED') {
      navigate('/auth/moreinfo');
    } else if (userStatus === 'MORE_INFO_TERMS') {
      reqTerms();
    } else if (userStatus === 'SUBS_REQUESTED') {
      navigate('/auth/subsRequested');
    } else if (error && error.length > 0) {
      setUsernameError(true);
      setPasswordError(true);
      setPasswordHelperText(error);
    }
  }, [userStatus, error]);

  const reqTerms = () => {
    setTermsPopup(true);
  };

  const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const usernamev = validationUsername();
    const passwordv = validationPassword();
    if (usernamev && passwordv) {
      dispatch(loginRequest(username));
      setShowMessage(false);
      customAxios
        .post<LoginInterface>(`/mdx/V1.0/auth/signin`, {
          username,
          password,
        })
        .then(response => {
          dispatch(setUserinfo(response.data));
          setShowMessage(true);
        })
        .catch((ex: AxiosError<ErrorUserType>) => {
          if (ex.response) {
            dispatch(
              userFailure(
                ex.response.status,
                ex.response.data.message,
                ex.response.data.userStatus,
                ex.response.data.id,
                ex.response.data.username,
              ),
            );
          }
          setShowMessage(true);
        });
    }
  };

  const validationUsername = (): boolean => {
    if (username === '') {
      setUsernameHelperText(t`Please enter an ID.`);
      setUsernameError(true);
      return false;
    }
    if (!validationEmail(username)) {
      setUsernameHelperText(
        `Please enter your email address in the correct format.`,
      );
      setUsernameError(true);
      return false;
    }
    setUsernameError(false);
    setUsernameHelperText('');
    return true;
  };

  const handleUsernameFocus = () => {
    setUsernameError(false);
    setUsernameHelperText('');
  };

  const handleUsernameBlur = () => {
    validationUsername();
  };

  const validationPassword = (): boolean => {
    if (password === '') {
      setPasswordError(true);
      setPasswordHelperText(t`Please enter a password.`);
      return false;
    }
    if (!(password.length >= 3 && password.length < 20)) {
      setPasswordError(true);
      setPasswordHelperText(
        t`You entered your ID or password incorrectly. Please re-enter them.`,
      );
      return false;
    }
    setPasswordError(false);
    setPasswordHelperText('');
    return true;
  };

  const handlePasswordFocus = () => {
    setPasswordError(false);
    setPasswordHelperText('');
  };

  const handlePasswordBlur = () => {
    validationPassword();
  };

  return (
    <Wrap>
      <div style={{ width: '100%' }}>
        <form onSubmit={onSubmit}>
          <div>
            <Typography className="title-span">{t`ID`}</Typography>
            <Input
              id="username"
              type="text"
              style={{ width: '100%' }}
              autoComplete="current-email"
              autoFocus
              variant="outlined"
              value={username}
              helperText={usernameHelperText}
              error={usernameError}
              onChange={onChangeUsername}
              onFocus={handleUsernameFocus}
              onBlur={handleUsernameBlur}
              placeholder="Email"
            />
          </div>
          <div className="input-box">
            <PasswordTooltip />
            <InputPassword
              id="password"
              style={{ width: '100%' }}
              variant="outlined"
              value={password}
              helperText={passwordHelperText}
              error={passwordError}
              placeholder={t`Password`}
              onChange={onChangePassword}
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
            />
          </div>
          <div className="signup-msg">
            <Typography>{t`Don't have an account?`}</Typography>
            <NavLink to="/register">
              <Typography>SIGN UP</Typography>
            </NavLink>
          </div>
          <Button className="btn" type="submit" variant="outlined" fullWidth>
            Login
          </Button>
        </form>
      </div>

      <ModalPopup open={termsPopup} title="" popupWidth={498}>
        <MoreTermsInfoForm id={id} setPopup={setTermsPopup} />
      </ModalPopup>
    </Wrap>
  );
};

export default LoginForm;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffffff;
  flex-direction: column;
  margin-top: 21px;
  margin-bottom: 42px;
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 26px;
  }

  .input-box {
    margin-top: 15px;
  }

  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .signup-msg {
    margin-top: 20px;
    margin-bottom: 32px;
    p {
      display: inline-block;
      margin-right: 24px;
      font-size: 14px !important;
      line-height: 18px !important;
      font-weight: 700 !important;
      a > p:hover {
        color: #2290e2;
      }
    }
  }
`;
