import React from 'react';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../app/hooks';

const Loading = () => {
  const { loading, showLoading } = useAppSelector(state => state.loading);

  return (
    <Modal open={loading && showLoading}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 100,
        }}
      >
        <CircularProgress disableShrink />
      </Box>
    </Modal>
  );
};

export default Loading;
