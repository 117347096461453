import { i18n } from '@lingui/core';
import { en, ko } from 'make-plural/plurals';

export const locales = {
  en: 'English',
  ko: '한국어',
};

export const getLocal = (locale: string): string => {
  if (!(locale in locales)) {
    locale = defaultLocale;
  }
  return locale;
};

export const defaultLocale = 'en';

i18n.loadLocaleData({
  en: { plurals: en },
  ko: { plurals: ko },
});

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string, remove?: boolean) {
  locale = getLocal(locale);
  if (i18n.locale !== locale) {
    const { messages } = await import(`./locales/${locale}/translations`);
    i18n.load(locale, messages);
    i18n.activate(locale);
    if (remove) {
      // 새로운 언어 적용 후 리로딩 됨
      sessionStorage.removeItem('page');
    }
  }
}
