import Button from '@mui/material/Button';
import { useAppDispatch } from '../../app/hooks';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';

import styled from 'styled-components';

const Gui = () => {
  const dispatch = useAppDispatch();
  const alertClick = (type: string) => {
    dispatch(
      openAlert({
        message:
          '요청하신 검색어에 대한 검색결과가 없습니다.\n입력하신 검색어를 확인해 주세요.',
        type,
      }),
    );
  };

  const alertDownloadComplete = () => {
    dispatch(
      openAlert({
        message: '다운로드가 완료 되었습니다.',
        title: '확인',
      }),
    );
  };

  const confirmClick = (type: string) => {
    dispatch(
      openConfirm({
        message:
          '요청하신 검색어에 대한 검색결과가 없습니다.\n입력하신 검색어를 확인해 주세요.',
        type,
      }),
    );
  };

  const confirmClickDownload = () => {
    dispatch(
      openConfirm({
        message: '다운로드 하시겠습니까 ?',
        title: '다운로드',
        okString: '다운로드',
      }),
    );
  };

  return (
    <Wrap>
      <div>
        <Button
          variant="contained"
          onClick={() => {
            alertClick('success');
          }}
        >
          Success Alert
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            alertClick('error');
          }}
        >
          Error Alert
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            alertClick('warning');
          }}
        >
          Warning Alert
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            alertDownloadComplete();
          }}
        >
          다운로드 확인
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={() => {
            confirmClick('success');
          }}
        >
          Success Confirm
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            confirmClick('error');
          }}
        >
          Error Confirm
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            confirmClick('warning');
          }}
        >
          Warning Confirm
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            confirmClickDownload();
          }}
        >
          다운로드
        </Button>
      </div>
    </Wrap>
  );
};

export default Gui;

const Wrap = styled.div``;
