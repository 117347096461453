import React, { FormEvent, ChangeEvent, useState, useCallback } from 'react';
import { t } from '@lingui/macro';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { validationEmail, validationPassword } from '../../lib/common';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { showMessage } from '../../features/messagebox/MessageboxSlice';
import UpdateInfoForm, { getCheckedIds } from './UpdateInfoForm';
import styled from 'styled-components';
import Input from '../common/Input';
import InputPassword from '../common/InputPassword';
import TermsForm from './TermsForm';
import { AxiosError, customAxios } from '../../lib/customAxios';
import Logo from '../../assets/images/app-logo.svg';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import PasswordTooltip from './PasswordTooltip';

interface Props {
  setComplete: (isComplete: boolean) => void;
}

const RegisterForm = ({ setComplete }: Props) => {
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector(state => state.userinfo);
  const [username, setUsername] = useState<string>('');
  const [usernameHelperText, setUsernameHelperText] = useState<string>('');
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [nameHelperText, setNameHelperText] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [subEmail, setSubEmail] = useState<string>('');
  const [subEmailHelperText, setSubEmailHelperText] = useState<string>('');
  const [subEmailError, setSubEmailError] = useState<boolean>(false);
  const [department, setDepartment] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordHelperText, setPasswordHelperText] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [passwordConfirmHelptext, setPasswordConfirmHelptext] =
    useState<string>('');
  const [passwordConfirmError, setPasswordConfirmError] =
    useState<boolean>(false);
  const [swUpdate, setSwUpdate] = useState<boolean>(false);
  const [swUpdateError, setSwUpdateError] = useState<boolean>(false);
  const [swUpdateHelperText, setSwUpdateHelperText] = useState<string>('');
  const [swUpdateArr] = useState<number[]>([]);
  const [isTerms, setTerms] = useState<boolean>(false);
  const [termsError, setTermsError] = useState<boolean>(false);
  const [termsHelperText, setTermsHelperText] = useState<string>('');
  const [termsIds, setTermsIds] = useState<number[]>([]);
  const [organizationError, setOrganizationError] = useState<boolean>(false);
  const [organizationHelperText, setOrganizationHelperText] =
    useState<string>('');

  const handleSwUpdateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(
        showMessage(
          t`I want to receive an e-mail notification regarding software updates.`,
        ),
      );
    }
    setSwUpdate(event.target.checked);
  };

  const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const validationUsername = (): boolean => {
    if (username === '') {
      setUsernameHelperText(t`Please enter an ID.`);
      setUsernameError(true);
      return false;
    }
    const seegeneRegex = /^[\w-\\/.]+@seegene.com$/;
    if (seegeneRegex.test(username)) {
      setUsernameHelperText(
        'If you are a seegene employee, please try ‘Employee Login.’',
      );
      setUsernameError(true);
      return false;
    }
    if (!validationEmail(username)) {
      setUsernameHelperText(
        'Please enter your email address in the correct format.',
      );
      setUsernameError(true);
      return false;
    }
    setUsernameError(false);
    setUsernameHelperText('');
    return true;
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validationName = (): boolean => {
    if (name === '') {
      setNameHelperText(t`Please enter a name.`);
      setNameError(true);
      return false;
    }
    setNameError(false);
    setNameHelperText('');
    return true;
  };

  const onChangeSubEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setSubEmail(e.target.value);
  };

  const validationEmailTextField = (): boolean => {
    if (subEmail === '') {
      setSubEmailHelperText(`Please enter an email address.`);
      setSubEmailError(true);
      return false;
    }
    if (!validationEmail(subEmail)) {
      setSubEmailHelperText(
        'Please enter your email address in the correct format.',
      );
      setSubEmailError(true);
      return false;
    }
    if (subEmail === username) {
      setSubEmailHelperText(
        'Please enter email address that is not identical to ID. ',
      );
      setSubEmailError(true);
      return false;
    }
    setSubEmailError(false);
    setSubEmailHelperText('');
    return true;
  };

  const onChangeDepartment = (e: ChangeEvent<HTMLInputElement>) =>
    setDepartment(e.target.value);

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const validationPasswordTextField = (): boolean => {
    if (password === '') {
      setPasswordHelperText(t`Please enter a password.`);
      setPasswordError(true);
      return false;
    }
    if (!validationPassword(password) || password === username) {
      setPasswordHelperText(t`Please enter according to the password rules.`);
      setPasswordError(true);
      return false;
    }
    setPasswordError(false);
    return true;
  };

  const validationSwUpdate = (): boolean => {
    if (swUpdate) {
      if (getCheckedIds().length === 0) {
        setSwUpdateHelperText(
          t`Please select the software to receive update notifications.`,
        );
        setSwUpdateError(true);
        return false;
      }
    }
    setSwUpdateHelperText('');
    setSwUpdateError(false);
    return true;
  };

  const validationTerms = (): boolean => {
    if (!isTerms) {
      setTermsError(true);
      setTermsHelperText(t`Agree to the Terms of Service.`);
      return false;
    }
    setTermsError(false);
    setTermsHelperText('');
    return true;
  };

  const validationOrganization = () => {
    if (department === '') {
      setOrganizationError(true);
      setOrganizationHelperText('Please enter an organization.');
      return false;
    }
    setOrganizationError(false);
    setOrganizationHelperText('');
    return true;
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const vuser = validationUsername();
    const vname = validationName();
    const vemail = validationEmailTextField();
    const vpassword = validationPasswordTextField();
    const vcpassword = validationPasswordConfirmTextField();
    const vterms = validationTerms();
    const vswupdate = validationSwUpdate();
    const vOrganization = validationOrganization();

    if (
      vuser &&
      vname &&
      vemail &&
      vpassword &&
      vcpassword &&
      vterms &&
      vswupdate &&
      vOrganization
    ) {
      const swProductIdList = getCheckedIds();
      customAxios
        .post<UserInterface>('/mdx/V1.0/auth/signup', {
          name,
          username,
          subEmail,
          department,
          password,
          swProductIdList,
          agrTermsUseVerIdList: termsIds,
          lang,
        })
        .then(() => {
          setComplete(true);
        })
        .catch((ex: AxiosError<ErrorType>) => {
          if (
            ex.response &&
            ex.response.data.message === 'Error: Username is already taken!'
          ) {
            setUsernameHelperText(t`Above email address already exists.`);
            setUsernameError(true);
          }
          if (
            ex.response &&
            ex.response.data.message.includes(
              'constraint [C_USER_SUB_EMAIL_UNIQUE]',
            )
          ) {
            setSubEmailHelperText('Above email address already exists.');
            setSubEmailError(true);
          }
        });
    }
  };

  const handleIdBlur = () => {
    validationUsername();
  };

  const handleIdFocus = () => {
    setUsernameError(false);
    setUsernameHelperText('');
  };

  const handleNameBlur = () => {
    validationName();
  };

  const handleNameFocus = () => {
    setNameError(false);
    setNameHelperText('');
  };

  const handleSubEmailBlur = () => {
    validationEmailTextField();
  };

  const handleSubEmailFocus = () => {
    setSubEmailError(false);
    setSubEmailHelperText('');
  };

  const handlePasswordBlur = () => {
    validationPasswordTextField();
  };

  const handlePasswordFocus = () => {
    setPasswordError(false);
    setPasswordHelperText('');
  };

  const handleChangePasswordConfirm = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setPasswordConfirm(event.target.value);
  };

  const handlePasswordConfirmFocus = () => {
    setPasswordConfirmError(false);
    setPasswordConfirmHelptext('');
  };

  const validationPasswordConfirmTextField = () => {
    if (passwordConfirm === '') {
      setPasswordConfirmError(true);
      setPasswordConfirmHelptext(t`Please enter a password.`);
      return false;
    }
    if (password !== passwordConfirm) {
      setPasswordConfirmError(true);
      setPasswordConfirmHelptext(
        t`The passwords don’t match. Please re-enter.`,
      );
      return false;
    }
    setPasswordConfirmError(false);
    setPasswordConfirmHelptext('');
    return true;
  };

  const handlePasswordConfirmBlur = () => {
    validationPasswordConfirmTextField();
  };

  const handleOrganizationBlur = useCallback(() => {
    validationOrganization();
  }, []);

  const handleOrganizationFocus = useCallback(() => {
    setOrganizationError(false);
    setOrganizationHelperText('');
  }, []);

  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} className="login-top-logo" alt="" />
          <Typography className="title">{t`Sign Up`}</Typography>
        </div>
        <div style={{ width: '100%' }}>
          <form>
            <div>
              <Typography className="title-span">{t`ID`}</Typography>
              <Input
                id="username"
                type="email"
                autoComplete="current-email"
                variant="outlined"
                margin="none"
                value={username}
                onChange={onChangeUsername}
                helperText={usernameHelperText}
                error={usernameError}
                size="small"
                fullWidth
                autoFocus
                placeholder="Email"
                onBlur={handleIdBlur}
                onFocus={handleIdFocus}
                sx={{
                  marginBottom: '24px',
                }}
              />
            </div>
            <div>
              <Typography className="title-span">{t`Name`}</Typography>
              <Input
                id="name"
                type="text"
                placeholder={t`Name`}
                autoComplete="current-name"
                variant="outlined"
                margin="none"
                value={name}
                onChange={onChangeName}
                helperText={nameHelperText}
                error={nameError}
                size="small"
                onBlur={handleNameBlur}
                onFocus={handleNameFocus}
                sx={{
                  marginBottom: '24px',
                }}
                fullWidth
              />
            </div>
            <div>
              <Typography className="title-span">Recovery email</Typography>
              <Input
                id="subEmail"
                type="email"
                variant="outlined"
                margin="none"
                value={subEmail}
                helperText={subEmailHelperText}
                error={subEmailError}
                size="small"
                onChange={onChangeSubEmail}
                placeholder="Email"
                onBlur={handleSubEmailBlur}
                onFocus={handleSubEmailFocus}
                sx={{
                  marginBottom: '24px',
                }}
                fullWidth
              />
            </div>
            <div>
              <Typography className="title-span">{t`Organization`}</Typography>
              <TextField
                id="department"
                type="text"
                variant="outlined"
                margin="none"
                size="small"
                value={department}
                helperText={organizationHelperText}
                error={organizationError}
                onChange={onChangeDepartment}
                onBlur={handleOrganizationBlur}
                onFocus={handleOrganizationFocus}
                placeholder={t`Group`}
                sx={{
                  marginBottom: '9px',
                }}
                fullWidth
              />
            </div>
            <div className="input-box-wrap">
              <PasswordTooltip />
              <InputPassword
                id="password"
                type="password"
                autoComplete="current-password"
                variant="outlined"
                value={password}
                helperText={passwordHelperText}
                error={passwordError}
                size="small"
                onChange={onChangePassword}
                placeholder={t`Password`}
                onBlur={handlePasswordBlur}
                onFocus={handlePasswordFocus}
                sx={{
                  marginBottom: '24px',
                }}
                fullWidth
              />
            </div>
            <div>
              <Typography className="title-span">Confirm password</Typography>
              <InputPassword
                id="passwordConfirm"
                type="password"
                placeholder="Confirm password"
                value={passwordConfirm}
                error={passwordConfirmError}
                helperText={passwordConfirmHelptext}
                size="small"
                onChange={handleChangePasswordConfirm}
                onBlur={handlePasswordConfirmBlur}
                onFocus={handlePasswordConfirmFocus}
                fullWidth
                sx={{
                  marginBottom: '24px',
                }}
              />
            </div>
            {/* <div style={{ display: 'none' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={swUpdate}
                    onChange={handleSwUpdateChange}
                    name="swUpdate"
                  />
                }
                label={t`Receive e-mails when updating software`}
                sx={{ marginBottom: '16px' }}
              />
              <div className="sw">{t`Select Software`}</div>
              <FormControl error={swUpdateError} variant="outlined" fullWidth>
                <UpdateInfoForm
                  checkIds={swUpdateArr}
                  disabled={!swUpdate}
                  type={['EXTERN_DISTURB']}
                  className={`check-list-wrap ${swUpdateError ? `error` : ``}`}
                />
                {swUpdateError && (
                  <FormHelperText>{swUpdateHelperText}</FormHelperText>
                )}
              </FormControl>
            </div> */}
            <p>Terms and Conditions</p>
            <TermsForm
              setTerms={setTerms}
              setTermsIds={setTermsIds}
              termsIds={termsIds}
              error={termsError}
              helperText={termsHelperText}
            />
          </form>
        </div>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={onSubmit}
          style={{ marginTop: '15px', height: '46px' }}
        >
          {t`Sign Up`}
        </Button>
      </div>
    </Wrap>
  );
};

export default RegisterForm;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f3f3f3;
  .login-box2 {
    position: relative;
  }
  button {
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 26px;
  }
  .check-list-wrap {
    border: 1px solid #c8c8c8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 19px;
    height: 172px;
    overflow: auto;
    width: 100%;
  }
  .error {
    border-color: #e01919;
  }
  .sw {
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4d4c4c;
    background: #fff;
    width: 110px;
    height: 20px;
    position: absolute;
    transform: translate(0px, -8px);
    text-align: center;
    z-index: 100;
  }
  .Mui-focused {
    color: rgba(0, 0, 0, 0.6);
  }
  .MuiInputBase-inputSizeSmall {
    height: 27px;
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .logo-bottom {
    margin-bottom: 24px;
  }
`;
