import { ChangeEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Input from '../../components/common/Input';
import { validationEmail } from '../../lib/common';
import { customAxios } from '../../lib/customAxios';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import Logo from '../../assets/images/app-logo.svg';
import Typography from '@mui/material/Typography';

interface Porps {
  setCompletion: (isCompletion: boolean) => void;
  setCompletUsername: (email: string) => void;
}

const ResetPasswordEmailForm = ({
  setCompletion,
  setCompletUsername,
}: Porps) => {
  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [usernameHalperText, setUsernameHalperText] = useState<string>('');

  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const validationUsername = (): boolean => {
    if (username === '') {
      setUsernameError(true);
      setUsernameHalperText(t`Please enter an ID.`);
      return false;
    }
    if (!validationEmail(username)) {
      setUsernameError(true);
      setUsernameHalperText(
        'Please enter your email address in the correct format.',
      );
      return false;
    }
    const seegeneRegex = /^[\w-\\/.]+@seegene.com$/;
    if (seegeneRegex.test(username)) {
      setUsernameError(true);
      setUsernameHalperText(
        'Please enter your email address in the correct format.',
      );
      return false;
    }
    setUsernameError(false);
    return true;
  };

  const handleOkClick = () => {
    if (validationUsername()) {
      customAxios
        .put(`/mdx/V1.0/auth/user/reset_password?username=${username}`)
        .then(() => {
          setCompletUsername(username);
          setCompletion(true);
        })
        .catch(() => {
          setUsernameError(true);
          setUsernameHalperText(
            'The email address you entered does not exist.',
          );
        });
    }
  };

  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} alt="Logo" className="login-top-logo" />
          <Typography component="h2">{t`Change Password`}</Typography>
          <Typography component="h4">
            Please enter your email address.
          </Typography>
          <div style={{ marginBottom: '42px' }}>
            <Typography className="title-span">{t`ID`}</Typography>
            <Input
              id="username"
              placeholder="Email"
              value={username}
              onChange={handleChangeUsername}
              error={usernameError}
              helperText={usernameHalperText}
            />
          </div>
          <Button
            variant="contained"
            size="medium"
            fullWidth
            onClick={handleOkClick}
          >
            {t`OK`}
          </Button>
        </div>
      </div>
    </Wrap>
  );
};

export default ResetPasswordEmailForm;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f3f3f3;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 0;
    margin-bottom: 24px;
  }
  .MuiTextField-root {
    width: 100%;
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .logo-bottom {
    margin-bottom: 24px;
  }
`;
