import React, { ChangeEvent, useEffect, useState, MouseEvent } from 'react';
import SearchBox from '../common/SearchBox';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconAdd from '../../assets/images/icon_add.svg';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { getDate } from '../../lib/common';
import Pagination from '@mui/material/Pagination';
import { customAxios } from '../../lib/customAxios';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import Skeleton from '@mui/material/Skeleton';
import { hideLoading } from '../../features/loading/LoadingSlice';
import Grid from '@mui/material/Grid';
import { t } from '@lingui/macro';
import MoreVert from '../../assets/icon/MoreVert';

const Buttons = styled(Button)`
  height: 42px;
`;

const NoticeList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { c, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const loading = useAppSelector(state => state.loading.loading);
  const [rowsPerPage] = useState<number>(50);
  const [noticeDataList, setNoticeDataList] = useState<NoticeInterface[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [moreMenu, setMoreMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const dispatch = useAppDispatch();

  const handleModify = () => {
    navigate(`/main/notice/noticeWrite/${selectedRowId}`);
  };

  const handleDelete = async () => {
    handleMoreMenuClose();
    const result = await dispatch(
      openConfirm({ message: '삭제 하시겠습니까?' }),
    );
    if (result.payload) {
      customAxios
        .delete(`/mdx/V1.0/common/notice/${selectedRowId}`)
        .then(() => getNoticeDataList(page));
    }
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    getNoticeDataList(value);
  };

  const goNoticeInfo = (data: NoticeInterface) => {
    navigate(`/main/notice/noticeInfo/${data.id}`);
  };

  const handleMoreInfo = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    setSelectedRowId(Number(id));
    setMoreMenu(
      moreMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const columns: GridColumns = [
    {
      field: 'subject',
      headerName: t`Title`,
      headerClassName: 'grid-title-app-name',
      flex: 1,
      minWidth: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            color="inherit"
            onClick={() => {
              goNoticeInfo(params.row);
            }}
          >
            <div style={{ padding: '0px 28px' }}>{params.row.subject}</div>
          </Button>
        );
      },
    },
    {
      field: 'name',
      headerName: t`Registrar`,
      flex: 0.5,
      sortable: false,
      minWidth: 150,
    },
    {
      field: 'createdDt',
      headerName: t`Date`,
      sortable: false,
      minWidth: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value ? getDate(params.value.toString()) : '';
      },
    },
    {
      field: 'modify',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconButton-${params.row.id}`}
              onClick={event => {
                handleMoreInfo(event, `${params.row.id}`);
              }}
              id={`notice-modify-${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  useEffect(() => {
    getNoticeDataList(1);
  }, [location.key]);

  const getNoticeDataList = (pageIndex: number) => {
    dispatch(hideLoading());
    customAxios
      .get<ResultType<NoticeInterface>>(
        `/mdx/V1.0/common/notice?page=${
          pageIndex - 1
        }&size=${rowsPerPage}&sort=createdDt,DESC`,
      )
      .then(response => {
        setNoticeDataList(response.data.content);
        setPage(response.data.number + 1);
        if (response.data.totalPages === 0) {
          setTotalPage(undefined);
        } else {
          setTotalPage(response.data.totalPages);
        }
      });
  };

  const getNoticeSearchDataList = (search: string) => {
    if (!search) {
      getNoticeDataList(1);
      return;
    }
    dispatch(hideLoading());
    customAxios
      .get<ResultType<NoticeInterface>>(
        `/mdx/V1.0/common/notice?search=${search}`,
      )
      .then(response => {
        setNoticeDataList(response.data.content);
        setPage(response.data.number + 1);
        if (response.data.totalPages === 0) {
          setTotalPage(undefined);
        } else {
          setTotalPage(response.data.totalPages);
        }
      });
  };

  const onSearchClick = (search: string) => {
    getNoticeSearchDataList(search);
  };

  const onAddNotice = () => {
    navigate(`/main/notice/noticeWrite`);
  };

  const handleMoreMenuClose = () => {
    setMoreMenu(null);
  };

  return (
    <Wrap>
      {loading ? (
        <div>
          <Box
            className="btnGroup"
            sx={{ justifyContent: 'space-between', mb: 2 }}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={400}
              height={45}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={100}
              height={45}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              key="container"
              container
              spacing={{ xs: 2, md: 1 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {Array.from(Array(56)).map((item, i) => {
                const index = i;
                return (
                  <Grid item xs={2} sm={2} md={3} key={`item-${index}`}>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={50}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </div>
      ) : (
        <div>
          <Box
            className="btnGroup"
            sx={{ justifyContent: 'space-between', mb: 2 }}
          >
            <SearchBox
              label={`${t`Title`}, ${t`Content`}`}
              searchClick={onSearchClick}
            />
            {c && (
              <Buttons
                variant="contained"
                onClick={onAddNotice}
                startIcon={<img src={IconAdd} alt="" />}
              >
                {t`Write`}
              </Buttons>
            )}
          </Box>
          <div
            style={{
              height: 'calc(100vh - 250px)',
              width: '100%',
              marginTop: '24px',
            }}
          >
            <DataGrid
              rows={noticeDataList}
              columns={columns}
              disableColumnMenu
              disableColumnFilter
              hideFooterPagination
              hideFooter
            />
          </div>
          {totalPage && totalPage > 1 && (
            <Pagination
              sx={{ position: 'static', bottom: 0, left: 0, right: 0 }}
              count={totalPage}
              page={page}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          )}
          <Menu
            open={moreMenu !== null}
            className="more-menu"
            onClose={handleMoreMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={
              moreMenu !== null
                ? { top: moreMenu.mouseY, left: moreMenu.mouseX }
                : undefined
            }
            PaperProps={{
              style: { width: '154px' },
            }}
          >
            <MenuItem divider onClick={handleModify}>
              {t`Modify`}
            </MenuItem>
            {d && (
              <MenuItem divider onClick={handleDelete}>
                {t`Delete`}
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </Wrap>
  );
};

export default NoticeList;

const Wrap = styled.div`
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
  .MuiDataGrid-cell {
    border-bottom: none;
  }
`;
