import { ChangeEvent, FormEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { userinfoInit } from '../../features/userinfo/UserinfoSlice';
import { customAxios } from '../../lib/customAxios';
import InputPassword from '../common/InputPassword';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { validationPassword } from '../../lib/common';
import Button from '@mui/material/Button';
import Logo from '../../assets/images/app-logo.svg';
import PasswordTooltip from './PasswordTooltip';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

const ResetPasswordForm = () => {
  const dispatch = useAppDispatch();
  const { id, username } = useAppSelector(state => state.userinfo);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordHelperText, setPasswordHelperText] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [passwordConfirmHelptext, setPasswordConfirmHelptext] =
    useState<string>('');
  const [passwordConfirmError, setPasswordConfirmError] =
    useState<boolean>(false);

  const handlePasswordValidation = (paramPassword = password) => {
    if (paramPassword === '') {
      setPasswordHelperText(t`Please enter a password.`);
      setPasswordError(true);
    } else if (
      !validationPassword(paramPassword) ||
      paramPassword === username
    ) {
      setPasswordError(true);
      setPasswordHelperText(t`Please enter according to the password rules.`);
      return false;
    } else {
      setPasswordError(false);
      setPasswordHelperText('');
    }
    if (passwordConfirm !== '' && passwordConfirm !== paramPassword) {
      setPasswordConfirmError(true);
      setPasswordConfirmHelptext(`The passwords don't match. Please re-enter.`);
      return false;
    }
    return true;
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    handlePasswordValidation(event.target.value);
  };

  const handlePasswordConfirmValidation = (
    paramPasswordConfirm = passwordConfirm,
  ) => {
    if (paramPasswordConfirm === '') {
      setPasswordConfirmHelptext(t`Please enter a password.`);
      setPasswordConfirmError(true);
      return false;
    }

    if (password !== paramPasswordConfirm) {
      setPasswordConfirmError(true);
      setPasswordConfirmHelptext(`The passwords don't match. Please re-enter.`);
      return false;
    }

    setPasswordConfirmError(false);
    setPasswordConfirmHelptext('');
    return true;
  };

  const handleChangePasswordConfirm = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setPasswordConfirm(event.target.value);
    handlePasswordConfirmValidation(event.target.value);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const passwordResult = handlePasswordValidation();
    const passwordConfirmResult = handlePasswordConfirmValidation();
    if (passwordResult && passwordConfirmResult) {
      const passwordEncode = encodeURI(password);
      customAxios
        .put(`/mdx/V1.0/setting/user/${id}/pw/${passwordEncode}`)
        .then(() => {
          dispatch(userinfoInit());
        });
    }
  };

  return (
    <Wrap style={{ height: '100vh' }}>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} className="login-top-logo" alt="Logo" />
          <Typography component="h2">{t`Change Password`}</Typography>
          <Typography component="h4">{t`Please change your password.`}</Typography>
          <form onSubmit={onSubmit}>
            <div className="input-box-wrap">
              <PasswordTooltip label="New password" />
              <InputPassword
                autoFocus
                id="password"
                type="password"
                placeholder="password"
                autoComplete="current-password"
                variant="outlined"
                value={password}
                helperText={passwordHelperText}
                error={passwordError}
                size="small"
                onChange={handleChangePassword}
                fullWidth
              />
            </div>
            <div>
              <Typography className="title-span">Confirm password</Typography>
              <InputPassword
                id="passwordConfirm"
                placeholder="Password"
                value={passwordConfirm}
                error={passwordConfirmError}
                helperText={passwordConfirmHelptext}
                onChange={handleChangePasswordConfirm}
              />
            </div>
            <div className="btnGroup" style={{ marginTop: '15px' }}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{ height: '46px' }}
              >
                OK
              </Button>
              <NavLink to="/main/updatemypage">
                <Button
                  variant="outlined"
                  size="large"
                  style={{ marginLeft: '10px', height: '46px' }}
                >
                  Cancel
                </Button>
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </Wrap>
  );
};

export default ResetPasswordForm;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f3f3f3;
  .login-box {
    padding: 32px !important;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  .MuiTextField-root {
    width: 100%;
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .input-box-wrap {
    position: relative;
    margin-bottom: 24px;
  }
`;
