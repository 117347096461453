import { useState, ChangeEvent, useEffect } from 'react';
import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import { customAxios } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import styled from 'styled-components';
import Input from '../common/Input';
import Typography from '@mui/material/Typography';

interface Props {
  setOpen: (open: boolean) => void;
  getRole: () => void;
  data?: RoleInterface;
}

const RoleRegisterForm = ({ setOpen, getRole, data }: Props) => {
  const dispatch = useAppDispatch();
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const [name, setName] = useState('');
  const [nameHelptext, setNameHelptext] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [descr, setDescr] = useState('');
  const [descrHelptext, setDescrHelptext] = useState<string>('');
  const [descrError, setDescrError] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescr(data.descr);
    }
  }, [data]);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validationName = (value?: string): boolean => {
    const tempValue = value || name;
    if (tempValue === '') {
      setNameError(true);
      setNameHelptext(t`Please enter a role name.`);
      return false;
    }
    setNameError(false);
    setNameHelptext('');
    return true;
  };

  const onChangeDescr = (e: ChangeEvent<HTMLInputElement>) => {
    setDescr(e.target.value);
  };

  const validationDescr = (value?: string): boolean => {
    const tempValue = value || descr;
    if (tempValue === '') {
      setDescrError(true);
      setDescrHelptext(t`Please enter a role description.`);
      return false;
    }
    setDescrError(false);
    setDescrHelptext('');
    return true;
  };

  const onSave = () => {
    const namev = validationName();
    const descrv = validationDescr();
    if (namev && descrv) {
      if (!data && c) {
        customAxios
          .post('/mdx/V1.0/setting/role', {
            name,
            descr,
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: t`Added role.` }),
            );
            if (result.payload) {
              setOpen(false);
              getRole();
            }
          });
      } else if (data && u) {
        customAxios
          .put(`/mdx/V1.0/setting/role/${data.id}`, {
            name,
            descr,
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: t`Modified role.` }),
            );
            if (result.payload) {
              setOpen(false);
              getRole();
            }
          });
      }
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  const handleNameFocus = () => {
    setNameError(false);
    setNameHelptext('');
  };

  const handleNameBlur = () => {
    validationName();
  };

  const handleDescrFocus = () => {
    setDescrError(false);
    setDescrHelptext('');
  };

  const handleDescrBlur = () => {
    validationDescr();
  };

  return (
    <Wrap>
      <div className="row">
        <Typography className="title-span">{t`Role Name`}</Typography>
        <Input
          id="name"
          placeholder={t`Role Name`}
          variant="outlined"
          value={name}
          error={nameError}
          helperText={nameHelptext}
          onChange={onChangeName}
          onFocus={handleNameFocus}
          onBlur={handleNameBlur}
          inputProps={{ maxLength: 45 }}
          autoFocus
          fullWidth
        />
      </div>
      <div className="row">
        <Typography className="title-span">{t`Role Description`}</Typography>
        <Input
          id="descr"
          placeholder={t`Role Description`}
          multiline
          rows={4}
          value={descr}
          error={descrError}
          helperText={descrHelptext}
          inputProps={{ maxLength: 60 }}
          onChange={onChangeDescr}
          onFocus={handleDescrFocus}
          onBlur={handleDescrBlur}
          fullWidth
        />
      </div>
      <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
        {(c || u) && (
          <Button variant="contained" size="large" onClick={onSave}>
            {data ? t`Modify` : t`Add`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={onCancel}>
          {t`Cancel`}
        </Button>
      </Box>
    </Wrap>
  );
};

export default RoleRegisterForm;

const Wrap = styled.div`
  .row {
    margin-bottom: 24px;
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
`;
