import ResetPasswordForm from '../../components/auth/ResetPasswordForm';

const ResetPasswordScreen = () => {
  return (
    <div>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPasswordScreen;
