const ArrowDown = () => {
  return (
    <svg viewBox="0 0 38 38">
      <path
        d="M0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19C38 29.4934 29.4934 38 19 38C8.50659 38 0 29.4934 0 19Z"
        fill="#C8C8C8"
      />
      <path
        d="M11.7325 13.6008L19 20.8525L26.2675 13.6008L28.5 15.8333L19 25.3333L9.5 15.8333L11.7325 13.6008Z"
        fill="#908E8E"
      />
    </svg>
  );
};

export default ArrowDown;
