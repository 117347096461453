import { useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import {
  IntroScreen,
  LoginScreen,
  ResetpasswordScreen,
  AppScreen,
  RegisterScreen,
  UserScreen,
  GroupScreen,
  RoleScreen,
  MenuScreen,
  NotFound,
  MypageScreen,
  CategoryScreen,
  EmailVerificationScreen,
  WaitingScreen,
  SsoScreen,
  SwPasswordScreen,
  NoticeScreen,
  CommLoginScreen,
  ResetPassowrdEmailScreen,
  SearchIdScreen,
  MoreInfoScreen,
  Gui,
  SettingScreen,
  InternAppScreen,
  TermsScreen,
  ServerError,
  TermsViewScreen,
  SubsRequestedScreen,
  MypageModifyScreen,
} from './pages';
import EmptyLayout from './layouts/EmptyLayout';
import MainLayout from './layouts/MainLayout';
import { useAppSelector } from './app/hooks';

const Routers = () => {
  const location = useLocation();
  const { id, userStatus, login } = useAppSelector(state => state.userinfo);

  // useEffect(() => {
  //   if (id < 0) {
  //     if (location.pathname.includes('/main/')) {
  //       localStorage.setItem('page', location.pathname + location.search);
  //     }
  //   }
  // }, []);

  return useRoutes([
    {
      path: '/main',
      element:
        userStatus === 'SUBS_APPROVED' ? (
          <MainLayout />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { element: <Navigate to="/main/index" replace />, index: true },
        { path: 'index', element: <IntroScreen /> },
        { path: 'mypage', element: <MypageScreen /> },
        { path: 'updatemypage', element: <MypageModifyScreen /> },
        {
          path: 'notice',
          element: (
            <RequireMyMenu>
              <NoticeScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'notice/:submenu',
          element: (
            <RequireMyMenu>
              <NoticeScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'notice/:submenu/:noticeId',
          element: (
            <RequireMyMenu>
              <NoticeScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'setting',
          element: (
            <RequireMyMenu>
              <SettingScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'extapp',
          element: (
            <RequireMyMenu>
              <AppScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'extapp/:submenu',
          element: (
            <RequireMyMenu>
              <AppScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'extapp/:submenu/:appId',
          element: (
            <RequireMyMenu>
              <AppScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'extapp/:submenu/:appId/:versionId',
          element: (
            <RequireMyMenu>
              <AppScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'intapp',
          element: (
            <RequireMyMenu>
              <InternAppScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'intapp/:submenu',
          element: (
            <RequireMyMenu>
              <InternAppScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'intapp/:submenu/:appId',
          element: (
            <RequireMyMenu>
              <InternAppScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'intapp/:submenu/:appId/:versionId',
          element: (
            <RequireMyMenu>
              <InternAppScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'masterkey',
          element: (
            <RequireMyMenu>
              <SwPasswordScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'user',
          element: (
            <RequireMyMenu>
              <UserScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'group',
          element: (
            <RequireMyMenu>
              <GroupScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'role',
          element: (
            <RequireMyMenu>
              <RoleScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'menu',
          element: (
            <RequireMyMenu>
              <MenuScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'category',
          element: (
            <RequireMyMenu>
              <CategoryScreen />
            </RequireMyMenu>
          ),
        },
        {
          path: 'terms',
          element: (
            <RequireMyMenu>
              <TermsScreen />
            </RequireMyMenu>
          ),
        },
      ],
    },
    {
      path: '/auth',
      element: <EmptyLayout />,
      children: [
        {
          path: 'subsRequested',
          element:
            userStatus === 'SUBS_REQUESTED' ? (
              <SubsRequestedScreen />
            ) : (
              <Navigate to="/login" />
            ),
        },
        {
          path: 'waiting',
          element:
            userStatus === 'EMAIL_VERIFIED' ? (
              <WaitingScreen />
            ) : (
              <Navigate to="/login" />
            ),
        },
        {
          path: 'moreinfo',
          element:
            userStatus === 'MORE_INFO_REQUIRED' ? (
              <MoreInfoScreen />
            ) : (
              <Navigate to="/login" />
            ),
        },
        {
          path: 'resetpassword',
          element: id > 0 ? <ResetpasswordScreen /> : <Navigate to="/login" />,
        },
      ],
    },
    {
      path: '/',
      element: <EmptyLayout />,
      children: [
        { element: <Navigate to="/main/index" replace />, index: true },
        {
          path: 'emailVerification/:confirmToken',
          element: <EmailVerificationScreen />,
        },
        { path: 'gui', element: <Gui /> },
        { path: 'login', element: <LoginScreen /> },
        { path: 'ad', element: <SsoScreen /> },
        { path: 'register', element: <RegisterScreen /> },
        { path: 'password', element: <ResetPassowrdEmailScreen /> },
        { path: 'searchid', element: <SearchIdScreen /> },
        {
          path: '404',
          element: <NotFound />,
        },
        {
          path: '500',
          element: <ServerError />,
        },
        { path: 'terms/:lang/:submenu', element: <TermsViewScreen /> },
        // { path: '/', element: <Navigate to={`/main${location.search}`} /> },
        // {
        //   path: '*',
        //   element:
        //     id > -1 ? (
        //       <Navigate to="/main/masterkey" />
        //     ) : (
        //       <Navigate to="/login" />
        //     ),
        // },
      ],
    },
    {
      path: '*',
      element: id > -1 ? <Navigate to="/404" /> : <Navigate to="/login" />,
    },
  ]);
};

const RequireMyMenu = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { menu } = useAppSelector(state => state.mymenu);

  if (
    !menu.find(item => {
      return item.navigateUrl.includes(location.pathname.split('/')[2]);
    })
  ) {
    return <Navigate to="/404" state={{ from: location }} replace />;
  }
  return children;
};

export default Routers;
