import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from '@lingui/macro';

const initialState: LoginInterface = {
  id: -1,
  username: '',
  name: '',
  error: '',
  userStatus: '',
  token: '',
  department: '',
  login: false,
  lang: 'en',
};

export const UserinfoSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    logout: state => {
      state.id = -1;
      state.username = '';
      state.name = '';
      state.error = '';
      state.userStatus = '';
      state.token = '';
      state.department = '';
      state.login = true;
      state.lang = '';
      sessionStorage.removeItem('page');
    },
    userinfoInit: state => {
      state.id = -1;
      state.username = '';
      state.name = '';
      state.error = '';
      state.userStatus = '';
      state.token = '';
      state.department = '';
      state.login = false;
      state.lang = sessionStorage.getItem('lang') || 'en';
    },
    loginRequest: (state, action: PayloadAction<string>) => {
      state.id = -1;
      state.username = action.payload;
      state.name = '';
      state.error = '';
      state.userStatus = '';
      state.token = '';
      state.department = '';
      state.login = false;
      state.lang = '';
    },
    setUserinfo: (state, action: PayloadAction<LoginInterface>) => {
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.error = action.payload.error;
      state.userStatus = action.payload.userStatus;
      if (action.payload.status) state.userStatus = action.payload.status;
      if (action.payload.token) state.token = action.payload.token;
      state.department = action.payload.department;
      state.login = false;
      state.lang = action.payload.lang;
    },
    userFailure: {
      reducer(
        state,
        action: PayloadAction<{
          status: number;
          userStatus?: string;
          message: string;
          id?: number;
          username?: string;
        }>,
      ) {
        if (action.payload.id) {
          state.id = action.payload.id;
        } else {
          state.id = -1;
        }
        state.username = action.payload.username || '';
        state.name = '';
        state.userStatus = '';
        state.department = '';
        state.login = false;
        if (action.payload.status === 401 || action.payload.status === 400) {
          state.error = t`You entered your ID or password incorrectly. Please re-enter them.`;
        } else if (
          action.payload.status === 403 &&
          action.payload.userStatus === 'SUBS_REQUESTED'
        ) {
          state.userStatus = action.payload.userStatus;
          state.username = action.payload.username || '';
        } else if (
          action.payload.status === 403 &&
          action.payload.userStatus &&
          ['MORE_INFO_REQUIRED', 'EMAIL_VERIFIED', 'MORE_INFO_TERMS'].includes(
            action.payload.userStatus,
          )
        ) {
          state.userStatus = action.payload.userStatus;
        } else if (
          action.payload.status === 403 &&
          action.payload.userStatus === 'RESET_PW_EXPIRED'
        ) {
          state.error =
            'Temporary password is no longer valid. Please re-issue the password.';
          state.userStatus = action.payload.userStatus;
        } else if (action.payload.message) {
          state.error = action.payload.message;
        }
      },
      prepare(
        status: number,
        message: string,
        userStatus?: string,
        id?: number,
        username?: string,
      ) {
        return {
          payload: {
            status,
            message,
            userStatus,
            id,
            username,
          },
        };
      },
    },
    refreshtokenSuccess: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setLang: (state, action: PayloadAction<string>) => {
      sessionStorage.setItem('lang', action.payload);
      state.lang = action.payload;
    },
  },
});

export const {
  logout,
  userinfoInit,
  loginRequest,
  setUserinfo,
  userFailure,
  refreshtokenSuccess,
  setLang,
} = UserinfoSlice.actions;
export default UserinfoSlice.reducer;
