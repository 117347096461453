/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-undef */
import SeegeneMasterGen from './wasm/master_pw.wasm';
import { Buffer } from 'buffer';

// WebAssembly Instanace
let masterPwGenInstance = null;
// WebAssembly Buffer
let masterPwGenMemory = null;

/**
 * Initializing WebAssembly
 * @returns {boolean} Return pass(true)/fail(false)
 */
export const loadMasterPwGen = async () => {
  const importObject = {
    wasi_snapshot_preview1: {
      proc_exit: () => {},
    },
  };

  const wasm = await WebAssembly.instantiateStreaming(
    fetch(SeegeneMasterGen),
    importObject,
  );

  const instance = wasm.instance.exports;

  if (instance === null || instance === undefined) return false;

  masterPwGenInstance = instance;
  masterPwGenMemory = instance.memory;

  return true;
};

/**
 * Create Daily password of Seegene Master
 * - Time seed: UTC Time based time-seed (YYYY-MM-DD)
 * - Since the UTC time is used, the password is initialized at 9 a.m. in Korean time.
 * - If you want to get a password the next day(after 9 a.m.), you can get it by adding a date to the time seed.
 * @returns {Buffer} Daily password string
 */
export const generateMasterPwOdd = () => {
  const masterPw = masterPwGenInstance.create_buffer(10);
  const pwLength = masterPwGenInstance.create_buffer(4);
  const date = new Date();
  const dateSeed =
    Date.parse(
      `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`,
    ) / 100000;

  //  Get Next Day password
  //   const dateSeedNextDay =
  //     Date.parse(
  //       `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${
  //         date.getUTCDate() + 1
  //       }`,
  //     ) / 100000;

  masterPwGenInstance.seegene_master_key(masterPw, pwLength, dateSeed);
  const outputLength = new Uint32Array(masterPwGenMemory.buffer, pwLength, 1);
  const output = new Uint8Array(
    masterPwGenMemory.buffer,
    masterPw,
    outputLength[0] / 2,
  );
  const bufferStr = Buffer.from(output).toString('hex');

  masterPwGenInstance.free_buffer(masterPw);

  return bufferStr;
};

export const generateMasterPw = () => {
  const masterPw = masterPwGenInstance.create_buffer(10);
  const pwLength = masterPwGenInstance.create_buffer(4);
  const currentDate = new Date();
  const seedTime = new Date();
  seedTime.setUTCFullYear(currentDate.getUTCFullYear());
  seedTime.setUTCMonth(currentDate.getUTCMonth());
  seedTime.setUTCDate(currentDate.getUTCDate());
  seedTime.setUTCHours(0);
  seedTime.setUTCMinutes(0);
  seedTime.setUTCSeconds(0);
  seedTime.setUTCMilliseconds(0);

  const dateSeed = Date.parse(seedTime) / 100000;

  masterPwGenInstance.seegene_master_key(masterPw, pwLength, dateSeed);
  const outputLength = new Uint32Array(masterPwGenMemory.buffer, pwLength, 1);
  const output = new Uint8Array(
    masterPwGenMemory.buffer,
    masterPw,
    outputLength[0] / 2,
  );
  const bufferStr = Buffer.from(output).toString('hex');

  masterPwGenInstance.free_buffer(masterPw);

  return bufferStr;
};