import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const IntroScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/main/masterkey');
  }, []);

  return <div>Intro</div>;
};

export default IntroScreen;
