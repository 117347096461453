import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import Logo from '../../assets/images/app-logo.svg';
import Typography from '@mui/material/Typography';

const RegisterCompletion = () => {
  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} className="login-top-logo" alt="" />
          <Typography component="h2">Complete Signup</Typography>
          <Typography component="h4">
            You have completed the signup process. Thank you for joining our
            service. Before you can begin using our service, please verify the
            email address you provided to sign up.
          </Typography>
          <NavLink to="/login">
            <Button variant="contained" size="large" fullWidth>
              Next
            </Button>
          </NavLink>
        </div>
      </div>
    </Wrap>
  );
};

export default RegisterCompletion;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f3f3f3;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 15px 0px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 15px 0px;
  }
  a {
    text-decoration: none !important;
  }
`;
