import { ChangeEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Input from '../common/Input';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { validationEmail } from '../../lib/common';
import { customAxios } from '../../lib/customAxios';
import Logo from '../../assets/images/app-logo.svg';
import Typography from '@mui/material/Typography';

interface Props {
  setComfirmEmail: (email: string) => void;
}

const SearchIdForm = ({ setComfirmEmail }: Props) => {
  const [subEmail, setSubEmail] = useState<string>('');
  const [subEmailHelperText, setSubEmailHelperText] = useState<string>('');
  const [subEmailError, setSubEmailError] = useState<boolean>(false);
  const handleChangeSubEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setSubEmail(e.target.value);
  };

  const validationEmailTextField = (): boolean => {
    if (subEmail === '') {
      setSubEmailHelperText(`Please enter an email address.`);
      setSubEmailError(true);
      return false;
    }
    if (!validationEmail(subEmail)) {
      setSubEmailHelperText(
        'Please enter your email address in the correct format.',
      );
      setSubEmailError(true);
      return false;
    }
    const seegeneRegex = /^[\w-\\/.]+@seegene.com$/;
    if (seegeneRegex.test(subEmail)) {
      setSubEmailHelperText(
        `Please enter your email address in the correct format.`,
      );
      setSubEmailError(true);
      return false;
    }
    setSubEmailError(false);
    return true;
  };

  const handleSubEmailBlur = () => {
    validationEmailTextField();
  };

  const handleSubEmailFocus = () => {
    setSubEmailError(false);
    setSubEmailHelperText('');
  };

  const handleClick = () => {
    // setComfirmEmail(subEmail);
    if (validationEmailTextField()) {
      customAxios
        .get(`/mdx/V1.0/auth/user/findUsername?subEmail=${subEmail}`)
        .then(response => {
          if (response.data === 'Not Find') {
            setSubEmailError(true);
            setSubEmailHelperText(
              'The email address you entered does not exist.',
            );
          } else {
            setComfirmEmail(response.data);
          }
        });
    }
  };

  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} className="login-top-logo" alt="Logo" />
          <Typography component="h2">Find Account</Typography>
          <Typography component="h4">
            Please enter your recovery email address.
          </Typography>
          <div style={{ marginBottom: '42px' }}>
            <Typography className="title-span">Recovery email</Typography>
            <Input
              id="email"
              type="email"
              placeholder="Email"
              value={subEmail}
              error={subEmailError}
              helperText={subEmailHelperText}
              onChange={handleChangeSubEmail}
              onBlur={handleSubEmailBlur}
              onFocus={handleSubEmailFocus}
            />
          </div>
          <Button
            variant="contained"
            size="medium"
            onClick={handleClick}
            fullWidth
          >
            {t`OK`}
          </Button>
        </div>
      </div>
    </Wrap>
  );
};

export default SearchIdForm;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f3f3f3;
  a {
    text-decoration: none !important;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 0;
    margin-bottom: 24px;
  }
  .MuiTextField-root {
    width: 100%;
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .logo-bottom {
    margin-bottom: 24px;
  }
`;
