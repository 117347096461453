/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useRef } from 'react';
import { Tree, NodeModel, TreeMethods } from '@minoru/react-dnd-treeview';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { customAxios } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TreeCustomNode from '../../components/common/TreeCustomNode';
import TreeCustomPreview from '../../components/common/TreeCustomPreview';
import ModalPopup from '../../components/common/ModalPopup';
import MenuRegisterForm from '../../components/admin/MenuRegisterForm';
import IconAdd from '../../assets/images/icon_add.svg';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Placeholder from '../../components/common/Placeholder';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import { t } from '@lingui/macro';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import { useLocation } from 'react-router-dom';

const MenuScreen = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { c, r, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const [menuPopup, setMenuPopup] = useState<boolean>(false);
  const [menu, setMenu] = useState<MenuDataInterface[]>([]);
  const [lastOrder, setLastOrder] = useState<number>(0);
  const [treeData, setTreeData] = useState<NodeModel<MenuDataInterface>[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<
    MenuDataInterface | undefined
  >(undefined);
  const [selectedParentMenu, setSelectedParentMenu] = useState<
    MenuDataInterface | undefined
  >(undefined);
  const [popupTitle, setPopupTitle] = useState<string>('');
  const ref = useRef<TreeMethods>(null);

  useEffect(() => {
    getMenu();
  }, [r, location.key]);

  const handleDrop = (newTree: NodeModel<MenuDataInterface>[]) => {
    const tree = newTree.map(node => {
      let { data } = node;
      if (data) {
        data = {
          ...data,
          isParent: newTree.filter(temp => temp.parent === node.id).length > 0,
        };
      }
      return {
        ...node,
        data,
      };
    });

    const reqData = tree.map((node, index) => {
      if (node.data) {
        return {
          id: node.id,
          name: node.text,
          parentId: node.parent,
          descr: node.data.descr,
          navigateUrl: node.data.navigateUrl,
          menuOrder: index + 1,
          menuIconSvg: node.data.menuIconSvg,
          selectedMenuIconSvg: node.data.selectedMenuIconSvg,
        };
      }
      return {};
    });

    customAxios.post(`/mdx/V1.0/setting/menus`, reqData).then(() => {
      getMenu();
    });
    setTreeData(tree);
  };

  const onAddMenuTop = () => {
    setPopupTitle(t`Add Menu`);
    setSelectedMenu(undefined);
    setSelectedParentMenu(menu.find(n => n.id === 1));
    setLastOrder(getLastOrder(1));
    setMenuPopup(true);
  };

  const getMenu = () => {
    if (r) {
      customAxios
        .get<ResultType<MenuDataInterface>>(
          '/mdx/V1.0/setting/menu?sort=menuOrder',
        )
        .then(response => {
          setMenu(response.data.content);
          const data = response.data.content
            .filter((node: MenuDataInterface) => node.parentId !== null)
            .map((node: MenuDataInterface) => {
              return {
                id: node.id,
                parent: node.parentId,
                text: node.name,
                droppable: true,
                data: {
                  ...node,
                  isParent:
                    response.data.content.filter(
                      (temp: MenuDataInterface) => temp.parentId === node.id,
                    ).length > 0,
                },
              };
            });
          setTreeData(data);
          ref.current?.openAll();
        });
    }
  };

  const addTreeNode = (id: NodeModel['id']) => {
    setPopupTitle(t`Add Menu`);
    setSelectedMenu(undefined);
    setSelectedParentMenu(menu.find(n => n.id === id));
    setLastOrder(getLastOrder(id));
    setMenuPopup(true);
  };

  const getLastOrder = (id: NodeModel['id']): number => {
    let lastO = 0;
    menu
      .filter(n => n.parentId === id)
      .forEach(n => {
        lastO = n.menuOrder > lastO ? n.menuOrder : lastO;
      });
    return lastO + 1;
  };

  const deleteTreeNode = async (id: NodeModel['id']) => {
    if (d) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the menu?` }),
      );
      if (result.payload) {
        customAxios.delete(`/mdx/V1.0/setting/menu/${id}`).then(() => {
          getMenu();
          dispatch(openAlert({ message: t`Deleted menu.` }));
        });
      }
    }
  };

  const modifyTreeNod = (id: NodeModel['id']) => {
    setPopupTitle(t`Modify Menu`);
    setSelectedMenu(menu.find(n => n.id === id));
    setSelectedParentMenu(undefined);
    setMenuPopup(true);
  };

  return (
    <Wrap>
      <Box className="btnGroup" sx={{ '& button': { ml: 1 } }}>
        {c && (
          <Button
            variant="contained"
            size="small"
            onClick={onAddMenuTop}
            startIcon={<img src={IconAdd} alt="" />}
          >
            {t`Add Menu`}
          </Button>
        )}
      </Box>
      <Stack direction="row" spacing={2}>
        <div style={{ width: '100%' }}>
          <div className="tree">
            <Tree
              tree={treeData}
              ref={ref}
              classes={{
                draggingSource: 'draggingSource',
                dropTarget: 'dropTarget',
                placeholder: 'placeholderContainer',
                container: 'tree-ul',
                root: 'tree-root',
                listItem: 'tree-li',
              }}
              rootId={1}
              render={(node: NodeModel<MenuDataInterface>, options) => (
                <TreeCustomNode
                  node={node}
                  {...options}
                  onDelete={deleteTreeNode}
                  onAdd={addTreeNode}
                  onModify={modifyTreeNod}
                />
              )}
              dragPreviewRender={monitorProps => (
                <TreeCustomPreview monitorProps={monitorProps} />
              )}
              canDrop={(tree, { dragSource, dropTargetId }) => {
                if (dragSource?.parent === dropTargetId && u) {
                  return true;
                }
              }}
              placeholderRender={(node, { depth }) => (
                <Placeholder node={node} depth={depth} />
              )}
              onDrop={handleDrop}
              sort={false}
              insertDroppableFirst={false}
              dropTargetOffset={10}
            />
          </div>
        </div>
      </Stack>
      <ModalPopup open={menuPopup} title={popupTitle} popupWidth={544}>
        <MenuRegisterForm
          setOpen={setMenuPopup}
          getMenu={getMenu}
          data={selectedMenu}
          parentData={selectedParentMenu}
          order={lastOrder}
        />
      </ModalPopup>
    </Wrap>
  );
};

export default MenuScreen;

const Wrap = styled.div`
  .draggingSource {
    opacity: 0.3;
  }
  .placeholderContainer {
    position: relative;
  }
`;
