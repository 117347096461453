/* eslint-disable camelcase */
import { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import GroupRegisterForm from '../../components/admin/GroupRegisterForm';
import ModalPopup from '../../components/common/ModalPopup';
import { AxiosError, customAxios } from '../../lib/customAxios';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import {
  GridColumns,
  GridRenderCellParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { getDate } from '../../lib/common';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconAdd from '../../assets/images/icon_add.svg';
import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';
import { openConfirm } from '../../features/confirmModal/ConfirmModalSlice';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import { useLocation } from 'react-router-dom';
import MoreVert from '../../assets/icon/MoreVert';

const GroupScreen = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { c, r, u, d } = useAppSelector(state => state.mymenu.currentMenu);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined);
  const [rowsPerPage] = useState<number>(20);
  const [group, setGroup] = useState<GroupInterface[]>([]);
  const [groupPopup, setGroupPopup] = useState(false);
  const [popuptitle, setPopupTitle] = useState<string>('');
  const [selectedData, setSelectedData] = useState<
    GroupInterface | undefined
  >();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [moreMenu, setMoreMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  useEffect(() => {
    getGroup(1);
  }, [r, location.key]);

  const getGroup = (pageIndex: number) => {
    if (r) {
      setPage(pageIndex);
      customAxios
        .get<ResultType<GroupInterface>>(
          `/mdx/V1.0/setting/group?page=${pageIndex - 1}&size=${rowsPerPage}`,
        )
        .then(response => {
          setGroup(response.data.content);
          if (response.data.totalPages === 0) {
            setTotalPage(undefined);
          } else {
            setTotalPage(response.data.totalPages);
          }
        });
    }
  };

  const onAdd = () => {
    setSelectedData(undefined);
    setPopupTitle(t`Add Group`);
    setGroupPopup(true);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) =>
    getGroup(value);

  const handleMoreInfo = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    setSelectedRowId(Number(id));
    setMoreMenu(
      moreMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleMoreMenuClose = () => {
    setMoreMenu(null);
  };

  const handleGroupModify = () => {
    handleMoreMenuClose();
    setSelectedData(group.find(item => item.id === selectedRowId));
    setGroupPopup(true);
    setPopupTitle(t`Modify Group`);
  };

  const handleUserDelete = async () => {
    handleMoreMenuClose();
    if (d) {
      const result = await dispatch(
        openConfirm({ message: t`Are you sure to delete the group?` }),
      );
      if (result.payload) {
        customAxios
          .delete(`/mdx/V1.0/setting/group/${selectedRowId}`)
          .then(() => {
            getGroup(page);
            dispatch(openAlert({ message: t`Deleted group.` }));
          })
          .catch((ex: AxiosError<ErrorType>) => {
            if (ex.response?.status === 405) {
              dispatch(
                openAlert({
                  message: `Role Mapping 데이터 삭제 후 다시 시도하세요.`,
                }),
              );
            }
          });
      }
    }
  };

  const groupCol: GridColumns = [
    { field: 'name', headerName: t`Group`, flex: 0.5, sortable: false },
    {
      field: 'updatedDt',
      headerName: t`Date`,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<string>) => {
        return getDate(params.value || '');
      },
      sortable: false,
    },
    {
      field: 'modify',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        return u || d ? (
          <div>
            <IconButton
              key={`IconButton-${params.row.id}`}
              onClick={event => {
                handleMoreInfo(event, `${params.row.id}`);
              }}
              id={`app-modify-${params.row.id}`}
            >
              <MoreVert />
            </IconButton>
          </div>
        ) : (
          <div />
        );
      },
    },
  ];

  return (
    <Wrap>
      <div>
        <Box className="btnGroup" sx={{ '& button': { ml: 1, mb: 0 } }}>
          {c && (
            <Button
              variant="contained"
              size="small"
              onClick={onAdd}
              startIcon={<img src={IconAdd} alt="" />}
            >
              {t`Add Group`}
            </Button>
          )}
          {d && selectionModel.length > 0 && (
            <Button variant="contained" size="small">
              {t`Delete Group`}
            </Button>
          )}
        </Box>
        <div
          style={{
            height: 'calc(100vh - 210px)',
            minHeight: '300px',
            width: '100%',
            marginTop: '24px',
          }}
        >
          <DataGrid
            disableColumnMenu
            disableColumnFilter
            rows={group}
            columns={groupCol}
            rowHeight={44}
            disableSelectionOnClick
            hideFooterPagination
            hideFooter
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel);
            }}
          />
        </div>
        {totalPage && totalPage > 1 && (
          <Pagination
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
        )}
        <Menu
          open={moreMenu !== null}
          className="more-menu"
          onClose={handleMoreMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            moreMenu !== null
              ? { top: moreMenu.mouseY, left: moreMenu.mouseX }
              : undefined
          }
          PaperProps={{
            style: { width: '154px' },
          }}
        >
          <MenuItem divider onClick={handleGroupModify}>
            {t`Modify`}
          </MenuItem>
          {d && <MenuItem onClick={handleUserDelete}>{t`Delete`}</MenuItem>}
        </Menu>
      </div>
      <ModalPopup open={groupPopup} title={popuptitle} popupWidth={470}>
        <GroupRegisterForm
          setOpen={setGroupPopup}
          getGroup={getGroup}
          data={selectedData}
          page={page}
        />
      </ModalPopup>
    </Wrap>
  );
};

export default GroupScreen;

const Wrap = styled.div`
  .check-list-wrap {
    border: 1px solid #c8c8c8;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 19px;
    height: 172px;
    overflow: auto;
  }
  .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root {
    display: none;
  }
  .MuiDataGrid-cell {
    border-bottom: none;
  }
`;
