/* eslint-disable react/jsx-props-no-spreading */
import { useState, ChangeEvent, useEffect, forwardRef } from 'react';
import moment from 'moment';
import { enUS, ko } from 'date-fns/locale';
import { t } from '@lingui/macro';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { customAxios } from '../../lib/customAxios';
import FileUpload from '../common/FileUpload';
import { formatVersion } from '../../lib/common';
import Button from '@mui/material/Button';
import ModalPopup from '../common/ModalPopup';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import SwAppVersionInstallFile from './SwAppVersionInstallFile';
import Editor from '../common/Editor';
import { i18n } from '@lingui/core';
import Input from '../common/Input';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import NumberFormat, { InputAttributes } from 'react-number-format';

interface Props {
  swId: string;
  swVersion?: SwVersionInterface;
  setOpen: (isOpen: boolean) => void;
  getSwVersionList: (pageIndex: number) => void;
  page: number;
}

const localeMap: { [key: string]: Locale } = {
  en: enUS,
  ko,
};

const maskMap: { [key: string]: string } = {
  ko: '____.__.__',
  en: '__/__/____',
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const VersionFormatCustom = forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function VersionFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { name: props.name, value: values.value } });
      }}
      format="#.##.###"
      mask="_"
      prefix=""
    />
  );
});

const SWAppVersionAddModify = ({
  swId,
  swVersion,
  setOpen,
  getSwVersionList,
  page,
}: Props) => {
  const dispatch = useAppDispatch();
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const [statusItems, setStatusItems] = useState<ItemInterface[]>([]);
  const [swAppVersionInstallPopup, setSwAppVersionInstallPopup] =
    useState<boolean>(false);
  const [versionId, setVersionId] = useState<number | undefined>(undefined);
  const [version, setVersion] = useState<string>('');
  const [versionError, setVersionError] = useState<boolean>(false);
  const [versionHelpText, setVersionHelpText] = useState<string>('');
  const [releaseNote, setReleaseNote] = useState<string>('');
  const [releaseNoteError, setReleaseNoteError] = useState<boolean>(false);
  const [releaseNoteHelpText, setReleaseNoteHelpText] = useState<string>('');
  const [releaseDate, setReleaseDate] = useState<Date | null>(new Date());
  const [releaseDateHelpText, setReleaseDateHelpText] = useState<string>('');
  const [releaseDateError, setReleaseDateError] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [statusError, setStatusError] = useState<boolean>();
  const [statusHelpText, setStatusHelpText] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [fileName, setFileName] = useState<FileInterface[]>([]);
  const [deleteFileIds, setDeleteFileIds] = useState<number[]>([]);
  const [swObjectKeys, setSwObjectKeys] = useState<string[]>([]);
  const [loadFile, setLoadFile] = useState<number>(0);

  const FILE_MAX_SIZE: number = parseInt(
    `${process.env.REACT_APP_MAX_FILE_SIZE}`,
    10,
  );
  let fileArr: File[] = [];

  let num = 0;
  const maxsize = 5;

  useEffect(() => {
    customAxios
      .get<ItemInterface[]>(`/mdx/V1.0/setting/category/cgCd/SW004/item`)
      .then(res => {
        setStatusItems(res.data);
      });
  }, []);

  useEffect(() => {
    if (swVersion) {
      setVersion(formatVersion(`${swVersion.version}`));
      setReleaseNote(swVersion.releaseNote);
      setReleaseDate(new Date(swVersion.releaseDt));
      setStatus(swVersion.status);
      setVersionId(swVersion.id);
      if (
        swVersion.swVersionFileList &&
        swVersion.swVersionFileList?.length > 0
      )
        setFileName(swVersion.swVersionFileList);
    }
  }, [swVersion]);

  const onChangeVersion = (e: ChangeEvent<HTMLInputElement>) =>
    setVersion(e.target.value);

  const onChangeReleaseNote = (contents: string) => setReleaseNote(contents);

  const validationVersion = () => {
    if (version === '') {
      setVersionError(true);
      setVersionHelpText('버전을 입력하세요.');
      return false;
    }
    setVersionError(false);
    setVersionHelpText('');
    return true;
  };

  const validationReleaseDate = () => {
    if (releaseDate === null) {
      setReleaseDateError(true);
      setReleaseDateHelpText('배포일을 입력하세요.');
      return false;
    }
    setReleaseDateError(false);
    setReleaseDateHelpText('');
    return true;
  };

  const validationStatus = () => {
    if (status === '') {
      setStatusError(true);
      setStatusHelpText('상태를 선택하세요.');
      return false;
    }
    setStatusError(false);
    setStatusHelpText('');
    return true;
  };

  const validationReleaseNote = () => {
    if (releaseNote === '' || releaseNote.length === 0) {
      setReleaseNoteError(true);
      setReleaseNoteHelpText('릴리즈 노트를 입력하세요.');
      return false;
    }
    setReleaseNoteError(false);
    setReleaseNoteHelpText('');
    return true;
  };

  const getSaveFile = (tempFileList: File[]) => {
    const saveFileList: File[] = [];
    fileArr = [];
    let fileTotalSize = 0;

    tempFileList.forEach(file => {
      if (fileTotalSize + file.size < FILE_MAX_SIZE) {
        saveFileList.push(file);
        fileTotalSize += file.size;
        console.log(`[add]-${file.name}-${file.size}`);
      } else {
        fileArr.push(file);
        console.log(`[arr]-${file.name}-${file.size}`);
      }
    });

    return saveFileList;
  };

  const handleSave = () => {
    const versionC = validationVersion();
    const statusC = validationStatus();
    const releaseC = validationReleaseDate();
    const releaseNoteC = validationReleaseNote();
    num = 0;
    if (versionC && statusC && releaseC && releaseNoteC) {
      processSave(false);
    }
  };

  const processSave = (onlyFile?: boolean) => {
    if (num < maxsize) {
      num++;
      const formData = new FormData();
      formData.append(
        'swVersionRequest',
        new Blob(
          [
            JSON.stringify({
              sw_id: swId,
              releaseDate: moment(releaseDate).toISOString(),
              version: version.replaceAll('.', ''),
              note: releaseNote,
              status,
              deleteFileIds,
              swObjectKeys,
            }),
          ],
          {
            type: 'application/json',
          },
        ),
      );

      // files.map((file, index) => {
      //   if (fileTotalSize + file.size < FILE_MAX_SIZE) {
      //     formData.append('addFiles', file);
      //     fileTotalSize += file.size;
      //     console.log(`ADD-[${index}]-${file.size}-${fileTotalSize}`);
      //   } else {
      //     fileArr.push(file);
      //     console.log(`fileArr-[${index}]-${file.size}`);
      //   }

      //   return file;
      // });

      const saveFileArr: File[] = getSaveFile(onlyFile ? fileArr : files);
      saveFileArr.forEach(file => formData.append('addFiles', file));

      if (versionId && u) {
        customAxios
          .put(`/mdx/V1.0/swm/sw/${swId}/version/${versionId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent =>
              setLoadFile(progressEvent.loaded),
          })
          .then(async () => {
            if (fileArr.length > 0) {
              processSave(true);
            } else {
              const result = await dispatch(
                openAlert({
                  message: swVersion ? t`Modified version.` : t`Added version.`,
                }),
              );
              if (result.payload) {
                getSwVersionList(swVersion ? page : 1);
                setOpen(false);
              }
            }
          });
      } else if (c) {
        customAxios
          .post(`/mdx/V1.0/swm/sw/${swId}/version`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent =>
              setLoadFile(progressEvent.loaded),
          })
          .then(async response => {
            setVersionId(response.data.id);
            if (fileArr.length > 0) {
              processSave(true);
            } else {
              const result = await dispatch(
                openAlert({ message: t`Added version.` }),
              );
              if (result.payload) {
                getSwVersionList(1);
                setOpen(false);
              }
            }
          });
      }
    }
  };

  const onStatusChange = (event: ChangeEvent<HTMLInputElement>) =>
    setStatus(event.target.value);

  const handleInstallFile = () => setSwAppVersionInstallPopup(true);

  const handleCancel = () => setOpen(false);

  const handleVersionFocus = () => {
    setVersionHelpText('');
    setVersionError(false);
  };

  const handleVersionBlur = () => {
    validationVersion();
  };

  return (
    <Wrap>
      <div className="scroll-wrap">
        <div>
          <Typography className="title-span">{t`Version`}</Typography>
          <Input
            id="version"
            variant="outlined"
            value={version}
            placeholder="A.BB.CCC"
            onChange={onChangeVersion}
            onFocus={handleVersionFocus}
            onBlur={handleVersionBlur}
            error={versionError}
            helperText={versionHelpText}
            InputProps={{
              inputComponent: VersionFormatCustom as any,
            }}
            fullWidth
            sx={{
              marginBottom: '24px',
            }}
          />
        </div>
        <div>
          {/* <p className="input-title">{t`Deployed Date`}</p> */}
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap[i18n.locale]}
          >
            <DatePicker
              showDaysOutsideCurrentMonth
              value={releaseDate}
              label={t`Deployed Date`}
              mask={maskMap[i18n.locale]}
              onChange={newValue => {
                setReleaseDate(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  error={releaseDateError}
                  helperText={releaseDateHelpText}
                  sx={{
                    marginBottom: '16px',
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ position: 'relative' }}>
          <Typography className="input-title">{t`File`}</Typography>
          <Button
            className="btn-black"
            variant="contained"
            size="small"
            onClick={handleInstallFile}
            style={{ position: 'absolute', right: '100px' }}
          >
            {t`Select Installation File`}
          </Button>
          {swVersion ? (
            <FileUpload
              showFileList
              fileName={fileName}
              setFiles={setFiles}
              files={files}
              deleteFileIds={deleteFileIds}
              setDeleteFileIds={setDeleteFileIds}
              externalFiles={Array.from(swObjectKeys)}
              loadFileSize={loadFile}
            />
          ) : (
            <FileUpload
              showFileList
              fileName={fileName}
              setFiles={setFiles}
              files={files}
              externalFiles={Array.from(swObjectKeys)}
              setExternalFiles={setSwObjectKeys}
              loadFileSize={loadFile}
            />
          )}
        </div>
        <div>
          <p className="input-title">{t`Status`}</p>
          <FormControl error={statusError}>
            <RadioGroup
              name="status"
              value={status}
              onChange={onStatusChange}
              row
            >
              {statusItems.map(item => (
                <FormControlLabel
                  key={item.code}
                  value={item.code}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </RadioGroup>
            <FormHelperText error>{statusHelpText}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <Typography className="input-title">{t`Release Note`}</Typography>
          {/* <TextField
            id="releaseNote"
            label={t`Release Note`}
            variant="outlined"
            multiline
            rows={5}
            value={releaseNote}
            onChange={onChangeReleaseNote}
            fullWidth
            sx={{
              marginTop: '24px',
            }}
          /> */}
          <Editor
            contents={releaseNote}
            setContents={onChangeReleaseNote}
            imageUploadUrl="/mdx/V1.0/common/image/temp"
            styles={{ height: '49vh' }}
          />
          {releaseNoteError && (
            <p
              style={{
                marginTop: '68px',
                color: '#E01919',
                fontSize: '12px',
                lineHeight: '16px',
              }}
            >
              {releaseNoteHelpText}
            </p>
          )}
        </div>
      </div>
      <Box className="btnGroup btn-footer">
        {(c || u) && (
          <Button variant="contained" size="large" onClick={handleSave}>
            {swVersion ? t`Modify` : t`Add`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={handleCancel}>
          {t`Cancel`}
        </Button>
      </Box>
      <ModalPopup
        open={swAppVersionInstallPopup}
        title={t`Select Installation File`}
        popupWidth={800}
      >
        <SwAppVersionInstallFile
          setProductPopup={setSwAppVersionInstallPopup}
          swObjectKeys={swObjectKeys}
          setSwObjectKeys={setSwObjectKeys}
        />
      </ModalPopup>
    </Wrap>
  );
};

export default SWAppVersionAddModify;

const Wrap = styled.div`
  margin-top: 24px;
  .scroll-wrap {
    position: relative;
    overflow: auto;
    height: 520px;
    margin-bottom: 90px;
    margin-right: -10px;
    padding-right: 10px;
    padding-top: 10px;
  }
  .btnGroup.btn-footer {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    background: #fff;
    border-top: #eee;
    padding-right: 24px;
    padding-bottom: 24px;
    margin-top: 24px;
    button {
      margin: 4px;
    }
  }
  .btn-black {
    background: rgb(0, 0, 0) !important;
    margin-bottom: 16px;
    padding: 8px 10px;
    box-sizing: border-box;
    letter-spacing: 0.01em;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .btnGroup {
    display: flex;
    justify-content: flex-end;
  }
  .input-title {
    margin-top: 16px;
  }
  .file-preview-box {
    border: 1px solid #9f9f9f;
    border-radius: 4px;
    margin-top: 32px;
    .title {
      height: 45px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      background: #000000;
      color: #fff;
    }
    .list-wrap {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .list {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      align-items: center;
    }
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
`;
