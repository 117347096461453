/* eslint-disable react/no-danger */
import { SyntheticEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { customAxios } from '../lib/customAxios';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import Logo from '../assets/images/app-logo.svg';
import CopyRight from '../components/common/CopyRight';
import Typography from '@mui/material/Typography';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TermsViewScreen = () => {
  const { lang, submenu } = useParams();
  const [termsList, setTermsList] = useState<TermsInterface[]>([]);
  const [tab, setTab] = useState<string>();

  useEffect(() => {
    customAxios
      .get<TermsInterface[]>(
        `/mdx/V1.0/setting/agrTermsUse/targets?lang=${lang}`,
      )
      .then(response => {
        setTermsList(response.data);
      });
  }, []);

  useEffect(() => {
    setTab(submenu);
  }, [submenu]);

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Wrap>
      <div className="register-header">
        <img src={Logo} alt="logo" className="logo-img" />
      </div>
      <div className="register-content">
        <Typography className="title">Terms & Privacy </Typography>
        <TabContext value={`${tab}`}>
          <Box>
            <TabList onChange={handleTabChange} aria-label="Terms Tabs">
              {termsList.map(item => (
                <Tab
                  label={item.termsItem}
                  value={`${item.verId}`}
                  className="tab-label"
                />
              ))}
            </TabList>
          </Box>
          {termsList.map(item => (
            <TabPanel value={`${item.verId}`} className="tab-content">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </TabPanel>
          ))}
        </TabContext>
      </div>
      <CopyRight />
    </Wrap>
  );
};

export default TermsViewScreen;

const Wrap = styled.div`
  width: 100%;
  .logo-img {
    padding: 24px 24px;
  }
  .register-header {
    background: #000;
    width: 100%;
    height: 113px;
    box-sizing: border-box;
  }
  .register-content {
    padding: 5%;
    background: #fbfbfb;

    .title {
      font-size: 28px;
      line-height: 32px;
      font-weight: 400;
      color: #4d4c4c;
      margin-bottom: 45px;
    }
    .MuiTabs-root {
      margin-bottom: 10px;
    }
    .MuiTabs-indicator {
      display: none;
    }
    .tab-label {
      font-size: 28px !important;
      line-height: 32px;
      font-weight: 400 !important;
      border-radius: 4px !important;
      padding: 24px;
      color: #000;
      background-color: #f6f6f6;
      margin-right: 10px;
    }
    .tab-label.Mui-selected {
      background: #fff;
      color: #0076ce;
    }
    .tab-content {
      font-size: 16px;
      line-height: 24px;
      background: #fff;
    }
  }
`;
