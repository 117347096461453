/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { dynamicActivate, locales } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { useState } from 'react';
import { customAxios } from '../../lib/customAxios';
import { setLang } from '../../features/userinfo/UserinfoSlice';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const SettingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, lang } = useAppSelector(state => state.userinfo);
  const [language, setLanguage] = useState<string>(lang);

  const handleLangChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
  };

  const handleSave = () => {
    dispatch(setLang(language));
    dynamicActivate(language);
    customAxios
      .put(`/mdx/V1.0/setting/user/${id}`, { lang: language })
      .then(() => {
        navigate('/main/setting');
      });
  };

  const handleCancel = () => {
    setLanguage(lang);
  };

  return (
    <Wrap>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography style={{ width: '150px' }}>
          {t`Language`}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            onChange={handleLangChange}
            sx={{ width: '280px' }}
            IconComponent={props => (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
              >
                <path
                  d="M7.41 9.13354L12 13.7135L16.59 9.13354L18 10.5435L12 16.5435L6 10.5435L7.41 9.13354Z"
                  fill="#333333"
                />
              </svg>
            )}
          >
            {Object.keys(locales).map(item => (
              <MenuItem value={item} key={item}>
                {locales[item as keyof typeof locales]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Box className="btnGroup" sx={{ '& button': { ml: 1 } }}>
        <Button
          variant="contained"
          size="large"
          onClick={handleSave}
        >{t`Save`}</Button>
        <Button variant="outlined" size="large" onClick={handleCancel}>
          {t`Cancel`}
        </Button>
      </Box>
    </Wrap>
  );
};

export default SettingScreen;

const Wrap = styled.div`
  height: calc(100% - 150px);
  background: #ffffff;
  border-radius: 8px;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #a8a7a7;
  .btnGroup {
    margin-top: 24px;
  }
  .input-title {
    margin-top: 24px;
  }
  .MuiSelect-icon {
    top: calc(50% - 0.7em);
  }
  .MuiOutlinedInput-root.Mui-focused > fieldset {
    border-color: #b3b2b2 !important;
  }
`;
