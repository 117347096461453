import { useState } from 'react';
import styled from 'styled-components';
import MoreInfoCompletion from '../../components/auth/MoreInfoCompletion';
import MoreInfoForm from '../../components/auth/MoreInfoForm';
import CopyRight from '../../components/common/CopyRight';

const MoreInfoScreen = () => {
  const [isMoreInfoComplete, setMoreInfoComplete] = useState<boolean>(false);
  return (
    <Wrap>
      {!isMoreInfoComplete && (
        <MoreInfoForm setComplete={setMoreInfoComplete} />
      )}
      {isMoreInfoComplete && <MoreInfoCompletion />}
      <CopyRight />
    </Wrap>
  );
};

export default MoreInfoScreen;

const Wrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
