import React, { ChangeEvent, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import { customAxios } from '../../lib/customAxios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { openAlert } from '../../features/alertModal/AlertModalSlice';
import Input from '../common/Input';
import Typography from '@mui/material/Typography';

interface Props {
  setOpen: (isOpen: boolean) => void;
  getCategory: () => void;
  data?: CategoryInterface;
}

const CategoryRegisterForm = ({ setOpen, getCategory, data }: Props) => {
  const { c, u } = useAppSelector(state => state.mymenu.currentMenu);
  const dispatch = useAppDispatch();
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<boolean>(false);
  const [codeHelptext, setCodeHelptext] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [nameHelptext, setNameHelptext] = useState<string>('');

  useEffect(() => {
    if (data) {
      setCode(data.cgCd);
      setName(data.cgName);
    }
  }, [data]);

  const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const validationCode = (value?: string): boolean => {
    const tempValue = value || code;
    if (tempValue === '') {
      setCodeError(true);
      setCodeHelptext(t`Please enter a code.`);
      return false;
    }
    setCodeError(false);
    setCodeHelptext('');
    return true;
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    validationName(e.target.value);
  };

  const validationName = (value?: string): boolean => {
    const tempValue = value || name;
    if (tempValue === '') {
      setNameError(true);
      setNameHelptext(t`Please enter a code name.`);
      return false;
    }
    setNameError(false);
    setNameHelptext('');
    return true;
  };

  const onSave = () => {
    const namev = validationName();
    const codev = validationCode();
    if (codev && namev) {
      if (c && data === undefined) {
        customAxios
          .post(`/mdx/V1.0/setting/category`, { cgCd: code, cgName: name })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: t`Added code.` }),
            );
            if (result.payload) {
              getCategory();
              setOpen(false);
            }
          });
      } else if (u && data) {
        customAxios
          .put(`/mdx/V1.0/setting/category/${data.id}`, {
            cgCd: code,
            cgName: name,
          })
          .then(async () => {
            const result = await dispatch(
              openAlert({ message: t`Modified code.` }),
            );
            if (result.payload) {
              getCategory();
              setOpen(false);
            }
          });
      }
    }
  };

  const handleCancle = () => {
    setOpen(false);
  };

  const handleCodeFocus = () => {
    setCodeError(false);
    setCodeHelptext('');
  };
  const handleCodeBlur = () => {
    validationCode();
  };

  const handleCodeNameFocus = () => {
    setNameError(false);
    setNameHelptext('');
  };
  const handleCodeNameBlur = () => {
    validationName();
  };

  return (
    <Wrap>
      <div className="row">
        <Typography className="title-span">{t`Code`}</Typography>
        <Input
          id="code"
          placeholder={t`Code`}
          variant="outlined"
          value={code}
          onChange={onChangeCode}
          onFocus={handleCodeFocus}
          onBlur={handleCodeBlur}
          error={codeError}
          helperText={codeHelptext}
          inputProps={{ maxLength: 45 }}
          fullWidth
          sx={{
            marginBottom: '24px',
          }}
        />
      </div>
      <div className="row">
        <Typography className="title-span">{t`Category Name`}</Typography>
        <Input
          id="name"
          placeholder={t`Category Name`}
          variant="outlined"
          value={name}
          onChange={onChangeName}
          onFocus={handleCodeNameFocus}
          onBlur={handleCodeNameBlur}
          error={nameError}
          helperText={nameHelptext}
          inputProps={{ maxLength: 45 }}
          fullWidth
        />
      </div>
      <Box className="btnGroup" sx={{ '& button': { ml: 1, mt: 2 } }}>
        {(c || u) && (
          <Button variant="contained" size="large" onClick={onSave}>
            {data ? t`Modify` : t`Add`}
          </Button>
        )}
        <Button variant="outlined" size="large" onClick={handleCancle}>
          {t`Cancel`}
        </Button>
      </Box>
    </Wrap>
  );
};

export default CategoryRegisterForm;

const Wrap = styled.div`
  .btnGroup {
    margin-top: 24px;
  }
  .input-title {
    margin-top: 24px;
  }
  .title-span {
    position: absolute;
    margin-left: 10px;
    color: #4d4c4c;
    background: #fff;
    z-index: 100;
    transform: translate(0px, -10px);
    padding: 0 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
`;
