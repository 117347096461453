import Button from '@mui/material/Button';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import Logo from '../../assets/images/app-logo.svg';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';

interface Props {
  email: string;
}

const SearchIdFormCompletion = ({ email }: Props) => {
  return (
    <Wrap>
      <div className="login-box">
        <div style={{ width: '100%' }}>
          <img src={Logo} className="login-top-logo" alt="logo" />
          <Typography component="h2">Account ID Search Result</Typography>
          <Typography component="h4">
            Here is your ID for your account that corresponds to your recovery
            email address.
          </Typography>
          <div className="logo-bottom">
            <Typography className="title">ID:</Typography>
            <Typography className="email-address">{email}</Typography>
          </div>
          <NavLink to="/login">
            <Button variant="contained" size="medium" fullWidth>
              {t`OK`}
            </Button>
          </NavLink>
        </div>
      </div>
    </Wrap>
  );
};

export default SearchIdFormCompletion;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f3f3f3;
  a {
    text-decoration: none !important;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }
  .MuiTextField-root {
    width: 100%;
  }
  .email-address {
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-left: 5px;
  }
  .title {
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #878585;
  }
  .logo-bottom {
    margin-bottom: 24px;
  }
`;
