import styled from 'styled-components';
import LoginForm from '../../components/auth/LoginForm';
import CopyRight from '../../components/common/CopyRight';

const CommLoginScreen = () => {
  return (
    <Wrap>
      <LoginForm />
      <CopyRight />
    </Wrap>
  );
};

export default CommLoginScreen;

const Wrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
